import { connect } from "react-redux";
import { getRecordsForPdf } from "src/redux/actions";
import ReportPdfPage from "./ReportPdfPage";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getRecordsForPdf: (payload) => dispatch(getRecordsForPdf(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportPdfPage);
