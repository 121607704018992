//libs
import React from "react";

//helper
import cx from "classnames";
import { matchUserImage, sanitizAddress } from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import ProfilePic from "src/assets/images/profile.png";
import { PROVIDER_PROFILE, STRINGS } from "src/shared/constants";

//components
import { useHistory } from "react-router-dom";

export const ProvidersTable = ({ data = [], loading = false }) => {
  const history = useHistory();

  return (
    <div className={S.tableContainer}>
      {!!!data.length && !!!loading ? (
        <p className={S.noData}>{STRINGS.NO_PROVIDERS_FOUND}</p>
      ) : (
        <table className={S.usersTable}>
          <thead>
            <tr className={cx(S.usersTableRow, S.usersTableHeading)}>
              <th>
                <span className={S.usersTableText}>Name</span>
              </th>
              <th>
                <span className={S.usersTableText}>Email</span>
              </th>
              <th>
                <span className={S.usersTableText}>Hospital ID</span>
              </th>
              <th>
                <span className={S.usersTableText}>Hospital Name</span>
              </th>
              <th>
                <span className={S.usersTableText}>Phone No</span>
              </th>
              <th>
                <span className={S.usersTableText}>Address</span>
              </th>
              <th>
                <span className={S.usersTableText}>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => {
              const hospitalDetails = d.hospitalData || {};
              return (
                <tr
                  key={d.id}
                  className={S.usersTableRow}
                  onClick={() =>
                    history.push({ pathname: PROVIDER_PROFILE, search: d.id })
                  }
                >
                  <td style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={
                        d.profilePicture
                          ? matchUserImage(d.profilePicture)
                          : ProfilePic
                      }
                      alt=""
                      className={S.usersTableImage}
                    />
                    <span
                      className={S.usersTableText}
                      style={{ marginLeft: "16px" }}
                    >
                      {d.fullName || STRINGS.EMPTY_DATA}
                    </span>
                  </td>
                  <td>
                    <span className={S.usersTableText}>
                      {d.email || STRINGS.EMPTY_DATA}
                    </span>
                  </td>
                  <td>
                    <span className={S.usersTableText}>
                      {hospitalDetails.uniqueId || STRINGS.EMPTY_DATA}
                    </span>
                  </td>
                  <td>
                    <span className={S.usersTableText}>
                      {hospitalDetails.name || STRINGS.EMPTY_DATA}
                    </span>
                  </td>
                  <td>
                    <span className={S.usersTableText}>
                      {hospitalDetails.phoneNumber || STRINGS.EMPTY_DATA}
                    </span>
                  </td>
                  <td>
                    <span className={S.usersTableText}>
                      {sanitizAddress(hospitalDetails)}
                    </span>
                  </td>
                  <td>
                    <div
                      className={cx(
                        S.status,
                        d.isEmailVerified ? S.statusActive : S.statusPending
                      )}
                    >
                      {d.isEmailVerified ? STRINGS.ACTIVE : STRINGS.PENDING}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};
