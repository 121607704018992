//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./HalfCardModal.module.scss";

const HalfCardModal = ({
  title = "",
  children,
  containerClass,
  showCard = false,
  onClose = () => {},
}) => {
  return (
    <>
      <div className={cx(showCard && S.overlay)} onClick={onClose} />
      <div
        className={cx(
          S.container,
          containerClass,
          showCard ? S.containerShow : S.containerHide
        )}
      >
        <p className={S.containerHeader}>{title}</p>
        {children}
      </div>
    </>
  );
};

export default HalfCardModal;
