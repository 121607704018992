import { ApexChart } from "apex-react-charts";
import React from "react";
import S from "./style.module.scss";

const LineChart = ({
  title,
  height = 180,
  xaxis = [],
  data = [],
  min,
  max,
}) => {
  const series = [{ name: "Desktops", data }];

  const options = {
    chart: {
      height: height,
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    markers: {
      size: 5,
    },
    dataLabels: {
      enabled: true,
      offsetX: 0,
      offsetY: -5,
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    xaxis: {
      categories: [...xaxis],
    },
    yaxis: {
      min: min ?? 70,
      max: max ?? 110,
    },
  };
  return (
    <>
      <div className={S.header}>
        <h1 className={S.headerTitle}>{title}</h1>
      </div>
      <ApexChart options={options} series={series} type="line" />
    </>
  );
};

export default React.memo(LineChart);
