//libs
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import cx from "classnames";

//components
import {
  BreadcrumbMenu,
  CountCard,
  CustomDaysPicker,
  PieChart,
} from "src/components";

//constants
import { ChartColors, STRINGS, TEST_TILES } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//widgets
import { SessionsCountBar } from "./widgets";

//helpers
import {
  computeSessions,
  getStartEndTime,
  getTotalTests,
} from "src/shared/services";

const Dashboard = ({
  enqueueSnackbar = () => {},
  loading = false,
  getTestSessionsData = () => {},
  getUserSessionsData = () => {},
}) => {
  const [testSessionsdata, setTestSessionsData] = useState({});
  const [userSessionsdata, setuserSessionsData] = useState({});
  const [dateType, setDateType] = useState("7");

  useEffect(() => {
    const { startTime, endTime } = getStartEndTime(dateType);
    getTestSessionsData({
      payload: { startTime, endTime },
      success: (data) => {
        setTestSessionsData(data);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });

    getUserSessionsData({
      payload: { startTime, endTime },
      success: (data) => {
        setuserSessionsData(getSessionData(data));
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
    // eslint-disable-next-line
  }, [dateType]);

  const getSessionData = (data = []) => {
    if (data?.length) {
      return computeSessions(data);
    }
    return null;
  };

  const renderCountCard = () =>
    [
      {
        count: getTotalTests(testSessionsdata),
      },
      {
        count: testSessionsdata?.act,
        background: ChartColors.one,
      },
      {
        count: testSessionsdata?.dailyLog,
        background: ChartColors.two,
      },
      {
        count: testSessionsdata?.fingerTipOxyMeter,
        background: ChartColors.three,
      },
      {
        count: testSessionsdata?.oxyNight,
        background: ChartColors.four,
      },
      {
        count: testSessionsdata?.oxyNightLite,
        background: ChartColors.five,
      },
      {
        count: testSessionsdata?.spiroLink,
        background: ChartColors.six,
      },
    ].map((e, i) => (
      <CountCard
        key={i}
        title={TEST_TILES[i]}
        count={e.count}
        background={e.background}
      />
    ));

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.DASHBOARD} />
      {!loading && (
        <>
          <div className={S.cardContainer}>{renderCountCard()}</div>
          <div className={S.chart}>
            <div className={cx(S.chartContainer, S.chartPie)}>
              <PieChart
                title={STRINGS.TESTS_PERFORMED}
                height={350}
                dataSet={testSessionsdata}
              />
            </div>

            {userSessionsdata && (
              <div className={S.tableContainer}>
                <div className={S.tableHead}>
                  <CustomDaysPicker setDate={setDateType} date={dateType} />
                </div>
                <div className={S.tableHeading}>{STRINGS.USERS_LOGIN}</div>
                <SessionsCountBar data={userSessionsdata.login} />
                <div className={S.tableHeading}>{STRINGS.USERS_SIGNUP}</div>
                <SessionsCountBar data={userSessionsdata.signup} />
              </div>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default withSnackbar(Dashboard);
