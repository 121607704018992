//libs
import React, { useEffect, useState } from "react";

//constants
import { BreadcrumbMenu, CustomButton, CustomInput } from "src/components";
import { STRINGS } from "src/shared/constants";

//styles
import S from "./style.module.scss";
import { withSnackbar } from "notistack";

const Config = ({
  getConfig = () => {},
  updateConfig = () => {},
  enqueueSnackbar = () => {},
}) => {
  const [data, setData] = useState({ weatherKey: "", pollutionKey: "" });
  const [values, setValues] = useState({
    weatherKey: "",
    pollutionKey: "",
  });
  const [readOnly, setReadOnly] = useState({
    weatherKey: true,
    pollutionKey: true,
  });

  useEffect(() => {
    getConfig({
      success: (data) => {
        setData({
          weatherKey: data?.weather_api_key,
          pollutionKey: data?.pollution_api_key,
        });
        setValues({
          weatherKey: data?.weather_api_key,
          pollutionKey: data?.pollution_api_key,
        });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdate = (value) => {
    try {
      updateConfig({
        payload: {
          weather_api_key: value.weatherKey,
          pollution_api_key: value.pollutionKey,
        },
        success: (data) => {
          setReadOnly({ weatherKey: true, pollutionKey: true });
          enqueueSnackbar(data.msg, { variant: "success" });
        },
        fail: (msg) => {
          enqueueSnackbar(msg, { variant: "error" });
        },
      });
    } catch (error) {}
  };

  const handleInput = (e) =>
    setValues({ ...values, [e.target.name]: e.target.value });

  const handleEdit = (e) => {
    setReadOnly({
      weatherKey: true,
      pollutionKey: true,
      [e.target.id]: !readOnly[e.target.id],
    });
    setValues({ ...data });
  };

  const handleCancel = (e) => {
    setReadOnly({
      ...readOnly,
      [e.target.id]: true,
    });
    setValues({ ...data });
  };

  const handleSubmit = () => {
    handleUpdate(values);
  };

  const KEYS = [
    {
      id: "weatherKey",
      value: values.weatherKey,
      label: STRINGS.WEATHER_API_KEY,
      readOnly: readOnly.weatherKey,
    },
    {
      id: "pollutionKey",
      value: values.pollutionKey,
      label: STRINGS.POLLUTION_API_KEY,
      readOnly: readOnly.pollutionKey,
    },
  ];

  return (
    <div className={S.section}>
      <BreadcrumbMenu title={STRINGS.CONFIGURE} subTitle={STRINGS.CONFIG} />

      <div className={S.container}>
        {KEYS.map((key, idx) => {
          return (
            <div key={idx}>
              <CustomInput
                containerClass={S.inputContainer}
                inputClass={S.input}
                label={key.label}
                value={key.value}
                handleChange={handleInput}
                name={key.id}
                readOnly={key.readOnly}
              />

              <div className={S.save}>
                {!key.readOnly && (
                  <CustomButton
                    title={STRINGS.CANCEL}
                    buttonClass={S.saveButton}
                    onClick={handleCancel}
                    id={key.id}
                  />
                )}
                <CustomButton
                  title={key.readOnly ? STRINGS.EDIT : STRINGS.SAVE}
                  buttonClass={S.saveButton}
                  onClick={key.readOnly ? handleEdit : handleSubmit}
                  id={key.id}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withSnackbar(Config);
