import { ACTION_TYPES } from "./actionTypes";

export const addDevice = (payload) => {
  return {
    type: ACTION_TYPES.ADD_DEVICE,
    payload,
  };
};

export const deleteDevice = (payload) => {
  return {
    type: ACTION_TYPES.DELETE_DEVICE,
    payload,
  };
};

export const updateDevice = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_DEVICE,
    payload,
  };
};

export const getDevices = (payload) => {
  return {
    type: ACTION_TYPES.GET_DEVICES,
    payload,
  };
};

export const getDevice = (payload) => {
  return {
    type: ACTION_TYPES.GET_DEVICE,
    payload,
  };
};

export const uploadBulkDevice = (payload) => {
  return {
    type: ACTION_TYPES.UPLOAD_BULK_DEVICE,
    payload
  }
}