import { ACTION_TYPES } from "./actionTypes";

export const sendNotification = (payload) => {
  return {
    type: ACTION_TYPES.SEND_NOTIFICATION,
    payload,
  };
};

export const getNotificationsList = (payload) => {
  return {
    type: ACTION_TYPES.GET_NOTIFICATIONS_LIST,
    payload,
  };
};

export const deleteNotification = (payload) => {
  return {
    type: ACTION_TYPES.DELETE_NOTIFICATION,
    payload,
  };
};
