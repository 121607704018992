import React from "react";
import { Icomoon } from "src/assets/icons";
import { STRINGS } from "src/shared/constants";

//styles
import S from "./style.module.scss";

const ActWidget = ({ data = {} }) => {
  if (!!!Object.keys(data)?.length) return null;

  return (
    <div className={S.container}>
      <div className={S.header}>
        <div className={S.headerImage}>
          <Icomoon className={S.headerImageIcon} icon={"lungs"} />
        </div>
        <div className={S.headerDesc}>
          <p className={S.headerDescTitle}>{STRINGS.ACT}</p>
          <p className={S.headerDescText}>{`Date: ${new Date(
            data?.updatedAt
          ).toLocaleString()}`}</p>
        </div>
      </div>
      <div
        className={S.result}
        style={{ background: getActColor(data?.act, 33) }}
      >
        <div
          className={S.resultScore}
          style={{ color: getActColor(data?.act) }}
        >
          <span>{data?.act}</span>
        </div>
        <div className={S.resultDesc}>
          <p className={S.resultDescTitle}>{data?.actTitle}</p>
          <p
            className={S.resultDescText}
            style={{ color: getActColor(data?.act) }}
          >
            {data?.actSubTitle}
          </p>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ActWidget);

export const getActColor = (act, op = 0) => {
  let color = "";
  if (act <= 20) {
    color = `#ff0000`;
  } else if (act > 20 && act < 24) {
    color = `#fbd849`;
  } else {
    color = `#05c46b`;
  }
  return op ? color + op : color;
};
