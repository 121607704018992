//libs
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

//styles
import S from "./SpiroLinkPdf.module.scss";

//widgets
import CoverPage from "./widgets/CoverPage";
import Summary from "./widgets/Summary";
import Graphs from "./widgets/Report";
import Page from "./Page";

const SpiroLinkPdf = ({
  getSpiroLinkRecords = () => {},
  userId,
  timezone,
  fromDate,
  toDate,
}) => {
  const [pdfData, setPdfData] = useState(null);
  const [pdfTimezone, setPdfTimezone] = useState("America/Phoenix");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getSpirLinkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSpirLinkData = () => {
    try {
      getSpiroLinkRecords({
        payload: {
          userId,
          fromDate,
          toDate,
        },
        success: (data) => {
          setPdfData(data.data);
          timezone && setPdfTimezone(timezone);
        },
        fail: (msg) => {},
      });
    } catch (err) {
      console.log(err);
    }
  };

  const dateRange = `${moment(fromDate)
    .tz(pdfTimezone)
    .format("MM-DD-YYYY")} to ${moment(toDate)
    .tz(pdfTimezone)
    .format("MM-DD-YYYY")}`;

  if (!pdfData) return null;

  if (!pdfData?.spiroRecords?.length) {
    return (
      <div className={S.container}>
        <div className={S.page}>
          <CoverPage
            dateRange={dateRange}
            userName={pdfData?.userProfileData?.fullName}
            empty
          />
        </div>
      </div>
    );
  }

  const data = {
    spiroRecords: pdfData?.spiroRecords?.sort((a, b) =>
      a?.createdAt?.localeCompare(b?.createdAt)
    ),
    userProfileData: pdfData?.userProfileData,
  };

  const highestPef = Number(
    data?.spiroRecords?.reduce((maxPef, currentObject) => {
      return currentObject.pef > maxPef ? currentObject.pef : maxPef;
    }, 0)
  ).toFixed(2);

  const highestPefPercent = Number(
    (highestPef / data?.userProfileData?.pef) * 100
  ).toFixed(2);

  const lowestPef = Number(
    data?.spiroRecords?.reduce((minPef, currentObject) => {
      return currentObject.pef < minPef ? currentObject.pef : minPef;
    }, Infinity)
  ).toFixed(2);

  const lowestPefPercent = Number(
    (lowestPef / data?.userProfileData?.pef) * 100
  ).toFixed(2);

  const highestFev1 = Number(
    data?.spiroRecords?.reduce((maxFev1, currentObject) => {
      return currentObject.fev1 > maxFev1 ? currentObject.fev1 : maxFev1;
    }, 0)
  ).toFixed(2);

  const highestFev1Percent = Number(
    (highestFev1 / data?.userProfileData?.fev1) * 100
  ).toFixed(2);

  const lowestFev1 = Number(
    data?.spiroRecords?.reduce((minFev1, currentObject) => {
      return currentObject.fev1 < minFev1 ? currentObject.fev1 : minFev1;
    }, Infinity)
  ).toFixed(2);

  const lowestFev1Percent = Number(
    (lowestFev1 / data?.userProfileData?.fev1) * 100
  ).toFixed(2);

  const totalTests = data?.spiroRecords?.length;

  const summary = [
    [`${data?.userProfileData?.pef}L/min`],
    [`${highestPef}L/min`, highestPefPercent],
    [`${lowestPef}L/min`, lowestPefPercent],
    [`${data?.userProfileData?.fev1}L`],
    [`${highestFev1}L`, highestFev1Percent],
    [`${lowestFev1}L`, lowestFev1Percent],
    [totalTests],
  ];

  // after summary split
  const weeklyRecords = splitDatesIntoWeeks(
    fillDates(data.spiroRecords, pdfTimezone)
  );

  return (
    <div className={S.container}>
      <Page hideLogo hidePageNo>
        <CoverPage
          dateRange={dateRange}
          userName={data?.userProfileData?.fullName}
        />
      </Page>
      <Page currentPage={1} totalPages={totalPages}>
        <Summary
          summary={summary}
          records={fillDates(data?.spiroRecords, pdfTimezone)}
          timezone={pdfTimezone}
          userData={data?.userProfileData}
          totalPages={totalPages}
        />
      </Page>
      <Graphs
        dateRange={dateRange}
        records={weeklyRecords}
        userData={data?.userProfileData}
        timezone={pdfTimezone}
        totalPages={totalPages}
        setTotalPages={setTotalPages}
      /> 
    </div>
  );
};

export default React.memo(SpiroLinkPdf);

function splitDatesIntoWeeks(datesArray) {
  const weeksArray = [];
  const daysInWeek = 7;

  let currentWeek = [];
  let week = 1;

  datesArray.forEach((dateObj) => {
    if (
      currentWeek.length < daysInWeek &&
      new Date(dateObj.createdAt).getDate() <= 7 * week
    ) {
      currentWeek.push(dateObj);
    } else {
      // Start a new week if the current week is full
      week += 1;
      if (currentWeek?.length) {
        weeksArray.push(currentWeek);
      }
      currentWeek = [dateObj];
    }
  });

  if (currentWeek?.length < 7) {
    weeksArray.push(currentWeek);
  }

  return weeksArray;
}

function fillDates(data = [], timezone) {
  if (data.length === 0) {
    return [];
  }

  const month = moment(data[0]?.createdAt).month() + 1;
  const year = moment(data[0]?.createdAt).year();
  const firstDateOfMonth = moment
    .tz([year, month - 1], timezone)
    .startOf("month");
  const lastDateOfMonth = moment.tz([year, month - 1], timezone).endOf("month");

  const dates = [];
  for (
    let currentDate = firstDateOfMonth;
    currentDate <= lastDateOfMonth;
    currentDate.add(1, "day")
  ) {
    const existingData = data.find((item) => {
      if (item.createdAt) {
        const itemDate = moment.tz(item.createdAt, timezone);
        return (
          itemDate.date() === currentDate.date() &&
          itemDate.month() === currentDate.month() &&
          itemDate.year() === currentDate.year()
        );
      }
      return false;
    });

    if (existingData) {
      dates.push(existingData);
    } else {
      dates.push({
        createdAt: currentDate.format(),
        // Add other properties as needed
      });
    }
  }

  return dates;
}
