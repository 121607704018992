import { ACTION_TYPES } from "./actionTypes";

export const addUser = (payload) => {
  return {
    type: ACTION_TYPES.ADD_USER,
    payload,
  };
};

export const deleteUser = (payload) => {
  return {
    type: ACTION_TYPES.DELETE_USER,
    payload,
  };
};

export const updateUser = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_USER,
    payload,
  };
};

export const getUsers = (payload) => {
  return {
    type: ACTION_TYPES.GET_USERS,
    payload,
  };
};

export const getUser = (payload) => {
  return {
    type: ACTION_TYPES.GET_USER,
    payload,
  };
};

export const changePassword = (payload) => {
  return {
    type: ACTION_TYPES.CHANGE_PASSWORD,
    payload,
  };
};

export const getUserReport = (payload) => {
  return {
    type: ACTION_TYPES.GET_USER_REPORT,
    payload,
  };
};

export const getOxyTestSessionData = (payload) => {
  return {
    type: ACTION_TYPES.GET_OXY_TEST_SESSION_DATA,
    payload,
  };
};

export const recreateSleepAnalysisData = (payload) => {
  return {
    type: ACTION_TYPES.RECREATE_SLEEP_ANALYSIS,
    payload,
  };
};
