//libs
import React from "react";
import { withSnackbar } from "notistack";

//styles
import S from "./login.module.scss";

//hooks
import { useHistory } from "react-router-dom";

//constants
import { STRINGS, FORGOT_PASSWORD } from "src/shared/constants";
import Logo from "src/assets/images/asthmaGo_logo.png";
import BodyImage from "src/assets/images/body.png";

//form
import { LoginForm } from "src/views/Public/Login/LoginForm";

const Login = ({ signIn = () => {}, enqueueSnackbar }) => {
  const history = useHistory();

  const handleSubmit = (values) => {
    signIn({
      payload: { ...values },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  return (
    <section className={S.login}>
      <div className={S.loginLeft}>
        <img src={BodyImage} alt="" height="100%" />
      </div>
      <div className={S.loginRight}>
        <img className={S.loginRightImage} src={Logo} alt="" />
        <h2 className={S.loginRightHeading}>{STRINGS.LOGIN_IN_ACCOUNT}</h2>
        <LoginForm onSubmit={handleSubmit} />
        <label
          className={S.loginForgotBtn}
          onClick={() => history.push(FORGOT_PASSWORD)}
        >
          {STRINGS.FORGOT_PASSWORD}
        </label>
      </div>
    </section>
  );
};

export default withSnackbar(Login);
