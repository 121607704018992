//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import {
  postRequest,
  postRequestNoAuth,
  putRequest,
  updateAuthToken,
} from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  startLoading,
  stopLoading,
  updateAuthTokenRedux,
  updateUserDetailsRedux,
} from "../actions";

function* signInSaga({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data = {} } = yield postRequestNoAuth({
      API: API.LOGIN,
      DATA: { ...payload },
    });

    if (data && data.data && data.data.accessToken) {
      updateAuthToken(data.data.accessToken);
      yield put(updateAuthTokenRedux(data.data.accessToken));
      yield put(updateUserDetailsRedux(data.data.user));
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* forgotPassword({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.FORGOT_PASSWRD,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* resetPasswordSaga({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield putRequest({
      API: API.RESET_PASSWORD,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* signOutSaga({
  payload: { payload = "", success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.LOGOUT,
      DATA: { token: payload },
    });
    if (data.statusCode === RESPONSE.success) {
      yield put(updateAuthTokenRedux(""));
      yield put(updateUserDetailsRedux({}));
      updateAuthToken("");
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

//end user reset password
function* resetUserPasswordSaga({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.RESET_USER_PASSWORD,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* AuthSaga() {
  yield all([
    takeLatest(ACTION_TYPES.SIGN_IN, signInSaga),
    takeLatest(ACTION_TYPES.FORGOT_PASSWORD, forgotPassword),
    takeLatest(ACTION_TYPES.RESET_PASSWORD, resetPasswordSaga),
    takeLatest(ACTION_TYPES.RESET_USER_PASSWORD, resetUserPasswordSaga),
    takeLatest(ACTION_TYPES.SIGN_OUT, signOutSaga),
  ]);
}

export default AuthSaga;
