//libs
import React from "react";

//components
import { CustomButton, CustomInput } from "src/components";
import { Formik } from "formik";

//styles
import S from "./ForgotPassword.module.scss";

//constants
import { STRINGS } from "src/shared/constants";

export const ForgotPasswordForm = ({ onSubmit = () => {} }) => {
  return (
    <Formik
      validate={validate}
      initialValues={{
        email: "",
      }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <div>
          <CustomInput
            placeholder={"Email"}
            containerClass={S.containerRightInputContainer}
            labelClass={S.containerRightLabel}
            inputClass={S.containerRightInput}
            name={"email"}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            value={values.name}
            handleBlur={handleBlur}
          />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <CustomButton
              title={STRINGS.SEND}
              buttonClass={S.containerRightButton}
              onClick={handleSubmit}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email";
  }
  return errors;
};
