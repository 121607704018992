//libs
import React from "react";
import moment from "moment";

//helper
import cx from "classnames";
import { formatImage } from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import ProfilePic from "src/assets/images/profile.png";
import {
  STRINGS,
  USER_PERMISSIONS,
  USER_ROLES_LABELS,
} from "src/shared/constants";

//components
import { Icomoon } from "src/assets/icons";

export const SubAdminsTable = ({
  data = [],
  handleViewDetails = (id) => {},
  handleDelete = (id) => {},
  handleEdit = (id) => {},
  handleActivate = (id) => {},
  permissions = [],
}) => {
  return (
    <div className={S.tableContainer}>
      <table className={S.adminsTable}>
        <thead>
          <tr className={S.adminsTableRow}>
            <th>Name</th>
            <th>Joining Date</th>
            <th>Role</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d.id} className={S.adminsTableRow}>
              <td style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={
                    d.profilePicture
                      ? formatImage(d.profilePicture)
                      : ProfilePic
                  }
                  alt=""
                  className={S.adminsTableImage}
                />
                <span style={{ marginLeft: "16px" }}>{d.fullName}</span>
              </td>
              <td>
                <span>{moment(d.createdAt).format("MMM Do, YYYY")}</span>
              </td>
              <td>
                <span>{USER_ROLES_LABELS[d.role]}</span>
              </td>
              <td>
                <div
                  className={cx(
                    S.status,
                    d.isEmailVerified ? S.statusActive : S.statusPending
                  )}
                >
                  {d.isEmailVerified ? STRINGS.ACTIVE : STRINGS.PENDING}
                </div>
              </td>
              <td>
                <Icomoon
                  className={cx(S.icon, S.view, {
                    [S.disable]: !permissions.includes(USER_PERMISSIONS.VIEW),
                  })}
                  icon={"eye"}
                  onClick={() => handleViewDetails(d.id)}
                />
                <Icomoon
                  className={cx(S.icon, S.edit, {
                    [S.disable]: !permissions.includes(USER_PERMISSIONS.UPDATE),
                  })}
                  icon={"pencil"}
                  onClick={() => handleEdit(d.id)}
                />
                <Icomoon
                  className={cx(S.icon, S.delete, {
                    [S.disable]: !permissions.includes(USER_PERMISSIONS.DELETE),
                  })}
                  icon={"bin"}
                  onClick={() => handleDelete(d.id)}
                />
                {!!!d.isEmailVerified && (
                  <Icomoon
                    className={cx(S.icon, S.view, S.power, {
                      [S.powerOff]: !!!d?.isEmailVerified,
                    })}
                    icon={"power-off"}
                    onClick={() => handleActivate(d.id)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
