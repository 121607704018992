//libs
import React, { useState } from "react";

//styles
import S from "./style.module.scss";

//constants
import {
  MAX_PASSWORD_LENGTH,
  passwordRegex,
  STRINGS,
  WARNINGS,
} from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import { CustomButton, CustomInput } from "src/components";

export const ResetPasswordForm = ({ onSubmit = () => {} }) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const INPUT_FILEDS = [
    {
      name: STRINGS.PASSWORD,
      label: STRINGS.NEW_PASSWORD_LABEL,
      type: showNewPassword ? "text" : "password",
      passwordIcon: showNewPassword ? "eye" : "eye-blocked",
      togglePassword: () => setShowNewPassword(!showNewPassword),
      max: MAX_PASSWORD_LENGTH,
    },
    {
      name: STRINGS.CONFIRM_PASSWORD,
      label: STRINGS.CONFIRM_PASSWORD_LABEL,
      type: showConfirmPassword ? "text" : "password",
      passwordIcon: showConfirmPassword ? "eye" : "eye-blocked",
      togglePassword: () => setShowConfirmPassword(!showConfirmPassword),
      max: MAX_PASSWORD_LENGTH,
    },
  ];

  const inputRefs = Array(INPUT_FILEDS.length).fill(React.createRef());

  return (
    <Formik
      validate={validate}
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <Form style={{ width: 350, marginTop: 56 }} onSubmit={handleSubmit}>
          <div className={S.formError}>
            {errors?.submit && touched?.password ? errors?.submit : ""}
          </div>
          {INPUT_FILEDS.map((field, idx) => (
            <CustomInput
              key={idx}
              label={field.label}
              inputRef={(r) => (inputRefs[idx] = r)}
              name={field.name}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              containerClass={S.formInputContainer}
              labelClass={S.formLabel}
              inputClass={S.formInput}
              value={values[field.name]}
              type={field.type}
              handleBlur={handleBlur}
              showPasswordIcon={field.passwordIcon}
              toggleShowPassword={field.togglePassword}
              showPasswordIconStyle={showPasswordIconStyle}
              maxLength={field.max}
            />
          ))}
          <div style={{ textAlign: "center" }}>
            <CustomButton
              type="submit"
              title={STRINGS.SUBMIT}
              onClick={handleSubmit}
              buttonClass={S.formButton}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values[STRINGS.PASSWORD]) {
    errors[STRINGS.PASSWORD] = "Required";
  } else if (!passwordRegex.test(values[STRINGS.PASSWORD])) {
    errors.submit = STRINGS.PASSWORD_REGEX_ERROR;
  }
  if (!values[STRINGS.CONFIRM_PASSWORD]) {
    errors[STRINGS.CONFIRM_PASSWORD] = "Required";
  }

  if (
    values[STRINGS.PASSWORD] &&
    values[STRINGS.CONFIRM_PASSWORD] &&
    values[STRINGS.CONFIRM_PASSWORD].localeCompare(values[STRINGS.PASSWORD]) !==
      0
  ) {
    errors[STRINGS.CONFIRM_PASSWORD] = WARNINGS.CONFIRM_PASSWORD;
  }
  return errors;
};

const showPasswordIconStyle = {
  top: 6,
  height: 18,
  width: 18,
};
