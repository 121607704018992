import React from "react";
import "./App.css";
import { Provider } from "react-redux";
import Routes from "./routes";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistor, store } from "./redux/store";
import { SnackbarProvider } from "notistack";
import { LoaderHoc } from "./components";

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      autoHideDuration={3000}
    >
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LoaderHoc>
            <Routes />
          </LoaderHoc>
        </PersistGate>
      </Provider>
    </SnackbarProvider>
  );
}

export default App;
