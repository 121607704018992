//libs
import React, { useState } from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//constants
import { STRINGS } from "src/shared/constants";

//components
import { Icomoon } from "src/assets/icons";
import { SendNotificationForm } from "./widgets/SendNotificationForm";

export const NotificationsForm = ({
  handleFormClose = () => {},
  handleSubmit = () => {},
  readOnly = 0, //0 add, 2 view
  data = {},
}) => {
  const [formType, setFormType] = useState(2);

  const handleClose = (callback) => {
    handleFormClose(() => {
      setTimeout(() => {
        setFormType(2);
        callback();
      }, 320);
    });
  };

  const renderFormBody = () => (
    <SendNotificationForm
      handleCancel={handleClose}
      onSubmit={handleSubmit}
      readOnly={readOnly}
      formData={data}
      notificationType={formType}
    />
  );

  return (
    <div className={S.form}>
      <p className={S.formHeading}>{STRINGS.NOTIFICATION_TYPE}</p>
      <div className={S.formSelector}>
        <div
          className={cx(S.formSelectorChip, {
            [S.formSelectorChipActive]: formType === 2,
            [S.formSelectorChipDisable]: readOnly,
          })}
          onClick={() => {
            !!!readOnly && setFormType(2);
          }}
        >
          <Icomoon
            className={S.formSelectorChipIcon}
            icon={formType === 2 ? "radio-checked" : "radio-unchecked"}
            color={formType === 2 ? "#0076a8" : "#000"}
          />
          <span className={S.formSelectorChipText}>
            {STRINGS.PUSH_NOTIFICATIONS}
          </span>
        </div>
        <div
          className={cx(S.formSelectorChip, {
            [S.formSelectorChipActive]: formType === 1,
            [S.formSelectorChipDisable]: readOnly,
          })}
          onClick={() => {
            !!!readOnly && setFormType(1);
          }}
        >
          <Icomoon
            className={S.formSelectorChipIcon}
            icon={formType === 1 ? "radio-checked" : "radio-unchecked"}
            color={formType === 1 ? "#0076a8" : "#000"}
          />
          <span className={S.formSelectorChipText}>{STRINGS.EMAIL}</span>
        </div>
      </div>

      <div className={S.formBody}>{renderFormBody()}</div>
    </div>
  );
};
