import { connect } from "react-redux";
import {
  deleteUser,
  getUser,
  updateUser,
  startLoading,
  stopLoading,
} from "src/redux/actions";
import ProviderProfile from "./ProviderProfile";

const mapStateToProps = (state) => {
  return {
    userDetails: state.common.userDetails,
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProvider: (payload) => dispatch(getUser(payload)),
    deleteProvider: (payload) => dispatch(deleteUser(payload)),
    updateProvider: (payload) => dispatch(updateUser(payload)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderProfile);
