import { connect } from "react-redux";
import { addProvider, getUsers } from "src/redux/actions";
import Providers from "./Providers";

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    userDetails: state.common.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProviders: (payload) => dispatch(getUsers(payload)),
    addProvider: (payload) => dispatch(addProvider(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Providers);
