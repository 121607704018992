import React from "react";
import { Icomoon } from "src/assets/icons";

//styles
import S from "./style.module.scss";

const DailyLogWidget = ({ data = {} }) => {
  if (!Object.entries(data).length) return null;

  const morningPef = data?.morningPef || "";
  const eveningPef = data?.eveningPef || "";

  const DAILY_DATA = {
    peak_flow_rate: [
      morningPef ? {
        icon: "sun",
        title: "Daytime",
        value: Number(morningPef).toFixed(2),
      } : "",
      eveningPef ? {
        icon: "moon",
        title: "Evening",
        value: Number(eveningPef).toFixed(2)
      } : "",
    ],
    medication_records: JSON.parse(data?.medicationType),
    record_of_symptoms: JSON.parse(data?.symptoms),
    others: [data?.others],
    food_choices: JSON.parse(data?.diet),
    activities: JSON.parse(data?.activities),
  };

  return (
    <div className={S.container}>
      <div className={S.header}>
        <p className={S.headerTitle}>Daily Log</p>
        <p className={S.headerText}>
          {new Date(data?.updatedAt).toLocaleString()}
        </p>
      </div>
      {Object.entries(DAILY_DATA).map(([property, data], key) => {
        return (
          <div className={S.log} key={key}>
            <p className={S.logHeading}>{property.split("_").join(" ")}</p>
            <div className={S.logContainer}>
              {data instanceof Array && data?.length && data?.map((d, idx) => (
                d && <div
                  className={S.logContainerWidget}
                  key={idx}
                  style={{ borderColor: COLORS[key] }}
                >
                  {d?.icon && (
                    <Icomoon
                      className={S.logContainerWidgetIcon}
                      icon={d.icon}
                      style={{ color: COLORS[key] }}
                    />
                  )}
                  <p
                    className={S.logContainerWidgetText}
                    style={{ color: COLORS[key] }}
                  >
                    {d?.title || d}
                  </p>
                  {d?.value && (
                    <span
                      className={S.logContainerWidgetText}
                      style={{ color: COLORS[key] }}
                    >
                      {d.value}
                    </span>
                  )}
                </div>
              ))}
            </div>
            <hr className={S.logBreak} />
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(DailyLogWidget);

const COLORS = ["red", "orange", "#ff5900", "#ff6600", "#205cc0", "#6bb120"];
