//libs
import React from "react";

//constants
import { STRINGS } from "src/shared/constants";

//utils
import { BMIcalculator, toFeet, toLbs } from "src/shared/services";

//styles
import S from "./style.module.scss";

const UserStats = ({ userData = {} }) => {
  const dataRows = [
    {
      name: "Weight",
      value: userData.weight ? toLbs(userData.weight) : STRINGS.EMPTY_DATA,
    },
    {
      name: "Height",
      value: userData.height ? toFeet(userData.height) : STRINGS.EMPTY_DATA,
    },
    {
      name: "BMI",
      value: BMIcalculator(userData.weight, userData.height),
    },
  ];

  return (
    <div className={S.userStat}>
      {dataRows.map((data) => (
        <div className={S.userStatInfo} key={data.name}>
          <span>{data.name}</span>
          <span>{data.value}</span>
        </div>
      ))}
    </div>
  );
};

export default React.memo(UserStats);
