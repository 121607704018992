import { ACTION_TYPES } from "./actionTypes";

export const getConfig = (payload) => {
  return {
    type: ACTION_TYPES.GET_CONFIG,
    payload,
  };
};

export const updateConfig = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_CONFIG,
    payload,
  };
};
