//libs
import React, { useEffect, useState } from "react";
import moment from "moment";

//helper
import cx from "classnames";
import {
  capitalizeText,
  formatImage,
  validContactNumber,
} from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import {
  emailRegex,
  FORM_TYPES,
  nameRegex,
  phoneRegex,
  STRINGS,
  USER_ROLES_LABELS,
  WARNINGS,
} from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import { CustomButton, CustomInput } from "src/components";
import { Icomoon } from "src/assets/icons";
import ProfilePic from "src/assets/images/profile.png";

export const AddSubAdminForm = ({
  onSubmit = () => {},
  handleCancel = () => {},
  startLoading,
  stopLoading,
  formType = "",
  formData = {},
}) => {
  const NAME_FILEDS = [
    { name: STRINGS.FIRST_NAME, label: STRINGS.FIRST_NAME_LABEL },
    { name: STRINGS.LAST_NAME, label: STRINGS.LAST_NAME_LABEL },
  ];

  const CONTACT_FILEDS = [
    { name: STRINGS.EMAIL, label: STRINGS.EMAIL_LABEL },
    { name: STRINGS.PHONE_NUMBER, label: STRINGS.CONTACT_NUMBER_LABEL },
  ];

  const USER_DETAILS = [
    { name: "role", label: "Role" },
    { name: "status", label: "Status" },
    { name: "joiningDate", label: "Joining Date" },
  ];

  const nameRefs = Array(NAME_FILEDS.length).fill(React.createRef());
  const contactRefs = Array(CONTACT_FILEDS.length).fill(React.createRef());

  const [userImage, setUserImage] = useState("");

  const isAdd = formType === FORM_TYPES.add;
  const isView = formType === FORM_TYPES.view;

  useEffect(() => {
    if (formData.profilePicture) {
      setUserImage(formatImage(formData.profilePicture));
    }
  }, [formData.profilePicture]);

  const hanldeFormSubmit = (values, { resetForm }) => {
    onSubmit(values, () => {
      resetForm();
      setUserImage("");
    });
  };

  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={{
        id: formData?.id || "",
        firstName: formData?.fullName?.split(" ")[0] || "",
        lastName: formData?.fullName?.split(" ")[1] || "",
        email: formData?.email || "",
        phoneNumber: formData?.phoneNumber || "",
        profilePicture: formData?.profilePicture || "",
        role: USER_ROLES_LABELS[formData?.role] || "",
        status: formData?.isEmailVerified ? STRINGS.ACTIVE : STRINGS.PENDING,
        joiningDate: moment(formData?.createdAt).format("MMM Do, YYYY") || "",
      }}
      onSubmit={hanldeFormSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        resetForm,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className={S.form}>
          <div className={S.formBody}>
            <div className={S.formBodyTop}>
              <div className={S.formBodyTopImage}>
                <img
                  src={userImage ? userImage : ProfilePic}
                  alt=""
                  width="100%"
                  height="100%"
                  onLoad={() => userImage && stopLoading()}
                />
                {!!!isView && (
                  <>
                    {userImage && (
                      <Icomoon
                        className={S.clearImage}
                        icon={"cancel-circle"}
                        onClick={() => {
                          setFieldValue("file", "");
                          setUserImage("");
                        }}
                      />
                    )}
                    <label htmlFor="image" className={S.formBodyTopImageLabel}>
                      <input
                        type="file"
                        name="image"
                        id="image"
                        accept="image/*"
                        className={S.formBodyTopImageInput}
                        onChange={(event) => {
                          if (event.target.files[0]) {
                            startLoading();
                            setFieldValue("file", event.target.files[0]);
                            setUserImage(
                              URL.createObjectURL(event.target.files[0])
                            );
                            event.target.value = "";
                          }
                        }}
                      />
                      <Icomoon
                        icon="camera"
                        className={S.formBodyTopImageIcon}
                      />
                    </label>
                  </>
                )}
              </div>
              <div className={S.formBodyTopFields}>
                {NAME_FILEDS.map((field, idx) => (
                  <CustomInput
                    key={idx}
                    label={field.label}
                    inputRef={(r) => (nameRefs[idx] = r)}
                    name={field.name}
                    errors={errors}
                    touched={touched}
                    handleChange={(e) => {
                      setFieldValue(field.name, capitalizeText(e.target.value));
                    }}
                    containerClass={S.formBodyTopFieldsContainer}
                    labelClass={S.formBodyTopFieldsLabel}
                    inputClass={S.formBodyInput}
                    value={values[field.name]}
                    type={field.type}
                    readOnly={!!isView}
                    handleBlur={handleBlur}
                  />
                ))}
              </div>
            </div>
            <div className={S.formBodyMid}>
              <CustomInput
                label={STRINGS.EMAIL_LABEL}
                inputRef={(r) => (contactRefs[0] = r)}
                name={STRINGS.EMAIL}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                containerClass={S.formBodyMidFieldsContainer}
                labelClass={S.formBodyMidFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.EMAIL]}
                type={"email"}
                readOnly={!!isView}
                handleBlur={handleBlur}
              />
              <CustomInput
                label={STRINGS.CONTACT_NUMBER_LABEL}
                inputRef={(r) => (contactRefs[1] = r)}
                name={STRINGS.PHONE_NUMBER}
                errors={errors}
                touched={touched}
                handleChange={(e) => {
                  validContactNumber(e.target.value) &&
                    setFieldValue(STRINGS.PHONE_NUMBER, e.target.value);
                }}
                containerClass={S.formBodyMidFieldsContainer}
                labelClass={S.formBodyMidFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.PHONE_NUMBER]}
                type={"tel"}
                readOnly={!!isView}
                handleBlur={handleBlur}
              />

              {isView &&
                USER_DETAILS.map((field, idx) => (
                  <CustomInput
                    key={idx}
                    label={field.label}
                    inputRef={(r) => (contactRefs[idx] = r)}
                    name={field.name}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    containerClass={S.formBodyMidFieldsContainer}
                    labelClass={S.formBodyMidFieldsLabel}
                    inputClass={S.formBodyInput}
                    value={values[field.name]}
                    type={field.type}
                    readOnly={!!isView}
                    handleBlur={handleBlur}
                  />
                ))}
            </div>
          </div>
          <div className={S.formFooter}>
            <CustomButton
              title={!!!isView ? STRINGS.CANCEL : STRINGS.CLOSE}
              onClick={() => {
                handleCancel(() => {
                  resetForm();
                  setUserImage("");
                });
              }}
              buttonClass={cx(S.formFooterButton, S.formFooterCancel)}
            />
            {!!!isView && (
              <CustomButton
                type="submit"
                title={isAdd ? STRINGS.SUBMIT : STRINGS.UPDATE}
                onClick={handleSubmit}
                buttonClass={S.formFooterButton}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = WARNINGS.REQUIRED;
  } else if (!nameRegex.test(values.firstName)) {
    errors.firstName = WARNINGS.INVALID_NAME;
  }
  if (!values.lastName) {
    errors.lastName = WARNINGS.REQUIRED;
  } else if (!nameRegex.test(values.lastName)) {
    errors.lastName = WARNINGS.INVALID_NAME;
  }
  if (!values.email) {
    errors.email = WARNINGS.REQUIRED;
  } else if (!emailRegex.test(values.email)) {
    errors.email = WARNINGS.INVALID_EMAIL;
  }
  // if (!values.phoneNumber) {
  //   errors.phoneNumber = WARNINGS.REQUIRED;
  // } else
  if (values.phoneNumber && !phoneRegex.test(values.phoneNumber)) {
    errors.phoneNumber = WARNINGS.INVALID_CONTACT;
  }
  return errors;
};
