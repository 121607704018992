//libs
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

//constants
import { FORGOT_PASSWORD, LOGIN, REPORT_PDF_DOWNLOAD, RESET_PASSWORD } from "src/shared/constants";

//Views
import { ForgotPassword, Login, ResetPassword, ReportPdfPage } from "src/views";

const PublicRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path={LOGIN} component={Login} />
        <Route path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route path={RESET_PASSWORD} component={ResetPassword} />
        <Route path={REPORT_PDF_DOWNLOAD} component={ReportPdfPage} />
        <Route path="*" component={() => <Redirect push to={LOGIN} />} />
      </Switch>
    </Router>
  );
};

export default PublicRoutes;
