//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

const CustomSelect = ({
  label = "",
  inputRef,
  name = "",
  errors = {},
  touched = {},
  handleChange = () => {},
  containerClass = "",
  inputClass = "",
  labelClass = "",
  placeholder = "",
  type = "text",
  value,
  options,
  ...props
}) => {
  return (
    <div className={cx(S.container, containerClass)}>
      {label && (
        <div className={S.containerLabelDiv}>
          <label
            className={cx(S.containerLabel, labelClass, {
              [S.disable]: props.readOnly,
            })}
          >
            {label}
          </label>
          <span className={S.containerError}>
            {errors[name] && touched[name] ? errors[name] : ""}
          </span>
        </div>
      )}
      <div className={S.fieldWrapper}>
        <select
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          onBlur={props.handleBlur}
          readOnly={props.readOnly}
          className={cx(S.containerField, inputClass, {
            [S.disable]: props.readOnly,
          })}
        >
          {!!!value && <option disabled value={""}>{`Select ${label}`}</option>}
          {options.map((opt) => {
            return (
              <option
                key={typeof opt === "string" ? opt : opt.value}
                value={typeof opt === "string" ? opt : opt.value}
              >
                {typeof opt === "string" ? opt : opt.label}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default CustomSelect;
