//libs
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//decorators
import cx from "classnames";

//components
import {
  BreadcrumbMenu,
  ConfirmDialog,
  HalfCardModal,
  MemberImageInfo,
} from "src/components";
import { EditUserForm } from "../EditUserForm";
import MemberRoutes from "../MemberRoutes";

//constants
import {
  STRINGS,
  USER_PERMISSIONS,
  USER_ROLES,
  WARNINGS,
} from "src/shared/constants";
import { UserStats, SecondaryUserCard } from "../Widgets";

//styles
import S from "./style.module.scss";
import { Icomoon } from "src/assets/icons";
import { withSnackbar } from "notistack";
import { ResetPasswordForm } from "./resetPasswordForm";

const MemberProfile = ({
  userDetails = {},
  getUser = () => {},
  deleteUser = () => {},
  updateUser = () => {},
  enqueueSnackbar = () => {},
  startLoading = () => {},
  stopLoading = () => {},
  setReportData = () => {},
  resetUserPassword = () => {},
}) => {
  const history = useHistory();
  const permissions = userDetails.permissions || [];
  const ID = history?.location?.search?.slice(1);

  const [userData, setUserData] = useState({});
  const [relatedUsersData, setRelatedUsersData] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [showHalfCard, setShowHalfCard] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [dialogType, setDialogType] = useState(1); //1 for block 2 for delete 3 for password reset
  const [passwordDetails, setPasswordDetails] = useState("");

  useEffect(() => {
    if (ID) {
      fetchUser(ID);
    }
    return () => {
      setReportData({});
    };
    // eslint-disable-next-line
  }, [ID]);

  const fetchUser = (userId) => {
    getUser({
      payload: {
        userId,
      },
      success: (data) => {
        setTimeout(() => {
          setUserData(data.data || {});
          setRelatedUsersData(data.users || []);
          setTabs(1);
        }, 1);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const updateUserDetails = (values, callback) => {
    const { id, ...rest } = values;
    updateUser({
      payload: {
        userId: ID,
        values: rest,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        if (callback) callback();
        fetchUser(ID);
        setShowHalfCard(false);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleDeleteClick = (userId = ID) => {
    if (!permissions.includes(USER_PERMISSIONS.DELETE)) {
      enqueueSnackbar(WARNINGS.DELETE_DENIED, { variant: "warning" });
      return;
    }
    if (userDetails.id === userId) {
      enqueueSnackbar(WARNINGS.SELF_DELETE, { variant: "error" });
      return;
    }
    setDialogType(2);
    setShowDialog(true);
  };

  const handleUpdate = (values, callback) => {
    if (!permissions.includes(USER_PERMISSIONS.UPDATE)) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    updateUserDetails(values, callback);
  };

  const handleBlock = () => {
    if (!permissions.includes(USER_PERMISSIONS.BLOCK)) {
      enqueueSnackbar(WARNINGS.BLOCK_DENIED, { variant: "warning" });
      return;
    }
    setDialogType(1);
    setShowDialog(true);
  };

  const handleDelete = () => {
    deleteUser({
      payload: {
        userId: ID,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        history.goBack();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      if (dialogType === 1) {
        let values = {
          isBlocked: !userData.isBlocked,
        };
        updateUserDetails(values); //block user
      } else if (dialogType === 2) {
        handleDelete();
      } else if (dialogType === 3) {
        handleResetPassword();
      }
    }
    setShowDialog(false);
  };

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    setTimeout(() => {
      callback();
    }, 320);
  };

  const hanldeEditClick = () => {
    if (!permissions.includes(USER_PERMISSIONS.UPDATE)) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    setShowHalfCard(true);
  };

  const handleActivateUser = () => {
    if (
      userDetails.role !== USER_ROLES.SUPER_ADMIN &&
      userDetails.role !== USER_ROLES.SUB_ADMIN
    ) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    let values = {
      isEmailVerified: true,
    };
    updateUserDetails(values);
  };

  const isReport = history?.location?.pathname?.includes("report") ?? false;

  const handleSubmit = (values) => {
    setPasswordDetails({ ...values });
    setShowDialog(true);
    setDialogType(3);
  };

  const handleResetPassword = () => {
    const { confirmPassword, ...rest } = passwordDetails;
    setShowDialog(false);
    setDialogType(1);
    resetUserPassword({
      payload: { ...rest, userId: userData.id },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        setTabs(1);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  return (
    <section className={cx(S.section, S.sectionClass)}>
      <BreadcrumbMenu title={STRINGS.MEMBERS} subTitle={STRINGS.PROFILE}>
        {!isReport && (
          <div className={S.headerButtons}>
            <div className={S.tooltip} onClick={hanldeEditClick}>
              <Icomoon
                className={cx(S.icon, S.edit, {
                  [S.disable]: !permissions.includes(USER_PERMISSIONS.UPDATE),
                })}
                icon={"pencil"}
              />
              <span className={S.tooltiptext}>Edit</span>
            </div>
            <div className={S.tooltip} onClick={handleDeleteClick}>
              <Icomoon
                className={cx(S.icon, S.delete, {
                  [S.disable]: !permissions.includes(USER_PERMISSIONS.DELETE),
                })}
                icon={"bin"}
              />
              <span className={S.tooltiptext}>Delete</span>
            </div>
            {userData.id ? (
              userData.isEmailVerified ? (
                <div className={S.tooltip} onClick={handleBlock}>
                  <Icomoon
                    className={cx(S.icon, S.view, {
                      [S.disable]: !permissions.includes(USER_PERMISSIONS.VIEW),
                    })}
                    icon={"blocked"}
                  />
                  <span className={S.tooltiptext}>
                    {userData?.isBlocked ? STRINGS.ACTIVATE : STRINGS.BLOCK}
                  </span>
                </div>
              ) : (
                <div className={S.tooltip} onClick={handleActivateUser}>
                  <Icomoon
                    className={cx(S.icon, S.view, S.power, {
                      [S.powerOff]: !userData?.isEmailVerified,
                    })}
                    icon={"power-off"}
                  />
                  <span className={S.tooltiptext}>{STRINGS.ACTIVATE}</span>
                </div>
              )
            ) : null}
          </div>
        )}
      </BreadcrumbMenu>

      {userData.id && (
        <div className={S.container}>
          <div className={S.containerLeft}>
            <MemberImageInfo userData={userData} />

            <UserStats userData={userData} />
          </div>
          <div className={S.containerRight}>
            <div className={S.containerRightTabs}>
              {!isReport && (
                <div
                  onClick={() => setTabs(1)}
                  className={cx(
                    S.containerRightTabsTitle,
                    tabs === 1 && S.containerRightTabsActive
                  )}
                >
                  User Details
                </div>
              )}
              {!isReport &&
                userData.role === 2 &&
                !!relatedUsersData?.length && (
                  <div
                    onClick={() => setTabs(2)}
                    className={cx(
                      S.containerRightTabsTitle,
                      tabs === 2 && S.containerRightTabsActive
                    )}
                  >
                    Secondary Users
                  </div>
                )}

              {history?.location?.state?.fromPrimary || isReport ? (
                <div className={S.containerRightTabsBack}>
                  <div
                    onClick={() => history.goBack()}
                    className={cx(
                      S.containerRightTabsTitle,
                      S.containerRightTabsActive,
                      S.containerRightTabsActiveBtn
                    )}
                  >
                    Back
                  </div>
                </div>
              ) : userDetails.role === USER_ROLES.SUPER_ADMIN ||
                userDetails.role === USER_ROLES.SUB_ADMIN ? (
                <div
                  className={cx(
                    S.containerRightTabsBack,
                    S.containerRightTabsBtn
                  )}
                >
                  <div
                    onClick={() => setTabs(3)}
                    className={cx(
                      S.containerRightTabsTitle,
                      tabs === 3 && S.containerRightTabsActive
                    )}
                  >
                    {STRINGS.RESET_USER_PASSWORD}
                  </div>
                </div>
              ) : null}
            </div>

            {tabs === 1 && <MemberRoutes />}
            {tabs === 2 && <SecondaryUsers data={relatedUsersData || []} />}
            {tabs === 3 && <ResetPassword handleSubmit={handleSubmit} />}
          </div>
        </div>
      )}
      <HalfCardModal showCard={showHalfCard} title={STRINGS.UPDATE_USER}>
        <EditUserForm
          formData={userData}
          handleCancel={handleFormClose}
          onSubmit={handleUpdate}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </HalfCardModal>

      {showDialog && (
        <ConfirmDialog
          title={
            dialogType === 1
              ? userData.isBlocked
                ? STRINGS.ACTIVATE_MSG
                : STRINGS.BLOCK_MSG
              : dialogType === 2
              ? STRINGS.DELETE_MSG
              : STRINGS.RESET_USER_PASSWORD_TITLE
          }
          onClose={handleConfirmDialog}
          yesTitle={
            dialogType === 1
              ? userData.isBlocked
                ? STRINGS.ACTIVATE
                : STRINGS.BLOCK
              : dialogType === 2
              ? STRINGS.DELETE
              : STRINGS.CONTINUE
          }
        />
      )}
    </section>
  );
};

export default withSnackbar(MemberProfile);

const SecondaryUsers = ({ data = [] }) => {
  return (
    <div className={S.secondaryUsers}>
      {data.map((user) => (
        <SecondaryUserCard userData={user} />
      ))}
    </div>
  );
};

const ResetPassword = ({ handleSubmit = () => {} }) => {
  return (
    <div className={cx(S.secondaryUsers, S.form)}>
      <ResetPasswordForm onSubmit={handleSubmit} />
    </div>
  );
};
