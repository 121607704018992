//libs
import React from "react";

//decorator
import cx from "classnames";

//styles
import S from "./style.module.scss";
import { MemberImageInfo } from "src/components";
import { useHistory } from "react-router-dom";
import { MEMBER_PROFILE } from "src/shared/constants";

export const SecondaryUserCard = ({ userData = {} }) => {
  const history = useHistory();

  const handleClick = () =>
    history.push({
      pathname: MEMBER_PROFILE,
      search: userData.id,
      state: { fromPrimary: true }
    });

  return (
    <div className={cx(S.container)} onClick={handleClick}>
      <MemberImageInfo userData={userData} />
    </div>
  );
};
