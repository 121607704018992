import { connect } from "react-redux";
import {
  deleteUser,
  getUser,
  updateUser,
  startLoading,
  stopLoading,
  setReportData,
  resetUserPassword,
} from "src/redux/actions";
import MemberProfile from "./MemberProfile";

const mapStateToProps = (state) => {
  return {
    userDetails: state.common.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (payload) => dispatch(getUser(payload)),
    deleteUser: (payload) => dispatch(deleteUser(payload)),
    updateUser: (payload) => dispatch(updateUser(payload)),
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    setReportData: (payload) => dispatch(setReportData(payload)),
    resetUserPassword: (payload) => dispatch(resetUserPassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfile);
