/* eslint-disable eqeqeq */
//helpers
import memoizeOne from "memoize-one";
import moment from "moment";

//constants
import {
  BASE_URL,
  numberRegex,
  STRINGS,
  USER_IMAGES,
  USER_ROLES,
} from "../constants";

export const pageNumbers = (selectedPage, count, pagesCutOff) => {
  let pageLabels = [];
  let totalPages = Math.ceil(count / 10);
  let start,
    end,
    ceiling = Math.ceil(pagesCutOff / 2),
    floor = Math.floor(pagesCutOff / 2);

  if (totalPages < pagesCutOff) {
    start = 0;
    end = totalPages;
  } else if (selectedPage >= 1 && selectedPage <= ceiling) {
    start = 0;
    end = pagesCutOff;
  } else if (selectedPage + floor >= totalPages) {
    start = totalPages - pagesCutOff;
    end = totalPages;
  } else {
    start = selectedPage - ceiling;
    end = selectedPage + floor;
  }

  for (let i = start; i < end; i++) {
    pageLabels.push(i + 1);
  }

  return pageLabels;
};

export const validContactNumber = (value) => {
  if (value === "" || /^[\d+-]+$/.test(value)) {
    return true;
  } else {
    return false;
  }
};

export const validPostalCode = (value) => {
  if (value === "" || numberRegex.test(value)) return true;
  return false;
};

export const formatImage = (url) => `${BASE_URL}uploads/profilePics${url}`;

export const matchUserImage = (id) =>
  USER_IMAGES.find((image) => image._id === id)?._image;

export const capitalizeText = (value) =>
  value.charAt(0).toUpperCase() + value.slice(1);

export const permissionsFormatter = (data = []) => {
  let permissions = {};
  data.forEach((d) => {
    let key = getKeyByValue(USER_ROLES, d.role);
    if (!Object.keys(permissions).includes(key)) {
      permissions = {
        ...permissions,
        [key]: [],
      };
    }
    permissions[key].push(d.permission);
  });
  return permissions;
};

const getKeyByValue = (object, value) => {
  for (var prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
};

export const ageCalculator = (date) => {
  let today = new Date();
  let birthDate = new Date(date);
  let age = today.getFullYear() - birthDate.getFullYear();
  let m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

export const BMIcalculator = (weight, height) => {
  if (weight && height) {
    return `${(weight / (height * 0.01 * height * 0.01)).toFixed(2)} lbs/sq.ft`;
  }
  return STRINGS.EMPTY_DATA;
};

export const sanitizeQuery = (payload) => {
  let query = "";
  const {
    userId,
    limit,
    offset,
    role,
    searchString,
    filterType,
    date,
    startTime,
    endTime,
    sortOrder,
    sortKey,
    filterDeviceType,
    appType,
    appFilter,
    notificationType,
    isEmailVerified,
    createdAt,
    oxySessionId,
    fromDate,
    toDate,
  } = payload;
  if (userId) query += `userId=${userId}&`;
  if (filterType) query += `filterType=${filterType}&`;
  if (limit) query += `limit=${limit}&`;
  if (offset) query += `offset=${offset}&`;
  if (role) query += `role=${role}&`;
  if (searchString) query += `searchString=${searchString}&`;
  if (date) query += `date=${new Date(date).toISOString()}&`;
  if (startTime) query += `startTime=${startTime}&`;
  if (endTime) query += `endTime=${endTime}&`;
  if (sortOrder) query += `sortOrder=${sortOrder}&`;
  if (sortKey) query += `sortKey=${sortKey}&`;
  if (filterDeviceType) query += `filterDeviceType=${filterDeviceType}&`;
  if (appType) query += `appType=${appType}&`;
  if (appFilter) query += `appFilter=${appFilter}&`;
  if (notificationType) query += `notificationType=${notificationType}&`;
  if (isEmailVerified) query += `isEmailVerified=${isEmailVerified}&`;
  if (createdAt) query += `createdAt=${createdAt}&`;
  if (oxySessionId) query += `oxySessionId=${oxySessionId}&`;
  if (fromDate) query += `fromDate=${fromDate}&`;
  if (toDate) query += `toDate=${toDate}&`;
  return query.slice(0, query.length - 1);
};

export const sanitizAddress = (data) => {
  let address = "";
  const { city, town, country } = data;
  // if (buildingNumber) address += `${buildingNumber},`;
  // if (streetName) address += ` ${streetName}, `;
  if (city) address += ` ${city},`;
  if (town) address += ` ${town},`;
  if (country) address += ` ${country},`;
  else address = STRINGS.EMPTY_DATA;
  return address.slice(0, address.length - 1);
};

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
};

export const getDeviceName = (deviceType) => {
  switch (deviceType) {
    case 1:
      return "Spiro Link";
    case 2:
      return "Finger Tip Oxymeter";
    case 3:
      return "Oxy Knight";
    case 4:
      return "Oxy Knight Lite";
    case 5:
      return "ACT(Asthma Control Test)";
    default:
      return "";
  }
};

export const getTestType = (deviceType) => {
  switch (deviceType) {
    case 1:
      return "Spiro Link";
    case 2:
      return "SpO2";
    case 3:
      return "SpO2";
    case 4:
      return "SpO2";
    case 5:
      return "ACT(Asthma Control Test)";
    default:
      return "";
  }
};

export const getDuration = (createdAt, updatedAt) => {
  let time = (
    Math.abs(new Date(updatedAt).getTime() - new Date(createdAt).getTime()) /
    60000
  ).toFixed(0);

  const getMins = (v) => (v < 10 ? `0${v}` : `${v}`);
  if (time < 60) {
    return `00:${getMins(time)} hrs`;
  }

  let mins = getMins(time % 60);
  let hrs = getMins(Math.floor(time / 60));

  return `${hrs}:${mins} hrs`;
};

export const toFeet = (n) => {
  let length = n / 2.54;
  let feet = Math.floor(length / 12);
  let inches = Math.round(length - feet * 12);
  return feet + "Ft " + inches + "In";
};

export const toLbs = (v) => {
  let lbs = (v * 2.2046).toFixed(0);
  return lbs + " lbs";
};

export const convertToLBS = (n) => {
  let lbs = (n * 2.2046).toFixed(0);
  return lbs;
};

export const convertToKg = (n) => {
  let kgs = (n / 2.2046).toFixed(2);
  return kgs;
};

export const convertToFeet = (n) => {
  let length = n / 2.54;
  let feet = Math.floor(length / 12);
  let inches = Math.round(length - feet * 12);
  return `${feet}.${inches}`;
};

export const convertToCms = (n) => {
  let feet = n.split(".")[0];
  feet = feet ? feet * 30.48 : 0;
  let inches = n.split(".")[1];
  inches = inches ? inches * 2.54 : 0;
  return feet + inches;
};

export const secondsToHrs = (v) => {
  v = Number(v);
  var h = Math.floor(v / 3600);
  var m = Math.floor((v % 3600) / 60);

  var hDisplay = h > 9 ? h : `0${h}:`;
  var mDisplay = m > 9 ? m : `0${m}`;
  return hDisplay + mDisplay + " hrs";
};

export const getTotalTests = memoizeOne((data = {}) => {
  if (!data) return 0;
  const counts = Object.entries(data).map((a) => a[1]);
  if (counts?.length) {
    return counts.reduce((acc, cur) => acc + cur, 0);
  }
  return 0;
});

export const computeSessions = memoizeOne((data = []) => {
  const login = [];
  const signup = [];

  const dualDigit = (v) => (v < 10 ? `0${v}` : v);

  return data
    .sort(
      (a, b) =>
        new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
    )
    .reduce((prev, cur, i) => {
      let key = "";
      key = `_${dualDigit(new Date(cur.updatedAt).getMonth() + 1)}/${dualDigit(
        new Date(cur.updatedAt).getDate()
      )}`;
      if (cur.eventType === 1) {
        login[key] = login[key] ?? [];
        login[key] = {
          SpiroLink:
            cur.appType == "SpiroLink"
              ? login[key].SpiroLink
                ? login[key].SpiroLink + 1
                : 1
              : login[key].SpiroLink ?? 0,
          OxyKnight:
            cur.appType == "OxyKnight"
              ? login[key].OxyKnight
                ? login[key].OxyKnight + 1
                : 1
              : login[key].OxyKnight ?? 0,
          AsthmaGo:
            cur.appType == "AsthmaGo"
              ? login[key].AsthmaGo
                ? login[key].AsthmaGo + 1
                : 1
              : login[key].AsthmaGo ?? 0,
        };
      } else {
        signup[key] = signup[key] ?? [];
        signup[key] = {
          SpiroLink:
            cur.appType == "SpiroLink"
              ? signup[key].SpiroLink
                ? signup[key].SpiroLink + 1
                : 1
              : signup[key].SpiroLink ?? 0,
          OxyKnight:
            cur.appType == "OxyKnight"
              ? signup[key].OxyKnight
                ? signup[key].OxyKnight + 1
                : 1
              : signup[key].OxyKnight ?? 0,
          AsthmaGo:
            cur.appType == "AsthmaGo"
              ? signup[key].AsthmaGo
                ? signup[key].AsthmaGo + 1
                : 1
              : signup[key].AsthmaGo ?? 0,
        };
      }
      return { login, signup };
    }, {});
});

export const getStartEndTime = memoizeOne((type = 7) => {
  let date = new Date();
  let startTime = moment(date).subtract(type, "days").toISOString();
  let endTime = moment(date).toISOString();

  if (type === "month") {
    startTime = moment(date).startOf(type).toISOString();
  }
  return { startTime, endTime };
});

export const daysDiff = (startDate, endDate) => {
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Calculate the difference in days
  const diffInDays = Math.round(Math.abs((end - start) / oneDay));

  return diffInDays;
};
