//libs
import React, { useEffect, useState } from "react";
import moment from "moment";

//styles
import S from "./style.module.scss";

//constants
import { STRINGS } from "src/shared/constants";

//helpers
import { connect } from "react-redux";
import { getOxyTestSessionData } from "src/redux/actions";
import { getDuration, secondsToHrs } from "src/shared/services";

//components
import { LineChart } from "src/components";
import ReportCharts from "src/components/Charts/ReportCharts";
import SleepChart from "src/components/Charts/SleepChart";

const OxyKnightWidget = ({
  type = 0,
  data = {},
  getOxyTestSessionData = () => { },
  enqueueSnackbar = () => { },
  loading,
}) => {
  const [graphData, setGraphData] = useState();

  useEffect(() => {
    if (data?.id) {
      getOxyTestSessionData({
        payload: {
          oxySessionId: data?.id,
        },
        success: (data) => {
          setGraphData(data.data || []);
        },
        fail: (msg) => {
          enqueueSnackbar(msg, { variant: "error" });
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  if (!Object.keys(data).length) return null;

  type = data.testType == "continues_test" ? 1 : 0;

  const { hourlyData, restFul, events, odi } = graphData ?? {};

  const avgSpo2 =
    hourlyData?.reduce((t, e) => t + e?.averageValues?.avgspO2, 0) /
    hourlyData?.length;

  const avgPr =
    hourlyData?.reduce((t, e) => t + e?.averageValues?.avgPr, 0) /
    hourlyData?.length;

  const SpO2Series = hourlyData?.map((e, i) => {
    return {
      x: new Date(e.startHour).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      y: Number(e?.averageValues?.avgspO2)?.toFixed(0),
    };
  });

  const PrSeries = hourlyData?.map((e, i) => {
    return {
      x: new Date(e.startHour).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      }),
      y: Number(e?.averageValues?.avgPr)?.toFixed(0),
    };
  });

  const SpotTest = [
    { title: "SpO2 Level", value: avgSpo2?.toFixed(0) },
    { title: "Pulse Level", value: avgPr?.toFixed(0) },
  ];

  const ContinueTest = [
    { title: "Duration", value: getDuration(data?.createdAt, data?.updatedAt) },
    { title: "Restfull Sleep", value: secondsToHrs(restFul ?? 0) },
    { title: "Total Oxygen Events", value: events ?? "" },
    { title: "ODI-Oxygen Desat Index", value: odi ?? "" },
    { title: "Avg. SpO2 Level", value: avgSpo2?.toFixed(0) ?? "" },
    { title: "Avg. Pulse Level", value: avgPr?.toFixed(0) ?? "" },
  ];

  const TestType = type ? ContinueTest : SpotTest;
  const testTime = type
    ? new Date(graphData?.createdAt).toLocaleTimeString() +
    ` - ` +
    new Date(graphData?.updatedAt).toLocaleTimeString()
    : new Date(data?.createdAt).toLocaleTimeString();
  if (loading) return null;

  const renderContinueTestGraphs = () => {
    if (!graphData) return null;

    return (
      <>
        <div className={S.graphsContainer}>
          <p className={S.logHeading}>{STRINGS.SLEEP_QUALITY}</p>
          <SleepChart
            data={graphData}
            height={160}
            timezone={moment.tz.guess()}
          />
        </div>
        <ReportCharts
          data={graphData}
          titles={[]}
          containerClassname={S.graphsContainer}
          headerClassname={S.logHeading}
          graphClassname={S.chartGraph}
          height={250}
          timezone={moment.tz.guess()}
          maxX={12}
          chartHeadings={[STRINGS.OXYGEN_LEVEL, STRINGS.PULSE]}
        />
      </>
    );
  };

  const renderSpotTestGraphs = () => (
    <>
      <div className={S.graphsContainer}>
        <p className={S.logHeading}>{STRINGS.OXYGEN_LEVEL}</p>
        <LineChart height={280} data={SpO2Series} />
      </div>
      <div className={S.graphsContainer}>
        <p className={S.logHeading}>{STRINGS.PULSE}</p>
        <LineChart height={280} data={PrSeries} min={0} max={180} />
      </div>
    </>
  );

  return (
    <div className={S.container}>
      <div className={S.header}>
        <div className={S.headerDiv}>
          <p className={S.headerTitle}>{"Spo2"}</p>
          <p className={S.headerText}>{type ? "Continue Test" : "Spot Test"}</p>
        </div>
        <p className={S.headerText}>
          {new Date(data?.createdAt).toLocaleDateString()}
        </p>
        <p className={S.headerText}>{testTime}</p>
      </div>
      <div className={S.log}>
        {TestType.map((d, key) => (
          <div
            className={S.logWidget}
            key={key}
            style={{ borderColor: COLORS[key] }}
          >
            <p className={S.logWidgetTitle} style={{ color: COLORS[key] }}>
              {d.title || d}
            </p>
            {d?.value && (
              <span className={S.logWidgetText} style={{ color: COLORS[key] }}>
                {d.value}
              </span>
            )}
          </div>
        ))}
      </div>

      <div className={S.graphs} style={{ display: type ? "block" : "flex" }}>
        {!!type ? renderContinueTestGraphs() : renderSpotTestGraphs()}
      </div>
    </div>
  );
};

const COLORS = ["black"];

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOxyTestSessionData: (payload) =>
      dispatch(getOxyTestSessionData(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(OxyKnightWidget));
