//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

const CustomTextArea = ({
  label = "",
  inputRef,
  name = "",
  errors = {},
  touched = {},
  handleChange = () => {},
  containerClass = "",
  inputClass = "",
  labelClass = "",
  placeholder = "",
  type = "text",
  value,
  ...props
}) => {
  return (
    <div className={cx(S.container, containerClass)}>
      <div className={S.containerLabelDiv}>
        <label
          className={cx(S.containerLabel, labelClass, {
            [S.disable]: props.readOnly,
          })}
        >
          {label}
        </label>
        <span className={S.containerError}>
          {errors[name] && touched[name] ? errors[name] : ""}
        </span>
      </div>
      <div className={S.fieldWrapper}>
        <textarea
          name={name}
          className={cx(S.containerField, inputClass, {
            [S.disable]: props.readOnly,
          })}
          ref={inputRef}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          type={type}
          onBlur={props.handleBlur}
          maxLength={props.maxLength}
          style={{ paddingRight: props.showPasswordIcon ? 30 : 2 }}
          autoComplete="off"
          readOnly={props.readOnly}
          rows={props.rows}
        />
      </div>
    </div>
  );
};

export default React.memo(CustomTextArea);
