export const ChartColors = {
  green: (alpha = 1) => `rgba(34, 139, 34, ${alpha})`,
  blue: (alpha = 1) => `rgba(0, 71, 171,${alpha})`,
  lightBlue: (alpha = 1) => `rgba(100, 149, 237,${alpha})`,
  lightGreen: (alpha = 1) => `rgba(64, 181, 173,${alpha})`,
  one: "#33b2df",
  two: "#546E7A",
  three: "#d4526e",
  four: "#13d8aa",
  five: "#A5978B",
  six: "#2b908f",
};
