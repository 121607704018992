//libs
import React, { useEffect, useState } from "react";
import cx from "classnames";
import queryString from "query-string";
import moment from "moment-timezone";

//styles
import S from "./ReportPdfPage.module.scss";

//hooks
import { useHistory } from "react-router-dom";

//constants
import OxyknightLogo from "src/assets/images/logo_oxy.png";
import { PLATFORM, STRINGS } from "src/shared/constants";

//widgets
import AreaChart from "src/components/Charts/ReportCharts";
import SpiroLinkPdf from "./SpiroLinkPdf";

//helpers
import {
  ageCalculator,
  secondsToHrs,
  toFeet,
  toLbs,
} from "src/shared/services";
import SleepChart from "src/components/Charts/SleepChart";

const getMins = (value) => Math.ceil(value / 60);

const ReportPdfPage = ({ getRecordsForPdf = () => { } }) => {
  const history = useHistory();
  const [pdfData, setPdfData] = useState(null);
  const [pdfTimezone, setPdfTimezone] = useState("America/Phoenix");
  const [reportType, setReportType] = useState("");
  const [queryProps, setQueryProps] = useState(null);

  const title = "Comprehensive Pulse Oximetry Report";

  useEffect(() => {
    const { oxySessionId, userId, timezone, reportType, toDate, fromDate } =
      queryString.parse(history?.location?.search);

    setQueryProps({
      userId,
      timezone,
      reportType,
      toDate,
      fromDate,
    });

    if (reportType === "spiroLink") {
      setReportType(reportType);
      return;
    }

    getRecordsForPdf({
      payload: {
        oxySessionId: oxySessionId,
        userId: userId,
      },
      success: (data) => {
        setPdfData(data.data);
        timezone && setPdfTimezone(timezone);
      },
      fail: (msg) => { },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (reportType === "spiroLink") {
    return (
      <SpiroLinkPdf
        userId={queryProps?.userId}
        timezone={queryProps?.timezone}
        fromDate={queryProps?.fromDate}
        toDate={queryProps?.toDate}
      />
    );
  }

  if (!pdfData) return null;

  var startTime = moment(pdfData.session.createdAt);
  var endTime = moment(pdfData.session.updatedAt);

  // calculate total duration
  var duration = moment.duration(endTime.diff(startTime));

  // duration in hours
  var hours = parseInt(duration.asHours());

  // duration in minutes
  var minutes = parseInt(duration.asMinutes()) % 60;

  const roundedValue = (value) => {
    return value ? Math.round(value) : "0";
  };

  const userInfo = [
    { title: "Name:", value: pdfData.user.fullName || "--" },
    { title: "Member ID:", value: pdfData.user.memberId || "--" },
    { title: "Gender:", value: pdfData.user.gender || "--" },
    { title: "Height:", value: toFeet(pdfData.user.height || "0") },
    { title: "Weight:", value: toLbs(pdfData.user.weight || "0") },
    { title: "Age:", value: `${ageCalculator(pdfData.user.dob)} Yrs` || "0" },
  ];

  const durationInfo = [
    {
      title: "Start:",
      value: moment(pdfData.session.createdAt)
        .tz(pdfTimezone)
        .format("YYYY-MM-DD hh:mm:ss A"),
    },
    {
      title: "End:",
      value: moment(pdfData.session.updatedAt)
        .tz(pdfTimezone)
        .format("YYYY-MM-DD hh:mm:ss A"),
    },
    { title: "Duration:", value: `${hours}:${minutes}` },
  ];

  const spO2Data = [
    {
      title: "Time ≤ 88%",
      value: `${getMins(pdfData?.session?.spo2Less88)} MIN` || "0 MIN",
    },
    {
      title: "Time ≤ 89%",
      value: `${getMins(pdfData?.session?.spo2Less89)} MIN` || "0 MIN",
    },
    {
      title: "High SpO2",
      value: `${roundedValue(pdfData?.session?.maxOxygen)}%`,
    },
    {
      title: "Low SpO2",
      value: `${roundedValue(pdfData?.session?.minOxygen)}%`,
    },
    {
      title: "Average SpO2",
      value: `${roundedValue(pdfData?.session?.avgOxygen)}%`,
    },
    {
      title: "Baseline SpO2",
      value: `${roundedValue(pdfData?.session?.baselineSpO2)}%`,
    },
    {
      title: "5% under baseline",
      value: `${getMins(pdfData?.session?.fivePerUnderBase)} MIN` || "0 MIN",
    },
    {
      title: "Time consecutive ≤ 88%",
      value:
        `${getMins(pdfData?.session?.timeConsecutiveLess88)} MIN` || "0 MIN",
    },
    // { title: "Awake SpO2", value: "0%" },
  ];

  const desaturationData = [
    // { title: "4% Desaturation Events* ", value: "0" },
    // {
    //   title: "Oxygen Desaturation Index (ODI)**",
    //   value: pdfData.session.odi || "0",
    // },
    { title: "Pulse Data" },
    {
      title: "High pulse",
      value: `${roundedValue(pdfData?.session?.maxPulseRate)} BPM`,
    },
    {
      title: "Low Pulse",
      value: `${roundedValue(pdfData?.session?.minPulseRate)} BPM`,
    },
    {
      title: "Average Pulse",
      value: `${roundedValue(pdfData?.session?.avgPulseRate)} BPM`,
    },
    // { title: "Sleep Data" },
    // { title: "Time Asleep", value: secondsToHrs(pdfData.session.restFul || 0) },
    // { title: "Time Awake", value: secondsToHrs(pdfData.session.awake || 0) },
    // {
    //   title: "Time Restless",
    //   value: secondsToHrs(pdfData.session.restLess || 0),
    // },
  ];

  return (
    <div className={S.container}>
      <div className={S.imageWrapper}>
        <img className={S.imageWrapperLogo} src={OxyknightLogo} alt="" />
      </div>
      <p className={S.heading}>{title}</p>
      <div className={S.details}>
        <p className={S.detailsTitle}>{"User Information"}</p>
        <div className={S.detailsChip}>
          {userInfo.map((data, key) => (
            <div key={key} className={S.detailsChipData}>
              <span className={S.detailsChipDataTitle}>{data.title} </span>
              <span className={S.detailsChipDataInfo}>{data.value}</span>
            </div>
          ))}
        </div>
        {/* <div className={S.detailsChip}>
          <div className={S.detailsChipData}>
            <span className={S.detailsChipDataTitle}>Average SpO2: </span>
            <span className={S.detailsChipDataInfo}>{avgSpo2}</span>
          </div>
          <div className={S.detailsChipData}>
            <span className={S.detailsChipDataTitle}>Average Pulse Rate: </span>
            <span className={S.detailsChipDataInfo}>{avgPulse}</span>
          </div>
        </div> */}
        <div className={S.detailsChip}>
          {durationInfo.map((data, key) => (
            <div key={key} className={S.detailsChipData}>
              <span className={S.detailsChipDataTitle}>{data.title}</span>
              <span className={S.detailsChipDataInfo}>{data.value}</span>
            </div>
          ))}
        </div>

        <div className={S.reportBox}>
          <div className={S.reportBoxData}>
            <p className={S.heading}>Oxygen (SpO2) Data</p>
            {spO2Data.map((data, key) => (
              <div key={key} className={S.reportBoxValues}>
                <span className={S.reportBoxValuesTitle}>{data.title}</span>
                <span className={S.reportBoxValuesInfo}>{data.value}</span>
              </div>
            ))}
          </div>
          <div className={S.reportBoxData}>
            {/* <p className={S.heading}>Desaturation Data</p> */}
            {desaturationData.map((data, key) => (
              <div key={key} className={S.reportBoxValues}>
                <span
                  className={cx(S.reportBoxValuesTitle, {
                    [S.reportBoxValuesCenter]: !data.value,
                  })}
                >
                  {data.title}
                </span>
                {!!data.value && (
                  <span className={S.reportBoxValuesInfo}>{data.value}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={S.chart}>
        {/* {pdfData.session && pdfData.session?.sleepMarks && (
          <>
            <p className={S.sleepHeading}>{STRINGS.SLEEP_QUALITY}</p>
            <SleepChart
              data={pdfData.session}
              height={150}
              timezone={pdfTimezone}
            />
          </>
        )} */}
        <AreaChart
          data={pdfData.session}
          yLabels={["SpO2", "Pulse"]}
          containerClassname={S.chartContainer}
          headerClassname={S.chartHeading}
          graphClassname={S.chartGraph}
          timezone={pdfTimezone}
          platform={PLATFORM.APP}
        />
      </div>
      {/* <p className={S.bottomText}>
        * Definition of a 4% Desaturation Event: drop in SpO2 by at least 4%
        from baseline SpO2 for a minimum duration of 10 sec. ** ODI is
        calculated by: number of desaturation events / total sleep hours. Unit
        is per hour.
      </p> */}
      <p className={S.footer}>
        For personal wellness use only. Not for diagnostic use.
      </p>
    </div>
  );
};

export default ReportPdfPage;
