//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
  sanitizeQuery,
  updateAuthToken,
} from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  startLoading,
  stopLoading,
  updateAuthTokenRedux,
} from "../actions";

function* uploadImage({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());

    const formData = new FormData();
    formData.append("file", payload);

    const { data = {} } = yield postRequest({
      API: API.UPLOAD_FILE,
      DATA: formData,
    });

    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* addUser({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.ADD_USER,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getUsers({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.USERS(`${sanitizeQuery(payload)}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getUser({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.USERS(`userId=${payload.userId}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* updateUser({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield patchRequest({
      API: API.USERS(`userId=${payload.userId}`),
      DATA: { ...payload.values },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* deleteUser({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield deleteRequest({
      API: API.DELETE_USER(),
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* changePasswordSaga({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield putRequest({
      API: API.CHANGE_PASSWORD_ADMIN,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getUserReport({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.USER_REPORT(`${sanitizeQuery(payload)}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* recreateSleepAnalysis({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: `${API.RECREATE_SLEEP_ANALYSIS}?oxySessionId=${payload.oxySessionId}`
    });

    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getOxyTestSessionData({
  payload: { payload = {}, success = () => { }, fail = () => { } } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.OXY_TEST_DATA(`oxySessionId=${payload?.oxySessionId}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}
function* UserSaga() {
  yield all([
    takeLatest(ACTION_TYPES.UPLOAD_IMAGE, uploadImage),
    takeLatest(ACTION_TYPES.ADD_USER, addUser),
    takeLatest(ACTION_TYPES.GET_USERS, getUsers),
    takeLatest(ACTION_TYPES.GET_USER, getUser),
    takeLatest(ACTION_TYPES.UPDATE_USER, updateUser),
    takeLatest(ACTION_TYPES.DELETE_USER, deleteUser),
    takeLatest(ACTION_TYPES.CHANGE_PASSWORD, changePasswordSaga),
    takeLatest(ACTION_TYPES.GET_USER_REPORT, getUserReport),
    takeLatest(ACTION_TYPES.GET_OXY_TEST_SESSION_DATA, getOxyTestSessionData),
    takeLatest(ACTION_TYPES.RECREATE_SLEEP_ANALYSIS, recreateSleepAnalysis),
  ]);
}

export default UserSaga;
