//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//components
import CustomButton from "../CustomButton";

//constants
import { pageNumbers } from "src/shared/services";
import { STRINGS } from "src/shared/constants";

const CustomPagination = ({
  selectedPage,
  count,
  currentPageLength,
  handlePagination = () => {},
}) => {
  const PageNumbers = pageNumbers(selectedPage, count, 5);
  const handlePrevious = () => handlePagination(selectedPage - 1);
  const handlePage = (page) => {
    handlePagination(page);
  };
  const handleNext = () => handlePagination(selectedPage + 1);

  return (
    <div className={S.container}>
      <p className={S.text}>{`Showing ${
        (selectedPage - 1) * 10 + 1
      } to ${showToEntries(count, selectedPage)} of ${count} entries`}</p>
      <div>
        <CustomButton
          title={STRINGS.PREVIOUS}
          buttonClass={cx(
            S.button,
            S.previous,
            selectedPage === 1 && S.disable
          )}
          onClick={handlePrevious}
        />
        {PageNumbers.map((page) => (
          <CustomButton
            key={page}
            title={page}
            buttonClass={cx(S.button, page === selectedPage && S.active)}
            onClick={() => handlePage(page)}
          />
        ))}
        <CustomButton
          title={STRINGS.NEXT}
          buttonClass={cx(
            S.button,
            S.next,
            selectedPage === Math.ceil(count / 10) && S.disable
          )}
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default CustomPagination;

const showToEntries = (count, nextPage, offset = 10) => {
  if (nextPage * offset < count) {
    return nextPage * offset;
  } else return count;
};
