//libs
import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

//Views
import { MemberDetails, MemberReport } from "src/views";

const MemberRoutes = () => {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={MemberDetails} />
      <Route
        exact
        path={`${path}/report/:reportType/:date/:reportId`}
        component={MemberReport}
      />
    </Switch>
  );
};

export default MemberRoutes;
