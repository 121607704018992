//libs
import React from "react";

//styles
import S from "./SpiroLinkPdf.module.scss";
import SpiroLink from "src/assets/images/spirolink.jpeg";

const Page = ({
  hideLogo = false,
  hidePageNo = false,
  currentPage = 1,
  totalPages = 1,
  scale=1,
  children
}) => {
  return (
    <>
      <div className={S.page}>
        <div className={S.subPage} style={{transform:`scale(${scale})`}}>
          {children}
        </div>
        {!hideLogo && <img className={S.headingLogo} src={SpiroLink} alt="" />}
         <div className={S.footer}>
          {!hidePageNo && <div>{`${currentPage}/${totalPages}`}</div>}
        </div>
      </div>
    </>
  );
};

export default React.memo(Page);

