import { connect } from "react-redux";
import {
  addUser,
  deleteUser,
  getUser,
  getUsers,
  startLoading,
  stopLoading,
  updateUser,
  uploadImage,
} from "src/redux/actions";
import SubAdmins from "./SubAdmins";

const mapStateToProps = (state) => {
  return { userDetails: state.common.userDetails };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startLoading: () => dispatch(startLoading()),
    stopLoading: () => dispatch(stopLoading()),
    addSubAdmin: (payload) => dispatch(addUser(payload)),
    uploadImage: (payload) => dispatch(uploadImage(payload)),
    deleteSubAdmin: (payload) => dispatch(deleteUser(payload)),
    updateSubAdmin: (payload) => dispatch(updateUser(payload)),
    getAdmins: (payload) => dispatch(getUsers(payload)),
    getAdmin: (payload) => dispatch(getUser(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubAdmins);
