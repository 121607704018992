export const STRINGS = {
  ACT: "ACT (Asthma Control Test)",
  ACTIVATE: "Activate",
  ACTIVATE_MSG: "Are you sure you want to activate this user?",
  ACTIVE: "Active",
  ADD: "Add",
  ADD_DEVICE: "Add Device",
  ADD_NOTIFICATION: "Add Notification",
  ADD_PROVIDER: "Add Provider",
  ADD_SUB_ADMIN: "Add Sub-admin",
  ADMINS: "Admins",
  APP_TYPE: "appType",
  APP_TYPE_LABEL: "App Type",
  BACK: "Back",
  BLOCK: "Block",
  BLOCKED: "Blocked",
  BLOCK_MSG: "Are you sure you want to block this user?",
  BUILDING: "buildingNumber",
  BUILDING_LABEL: "Building",
  CANCEL: "Cancel",
  CHANGE_PASSWORD: "Change Password",
  CITY: "city",
  CITY_LABEL: "City",
  CLOSE: "Close",
  CODE: "code",
  CODE_LABEL: "Authentication Code",
  CONFIGURE: "Configure",
  CONFIRM_PASSWORD: "confirmPassword",
  CONFIRM_PASSWORD_LABEL: "Confirm Password",
  CONTACT_NUMBER_LABEL: "Contact Number",
  CONTINUE: "Continue",
  COUNTRY: "country",
  COUNTRY_LABEL: "Country",
  DASHBOARD: "Dashboard",
  DELETE: "Delete",
  DELETE_MSG: "Are you sure you want to delete user?",
  DELETE_NOTIFICATION: "Are you sure you want to delete Notification?",
  DELETE_PROVIDER_MSG: "Are you sure you want to delete provider?",
  DEVICE_DETAILS: "Device Details",
  DEVICE_ID: "deviceId",
  DEVICE_ID_LABEL: "Device Id",
  DEVICE_IMEI: "deviceIMEI",
  DEVICE_IMEI_LABEL: "Device IMEI",
  DEVICE_NAME: "deviceName",
  DEVICE_NAME_LABEL: "Device Name",
  DEVICE_QUANTITY: "deviceQty",
  DEVICE_QUANTITY_LABEL: "Quantity",
  DEVICE_TYPE: "deviceType",
  DEVICE_TYPE_LABEL: "Type",
  DEVICES: "Devices",
  DOWNLOAD_REPORT: "Download Report",
  EDIT: "Edit",
  EMAIL: "email",
  EMAIL_BODY: "body",
  EMAIL_LABEL: "Email",
  EMAIL_SENT: "Check your registered email for Authentication Code.",
  EMPTY_DATA: "--",
  FIRST_LOGIN:
    "Welcome to CMI Health Admin panel. You are using system generated password, , please click OK to change password.",
  FIRST_NAME: "firstName",
  FIRST_NAME_LABEL: "First Name",
  FORGOT_PASSWORD: "Forgot Password?",
  FORGOT_PASSWORD_MESSAGE:
    "Enter the email address associated with your account.",
  HOSPITAL_DETAILS: "Hospital Details",
  HOSPITAL_NAME_LABEL: "Hospital Name",
  ID: "Id",
  LAST_NAME: "lastName",
  LAST_NAME_LABEL: "Last Name",
  LOGIN: "Login",
  LOGIN_IN_ACCOUNT: "Log into your account",
  LOGOUT: "Logout",
  LOGUT_MSG: "Are you sure you want to Logout ?",
  MEMBER_SINCE: "Member since",
  MEMBERS: "Members",
  MESSAGE: "message",
  MIN_PASSWORD: "Minimum four characters required",
  NAME: "name",
  NAME_LABEL: "Name",
  NEW_PASSWORD: "newPassword",
  NEW_PASSWORD_LABEL: "New Password",
  NEXT: "Next",
  NO_DEVICES_FOUND: "No devices found!",
  NO_MEMBERS_FOUND: "No members found!",
  NO_PROVIDERS_FOUND: "No providers found!",
  NO_NOTIFICATIONS_FOUND: "No notifications history found!",
  NO_USERS_FOUND: "No users found!",
  NOTIFICATIONS: "Notifications",
  NOTIFICATION_TITLE: "Title",
  NOTIFICATION_MESSAGE: "Message",
  NOTIFICATION_TYPE: "Notification Type",
  OK: "OK",
  OXYGEN_LEVEL: "Oxygen Level",
  PASSWORD: "password",
  PASSWORD_LABEL: "Password",
  PASSWORD_REGEX_ERROR:
    "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character (@$!%*#?&^_-)",
  PHONE_NUMBER: "phoneNumber",
  PENDING: "Pending",
  POSTALCODE: "postalCode",
  PLANS: "Plans",
  POSTALCODE_LABEL: "Postal Code",
  PREVIOUS: "Previous",
  PRIMARY_USERS: "Primary Members",
  PROFILE: "Profile",
  PROVIDERS: "Providers",
  PROVIDER_DETAILS: "Provider Details",
  PROVIDER_ID_LABEL: "Id",
  PROVIDER_ID: "providerId",
  PULMONARY_FUNCTION: "Pulmonary Function",
  PULSE: "Pulse",
  PUSH_NOTIFICATIONS: "Push Notifications",
  RECEPIENT: "recepient",
  RECEPIENT_LABEL: "Recepient",
  REPORT: "Report",
  RESET_PASSWORD: "Reset Password",
  RESET_USER_PASSWORD: "Reset User Password",
  RESET_USER_PASSWORD_TITLE:
    "This will change the user's password. Are you sure you want to continue?",
  ROLES_AND_PERMISSIONS: "Roles and Permissions",
  SAVE: "Save",
  SEARCH_NOTIFICATION: "Search by text",
  SEARCH_TEXT: "Search by Name or Id",
  SECONDARY_USERS: "Secondary Members",
  SEND: "Send",
  SEND_EMAIL: "Send Email",
  SLEEP_QUALITY: "Sleep Quality",
  SPIROLINK: "Spiro Link",
  STREET: "streetName",
  STREET_LABEL: "Street",
  SUBJECT: "subject",
  SUBJECT_LABEL: "Subject",
  SUBMIT: "Submit",
  SUB_ADMIN: "Sub Admin",
  SUB_ADMINS: "Sub-admins",
  SUB_ADMIN_DETAILS: "Sub-admin details",
  SUPER_ADMIN: "Super Admin",
  TITLE: "title",
  TOWN: "town",
  TOWN_LABEL: "Town",
  UPDATE: "Update",
  UPDATE_PROVIDER: "Update Provider",
  UPDATE_SUB_ADMIN: "Update Sub-Admin",
  UPDATE_USER: "Update User Details",
  UPLOAD: "Upload File",
  USERNAME: "username",
  USERNAME_LABEL: "User Name",
  VIEW_NOTIFICATION: "View Notification",
  TESTS_PERFORMED: "Tests Performed",
  USERS_LOGIN: "Users Logins",
  USERS_SIGNUP: "Users Signups",
  NOT_ENOUGH_DATA: "Not enough data",
  RECREATE_SLEEP_ANALYSIS: "Recreate Sleep Analysis",
  CONFIG: "Config",
  WEATHER_API_KEY: "Weather API key",
  POLLUTION_API_KEY: "Pollution API key",
};

export const WARNINGS = {
  BLOCK_DENIED: "You don't have authoriztion to block users!",
  CONFIRM_PASSWORD: "Password does not match",
  DELETE_DENIED: "You don't have authoriztion to delete users!",
  ERROR: "Something went wrong!",
  INVALID_CODE: "Invalid postal code",
  INVALID_CONTACT: "Invalid Contact No.",
  INVALID_EMAIL: "Invalid email address",
  SELF_DELETE: "You can't delete your own user!",
  UPDATE_DENIED: "You don't have authoriztion to update users!",
  INVALID_PROVIDER_ID: "use Alphanumeric 8 letters",
  REQUIRED: "Required",
  INVALID_NAME: "Invalid name",
};

export const MAX_PASSWORD_LENGTH = 64;
export const MIN_PASSWORD_LENGTH = 4;

export const USER_ROLES_LABELS = {
  1: "super admin",
  2: "Primary user",
  3: "Secondary user",
  4: "Provider",
  5: "Sub Admin",
};

export const RES = {
  SUCCESS: 200,
};

export const FORM_TYPES = {
  add: 0,
  edit: 1,
  view: 2,
};

export const phoneRegex =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const numberRegex = /^[0-9]/;

export const nameRegex = /^[a-zA-Z\-'. ]+$/;

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&^_-])[A-Za-z\d@$!%*#?&^_-]{8,}$/;

export const alphaNumericRegex = /^(?=.*[a-zA-Z].*)([a-zA-Z0-9]{8})$/;

export const REPORT_TITLES = [
  "All",
  "SpiroLink",
  "SpO2",
  "Daily Log",
  "Asthma Control Test (ACT)",
];

export const TEST_TILES = [
  "Total Tests",
  "Asthma Control Test",
  "Daily Log",
  "FingerTip OxiMeter",
  "OxyKnight Watch",
  "OxyKnight Lite",
  "SpiroLink",
];
