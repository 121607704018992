//libs
import React from "react";
import { withSnackbar } from "notistack";

//styles
import S from "./ForgotPassword.module.scss";

//constants
import { RESET_PASSWORD, STRINGS } from "src/shared/constants";
import Logo from "src/assets/images/asthmaGo_logo.png";

//form
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { useHistory } from "react-router-dom";

const ForgotPassword = ({
  forgotPassword = () => {},
  enqueueSnackbar = () => {},
}) => {
  const history = useHistory();

  const handleSubmit = (values) => {
    forgotPassword({
      payload: { ...values },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        history.push(RESET_PASSWORD);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };
  return (
    <section className={S.section}>
      <div className={S.container}>
        <div className={S.containerLogo}>
          <img src={Logo} alt="" width="100%" />
        </div>
        <div className={S.containerRight}>
          <h1 className={S.containerRightHeading}>{STRINGS.FORGOT_PASSWORD}</h1>
          <p className={S.containerRightMessage}>
            {STRINGS.FORGOT_PASSWORD_MESSAGE}
          </p>
          <ForgotPasswordForm onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default withSnackbar(ForgotPassword);
