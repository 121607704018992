import React from "react";
import { Pie } from "react-chartjs-2";
import { ChartColors } from "src/shared/constants";
import S from "../style.module.scss";

const options = {
  responsive: true,
  maintainAspectRatio: false,
};

const PieChart = ({ title, height = "100%", width = "100%", dataSet = {} }) => {
  const data = {
    labels: [
      "Asthma Control Test",
      "Daily Log",
      "FingerTip OxiMeter",
      "OxyKnight Watch",
      "OxyKnight Lite",
      "SpiroLink",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          dataSet.act || 0,
          dataSet.dailyLog || 0,
          dataSet.fingerTipOxyMeter || 0,
          dataSet.oxyNight || 0,
          dataSet.oxyNightLite || 0,
          dataSet.spiroLink || 0,
        ],
        backgroundColor: [
          ChartColors.one,
          ChartColors.two,
          ChartColors.three,
          ChartColors.four,
          ChartColors.five,
          ChartColors.six,
        ],
        borderColor: [
          ChartColors.one,
          ChartColors.two,
          ChartColors.three,
          ChartColors.four,
          ChartColors.five,
          ChartColors.six,
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <>
      <div className={S.header}>
        <h1 className={S.headerTitle}>{title}</h1>
      </div>
      <Pie data={data} options={options} width={width} />
    </>
  );
};
export default React.memo(PieChart);
