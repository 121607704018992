//libs
import { connect } from "react-redux";
import { signOut } from "src/redux/actions";

//component
import Header from "./Header";

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
    authToken: state.common.authToken,
    userDetails: state.common.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: (payload) => dispatch(signOut(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
