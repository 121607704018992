//libs
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";

//decorators
import cx from "classnames";

//components
import { CustomDatePicker } from "src/components";

//utils
import { formatDate } from "src/shared/services";

//constants
import { REPORT_TITLES } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//widgets
import { List } from "./widgets";
import moment from "moment";

const MemberDetails = ({
  getUserReport = () => { },
  enqueueSnackbar = () => { },
  setReportData = () => { },
  recreateSleepAnalysisData = () => { },
  reportData = {},
  loading = false,
}) => {
  const history = useHistory();
  const [active, setActive] = useState(reportData?.active ?? 0);
  const [date, setDate] = useState(
    reportData?.date ? new Date(reportData?.date) : new Date()
  );
  const [userReport, setUserReport] = useState({});
  const [dateType, setDateType] = useState(reportData?.dateType ?? "day");

  const ID = history?.location?.search?.slice(1);
  let { url } = useRouteMatch();

  useEffect(() => {
    if (ID) {
      fetchReport(ID);
    }
    // eslint-disable-next-line
  }, [ID, date, active, dateType]);

  const recreateSleepAnalysis = (oxySessionId) => {
    console.log("recreateSleepAnalysis")
    recreateSleepAnalysisData({
      payload: {
        oxySessionId,
      },
      success: (data) => {
        enqueueSnackbar("Sleep analysis will be updated soon", { variant: "success" });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  }

  const fetchReport = () => {
    let startTime = "";
    let endTime = "";
    switch (dateType) {
      case "2weeks":
        startTime = moment(date)
          .startOf("week")
          .subtract(7, "days")
          .toISOString();
        endTime = moment(date).endOf("week").subtract(7, "days").toISOString();
        break;
      case "all":
        startTime = moment("01/01/2020").toISOString();
        endTime = moment().endOf("day").toISOString();
        break;
      default:
        startTime = moment(date).startOf(dateType).toISOString();
        endTime = moment(date).endOf(dateType).toISOString();
        break;
    }

    getUserReport({
      payload: {
        userId: ID,
        date: formatDate(date),
        startTime: startTime,
        endTime: endTime,
        filterType:
          active === 1
            ? 1
            : active === 2
              ? 2
              : active === 3
                ? 3
                : active === 4
                  ? 5
                  : "",
        appType: 1,
      },
      success: (data) => {
        setUserReport(data.data || []);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleListClick = (data) => {
    const reportType = active || 0;
    const formattedDate = new Date(date).getTime();
    history.push({
      pathname: `${url}/report/${reportType}/${formattedDate}/${data?.id}`,
      search: ID,
      state: {
        data,
      },
    });
  };

  useEffect(() => {
    setReportData({ active, date, dateType });
  }, [date, setReportData, active, dateType]);

  return (
    <div className={S.wrapper}>
      <div className={S.container}>
        <div className={S.filterBox}>
          <div className={S.dateWrapper}>
            <select
              className={S.dateInput}
              onChange={(e) => setDateType(e.target.value)}
              value={dateType}
            >
              <option className={S.option} value="day" label="Day" />
              <option className={S.option} value="week" label="Week" />
              <option className={S.option} value="2weeks" label="2 Weeks" />
              <option className={S.option} value="month" label="Month" />
              <option className={S.option} value="all" label="All" />
            </select>
          </div>
          <div className={S.dateWrapper}>
            <CustomDatePicker
              date={date}
              setDate={setDate}
              inputClass={S.dateInput}
            />
          </div>
        </div>
        <div className={S.tabs}>
          {REPORT_TITLES.map((tab, key) => (
            <p
              key={key}
              onClick={() => {
                setActive(key);
              }}
              className={cx(S.tabsTitle, { [S.tabsActive]: active === key })}
            >
              {tab}
            </p>
          ))}
        </div>
      </div>
      <List
        data={userReport?.records}
        dailyLog={userReport?.dialyLogData ?? []}
        userPef={userReport?.userPefHistory}
        onClick={handleListClick}
        loading={loading}
        onClickRecreate={recreateSleepAnalysis}
      />
    </div>
  );
};

export default React.memo(MemberDetails);
