export const BASE_URL = "http://67.227.158.137:3000/";

const PREFIX_URL = BASE_URL + "v1";

const DOCKER_BASE_URL = "http://172.17.0.1:3000";
export const API = {
  LOGIN: `${PREFIX_URL}/user/login`,
  LOGOUT: `${PREFIX_URL}/user/logout`,
  RESET_PASSWORD: `${PREFIX_URL}/user/resetPassword`,
  FORGOT_PASSWRD: `${PREFIX_URL}/user/forgot-password`,
  UPLOAD_FILE: `${PREFIX_URL}/fileUpload`,
  USERS: (params = "") => `${PREFIX_URL}/user${params ? "?" + params : ""}`,
  DELETE_USER: (params = "") =>
    `${PREFIX_URL}/user/permanent${params ? "?" + params : ""}`,
  ADD_USER: `${PREFIX_URL}/user/add`,
  CHANGE_PASSWORD_ADMIN: `${PREFIX_URL}/user/changePassword`,
  PERMISSION: `${PREFIX_URL}/permission`,
  HOSPITAL_PROVIDER: `${PREFIX_URL}/hosptialProvider`,
  DEVICES: (params = "") => `${PREFIX_URL}/device?${params}`,
  GET_DASHBOARD_DATA: `${PREFIX_URL}/dashboard`,
  SEND_NOTIFICATION: `${PREFIX_URL}/sendNotification`,
  USER_REPORT: (params = "") => `${PREFIX_URL}/user/deviceData_v2?${params}`,
  OXY_TEST_DATA: (params = "") =>
    `${PREFIX_URL}/user/oxyTestSessionData?${params}`,
  UPLOAD_BULK_FILE: `${PREFIX_URL}/device/upload`,
  NOTIFICATION: (params = "") => `${PREFIX_URL}/notification?${params}`,
  NOTIFICATION_V2: (params = "") => `${PREFIX_URL}/notification_v2?${params}`,
  GET_RECORDS_FOR_PDF: (params = "") =>
    `${DOCKER_BASE_URL}/v1/admin/oxyTestSessionData?${params}`,
  RESET_USER_PASSWORD: `${PREFIX_URL}/admin/changeUserPassword`,
  GET_TEST_SESSIONS_DATA: (params = "") =>
    `${PREFIX_URL}/admin/countOfTest?${params}`,
  GET_USER_SESSIONS_DATA: (params = "") =>
    `${PREFIX_URL}/admin/events?${params}`,
  RECREATE_SLEEP_ANALYSIS: `${PREFIX_URL}/user/sleepAnalysis`,
  CONFIG: `${PREFIX_URL}/settings`,
  GET_SPIRO_RECORDS_FOR_PDF: (params = "") =>
    `${DOCKER_BASE_URL}/v1/admin/spiroData?${params}`,
};

export const RESPONSE = {
  success: 200,
  unAuthorized: 401,
};
