import { connect } from "react-redux";
import { getUser, getUserReport } from "src/redux/actions";
import MemberReport from "./MemberReport";

const mapStateToProps = (state) => {
  return {
    userDetails: state.common.userDetails,
    reportData: state.common.reportData,
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: (payload) => dispatch(getUser(payload)),
    getUserReport: (payload) => dispatch(getUserReport(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberReport);
