import { ACTION_TYPES } from "./actionTypes";

export const getDashboardData = (payload) => {
  return {
    type: ACTION_TYPES.GET_DASHBOARD_DATA,
    payload,
  };
};

export const getTestSessionsData = (payload) => {
  return {
    type: ACTION_TYPES.GET_TEST_SESSIONS_DATA,
    payload,
  };
};

export const getUserSessionsData = (payload) => {
  return {
    type: ACTION_TYPES.GET_USER_SESSIONS,
    payload,
  };
};
