import React from "react";

//styles
import S from "./style.module.scss";
import { Line } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

const SpiroLinkGraph = ({
  data = {},
  height,
  dataLabelsEnabled = false,
  tickAmount = 0,
  titles = ["", ""],
}) => {
  if (!Object.keys(data).length) return null;

  const GraphData = {
    pef: data?.map((p) => ({ x: p.date, y: p.pef })),
    fev1: data?.map((p) => ({ x: p.date, y: p.fev1 })),
  };

  const LineChart = ({
    data,
    title,
    precision,
    height,
    truncate = false,
    labelY = "",
  }) => {
    const options = {
      animation: {
        duration: 0,
      },
      responsive: true,
      devicePixelRatio: 2,
      maintainAspectRatio: false,
      showLines: true,
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: dataLabelsEnabled,
          color: "#fff",
          formatter: function (value) {
            return truncate ? Math.trunc(value.y) : value.y;
          },
          backgroundColor: "#40a0fc",
          font: {
            weight: 600,
            size: 12,
          },
          borderRadius: 2,
          padding: {
            top: 2,
            bottom: 2,
            left: 4,
            right: 4,
          },
        },
      },
      elements: {
        point: {
          radius: 5,
          backgroundColor: "#40a0fc",
          borderColor: "#40a0fc",
        },
        line: {
          backgroundColor: "#40a0fc",
          borderColor: "#40a0fc",
        },
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxis: {
          grid: {
            drawOnChartArea: false,
            drawTicks: true,
            drawBorder: false,
          },
          ticks: {
            color: "#000",
          },
          title: {
            text: "Day",
            display: true,
            align: "center",
            font: {
              size: 16,
            },
          },
        },
        yAxis: {
          grid: {
            drawBorder: false,
          },
          ticks: {
            maxTicksLimit: tickAmount,
            count: tickAmount,
            color: "#000",
            padding: 8,
            precision: precision,
          },
          beginAtZero: true,
          title: {
            text: labelY,
            display: true,
            align: "center",
            font: {
              size: 16,
            },
          },
        },
      },
    };

    return (
      <>
        <div className={S.legend}>
          <h1 className={S.legendTitle}>{title}</h1>
        </div>
        <div className={S.graphContainer}>
          <Line
            data={{ datasets: [{ data: data, spanGaps: true }] }}
            plugins={[ChartDataLabels]}
            options={options}
            height={height}
          />
        </div>
      </>
    );
  };

  return (
    <div className={S.container}>
      <div className={S.graph}>
        <LineChart
          data={GraphData.pef}
          title={titles[0] || "PEF"}
          precision={0}
          height={height || 220}
          truncate
          labelY={"L/min"}
        />
        <LineChart
          data={GraphData.fev1}
          title={titles[1] || "Fev1"}
          height={height || 220}
          labelY={"L"}
        />
      </div>
    </div>
  );
};

export default React.memo(SpiroLinkGraph);
