import MAN_IMAGE from "src/assets/images/man.png";
import WOMAN_IMAGE from "src/assets/images/woman.png";
import GIRL_IMAGE from "src/assets/images/girl.png";
import BOY_IMAGE from "src/assets/images/boy.png";

export const USER_ROLES = {
  SUPER_ADMIN: 1,
  PRIMARY_USER: 2,
  SECONDARY_USER: 3,
  PROVIDER: 4,
  SUB_ADMIN: 5,
};

export const USER_TYPES = [
  "SUPER ADMIN",
  "PRIMARY USER",
  "SECONDARY USER",
  "PROVIDER",
  "SUB ADMIN",
];

export const USER_PERMISSIONS = {
  ADD: 1,
  UPDATE: 2,
  DELETE: 3,
  VIEW: 4,
  BLOCK: 5,
  SensitiveInformation: 6,
};

export const PERMISSIONS_TYPES = [
  {
    label: "Add Admin",
    value: 1,
  },
  {
    label: "Update Admin",
    value: 2,
  },
  {
    label: "Delete Admin",
    value: 3,
  },
  {
    label: "View Admin Details",
    value: 4,
  },
  {
    label: "Block User",
    value: 5,
  },
  {
    label: "Read sensitive information",
    value: 6,
  },
];

export const USER_IMAGES = [
  {
    _id: "c855df4b-a1b8-49e2-b221-1ed95c9646ab",
    _image: MAN_IMAGE,
  },
  {
    _id: "68c0d526-8261-4b78-bbef-3603a8514968",
    _image: WOMAN_IMAGE,
  },
  {
    _id: "935fe579-93d3-416f-ac64-291d3fa538ea",
    _image: BOY_IMAGE,
  },
  {
    _id: "5b3c70ff-a9a2-4688-857e-7279e0968d89",
    _image: GIRL_IMAGE,
  },
];

export const DEVICE_TYPES = [
  {
    label: "SPIRO LINK",
    value: 1,
  },
  {
    label: "FINGER TIP OXYMETER",
    value: 2,
  },
  {
    label: "OXY KNIGHT",
    value: 3,
  },
  {
    label: "OXY KNIGHT LITE",
    value: 4,
  },
  {
    label: "ACT",
    value: 5,
  },
  {
    label: "DAILY LOG",
    value: 6,
  },
];

export const NOTIFICATION_TYPES = [
  {
    label: "Email",
    value: 1,
  },
  {
    label: "Push Notifications",
    value: 2,
  },
];

export const NOTIFICATION_NAMES = { 1: "Email", 2: "Push Notification" };

export const APP_TYPES = [
  {
    label: "AsthmaGo",
    value: 1,
  },
  {
    label: "SpiroLink",
    value: 3,
  },
  {
    label: "OxyKnight",
    value: 2,
  },
];

export const PLATFORM = {
  APP: "APP",
  WEB: "WEB",
};

export const RACES = [
  "Caucasian",
  "African American",
  "Hispanic",
  "Asian",
  "Other",
];

export const GENDERS = ["Male", "Female", "Other"];

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const COUNTRIES = [
  "Afghanistan",
  "land Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "AndorrA",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, The Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard Island and Mcdonald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic Of",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People'S Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao People'S Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory, Occupied",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "RWANDA",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Pierre and Miquelon",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Viet Nam",
  "Virgin Islands, British",
  "Virgin Islands, U.S.",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];
