//libs
import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

//styles
import S from "./header.module.scss";

//assets
import LogoImage from "src/assets/images/asthmaGo_logo.png";
import LogoWords from "src/assets/images/logoWords.png";
import { Icomoon } from "src/assets/icons";

//constants
import { CHANGE_PASSWORD, STRINGS } from "src/shared/constants";
import { useHistory } from "react-router-dom";
import PopupBox from "../PopupBox";
import ConfirmDialog from "../ConfirmDialog";

const Header = ({
  signOut = () => { },
  authToken = "",
  enqueueSnackbar = () => { },
  userDetails,
}) => {
  const history = useHistory();

  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    setIsFirstLogin(!userDetails.userCreatedOwnPassword);
    // eslint-disable-next-line
  }, [userDetails]);

  const handleClose = () => {
    setIsFirstLogin(false);
    history.push(CHANGE_PASSWORD);
  };

  const handleLogout = () => {
    let payload = {
      payload: authToken,
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    };
    signOut(payload);
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      handleLogout();
    }
    setShowDialog(false);
  };

  return (
    <section className={S.section}>
      <div className={S.leftBlock}>
        <img className={S.leftBlockLogo} src={LogoImage} alt="" />
      </div>
      <div className={S.rightBlock}>
        {/* <SearchBox /> */}
        <div className={S.logout} onClick={() => setShowDialog(true)}>
          <Icomoon className={S.logoutIcon} icon={"exit"} />
          <span className={S.logoutText}>{STRINGS.LOGOUT}</span>
        </div>
      </div>
      {isFirstLogin && (
        <PopupBox title={STRINGS.FIRST_LOGIN} onClose={handleClose} />
      )}

      {showDialog && (
        <ConfirmDialog
          title={STRINGS.LOGUT_MSG}
          onClose={handleConfirmDialog}
          yesTitle={STRINGS.LOGOUT}
        />
      )}
    </section>
  );
};

export default withSnackbar(Header);
