//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import { postRequest, updateAuthToken } from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  startLoading,
  stopLoading,
  updateAuthTokenRedux,
} from "../actions";

function* addProvider({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.HOSPITAL_PROVIDER,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* ProviderSaga() {
  yield all([takeLatest(ACTION_TYPES.ADD_PROVIDER, addProvider)]);
}

export default ProviderSaga;
