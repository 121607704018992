//libs
import React, { useState } from "react";

//styles
import S from "./login.module.scss";

//constants
import { STRINGS } from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import { CustomButton, CustomInput } from "src/components";

export const LoginForm = ({ onSubmit = () => {} }) => {
  const [showPassword, setShowPassword] = useState(false);

  const INPUT_FILEDS = [
    { name: STRINGS.EMAIL, label: STRINGS.EMAIL_LABEL },
    {
      name: STRINGS.PASSWORD,
      label: STRINGS.PASSWORD_LABEL,
      type: showPassword ? "text" : "password",
      passwordIcon: showPassword ? "eye" : "eye-blocked",
    },
  ];

  const inputRefs = Array(INPUT_FILEDS.length).fill(React.createRef());

  const handleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Formik
      validate={validate}
      initialValues={{
        email: "",
        password: "",
      }}
      onSubmit={onSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {INPUT_FILEDS.map((field, idx) => (
            <CustomInput
              key={idx}
              label={field.label}
              inputRef={(r) => (inputRefs[idx] = r)}
              name={field.name}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              containerClass={S.loginInputContainer}
              labelClass={S.loginLabel}
              inputClass={S.loginInput}
              value={values[field.name]}
              type={field.type}
              handleBlur={handleBlur}
              showPasswordIcon={field.passwordIcon}
              toggleShowPassword={handleShowPassword}
            />
          ))}
          <CustomButton
            type="submit"
            title={STRINGS.LOGIN}
            onClick={handleSubmit}
          />
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Required";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};
