//libs
import React from "react";
import { ApexChart } from "apex-react-charts";
import moment from "moment-timezone";

//constants
import { PLATFORM } from "src/shared/constants";

const formatData = (data) => {
  const reduced = data.allRecords.reduce(function (m, d) {
    const second = moment(d.createdAt).format("HHmmss");
    if (!m[second]) {
      m[second] = { ...d, count: 1, timestamp: moment(d.createdAt).valueOf() };
      return m;
    }
    return m;
  }, {});

  const result = Object.keys(reduced).map(function (k) {
    const item = reduced[k];
    return {
      timestamp: item.timestamp,
      pr: item.pr,
      spO2: item.spO2,
    };
  });

  result.sort((d, e) => d.timestamp - e.timestamp);

  return result;
};

const AreaChart = ({
  yLabels = [],
  data = [],
  height = 150,
  containerClassname = "",
  headerClassname = "",
  graphClassname = "",
  timezone = "America/Phoenix",
  maxX = 11,
  platform = PLATFORM.WEB,
  chartHeadings = [],
}) => {
  const options = {
    chart: {
      id: "area-datetime",
      type: "area",
      height: height,
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      area: {
        fillTo: "end",
      },
    },
    stroke: {
      curve: "straight",
      width: 2,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      tickPlacement: "on",
      tickAmount: maxX,
      min: new Date(data.createdAt).getTime(),
      max: new Date(data.updatedAt).getTime(),
      labels: {
        formatter: function (value, timestamp, opts) {
          return moment(timestamp).tz(timezone).format("hh:mm:ss");
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.35,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    grid: {
      show: platform === PLATFORM.WEB,
    },
  };

  let spO2Data = [
    {
      data: formatData(data).map((d) => [d.timestamp, Number(d.spO2)]),
    },
  ];

  let prData = [
    {
      data: formatData(data).map((d) => [d.timestamp, Number(d.pr)]),
    },
  ];

  const chartLabelStyle = {
    color: "#000",
    fontSize: "16px",
    fontWeight: 400,
  };

  const yLabel = (label) => {
    return {
      text: label,
      offsetY: 16,
      style: chartLabelStyle,
    };
  };

  return (
    <>
      <div className={containerClassname}>
        {chartHeadings[0] && (
          <p className={headerClassname}>{chartHeadings[0]}</p>
        )}
        <ApexChart
          options={{
            ...options,
            yaxis: {
              min: 85,
              max: 100,
              tickAmount: 4,
              title: yLabel(yLabels[0]),
            },
            fill: {
              ...options.fill,
            },
          }}
          series={spO2Data}
          type="line"
          height={height}
          width={600}
        />
      </div>
      <div className={containerClassname}>
        {chartHeadings[1] && (
          <p className={headerClassname}>{chartHeadings[1]}</p>
        )}
        <ApexChart
          options={{
            ...options,
            colors: ["#ef0000"],
            yaxis: {
              tickAmount: 4,
              title: yLabel(yLabels[1]),
            },
            fill: {
              ...options.fill,
              gradient: {
                shadeIntensity: 1,
              },
            },
          }}
          series={prData}
          type="line"
          height={height}
          width={"100%"}
        />
      </div>
    </>
  );
};

export default React.memo(AreaChart);
