import React from "react";
import cx from "classnames";

//styles
import S from "./style.module.scss";
import { AreaLineChart } from "src/components";
import { STRINGS } from "src/shared/constants";

const SpiroLinkWidget = ({ data = {} }) => {
  const SpiroTest = {
    records: [
      {
        time: data?.updatedAt ? new Date(data?.updatedAt).toLocaleString() : "",
        pef: data?.pef,
        fev1: data?.fev1,
        fvc: data?.fvc,
        mef25: data?.mef25,
        mef50: data?.mef50,
        mef75: data?.mef75,
      },
    ],
  };
  if (!Object.keys(data).length) return null;

  const GraphData = SpiroTest.records.map((r, i) => {
    return [
      { x: 0, y: 0 },
      { x: (r?.fvc * 0.182).toFixed(2), y: Number(r.pef).toFixed(2) },
      { x: (r?.fvc * 0.25.toFixed(2)), y: (r?.mef75 * 60).toFixed(2) },
      { x: (r?.fvc * 0.5).toFixed(2), y: (r?.mef50 * 60).toFixed(2) },
      { x: (r?.fvc * 0.75).toFixed(2), y: (r?.mef25 * 60).toFixed(2) },
      { x: Number(r?.fvc).toFixed(2), y: 0 },
    ];
  });

  return (
    <div className={S.container}>
      <div className={S.header}>
        <p className={S.headerTitle}>{STRINGS.PULMONARY_FUNCTION}</p>
        <p className={S.headerText}>{STRINGS.SPIROLINK}</p>
      </div>

      {/* 18d62b5f-2df7-4ac8-bab5-3e36842ac1b5 */}
      <div className={S.graph}>
        <div className={S.graphContainer}>
          {/* <p className={S.graphTitle}>{d.title || d}</p> */}
          <AreaLineChart
            title={"PEF (L/min)"}
            height={220}
            data={GraphData[0]}
          />
        </div>
      </div>

      <div className={S.records}>
        <p className={S.recordsHeading}>Record Details</p>
        <table className={S.recordsTable}>
          <thead>
            <tr className={cx(S.recordsTableHeading)}>
              <th className={cx(S.recordsTableData)}>#</th>
              <th className={cx(S.recordsTableData)}>Time</th>
              <th className={cx(S.recordsTableData)}>PEF</th>
              <th className={cx(S.recordsTableData)}>Fev1 (L)</th>
              <th className={cx(S.recordsTableData)}>FVC(L)</th>
              <th className={cx(S.recordsTableData)}>Mef25</th>
              <th className={cx(S.recordsTableData)}>Mef50</th>
              <th className={cx(S.recordsTableData)}>Mef75</th>
            </tr>
          </thead>
          <tbody>
            {SpiroTest.records.map((r, i) => (
              <tr className={cx(S.recordsTableRow)} key={i}>
                <td className={cx(S.recordsTableData)}>{`#${i + 1}`}</td>
                <td className={cx(S.recordsTableData)}>{r.time}</td>
                <td className={cx(S.recordsTableData)}>{r.pef}</td>
                <td className={cx(S.recordsTableData)}>{r.fev1}</td>
                <td className={cx(S.recordsTableData)}>{r.fvc}</td>
                <td className={cx(S.recordsTableData)}>{r.mef25}</td>
                <td className={cx(S.recordsTableData)}>{r.mef50}</td>
                <td className={cx(S.recordsTableData)}>{r.mef75}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default React.memo(SpiroLinkWidget);
