import { connect } from "react-redux";
import { getUser, getUsers } from "src/redux/actions";
import Members from "./Members";

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (payload) => dispatch(getUsers(payload)),
    getUser: (payload) => dispatch(getUser(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Members);
