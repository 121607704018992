import { ACTION_TYPES } from "./actionTypes";

export const signIn = (payload) => {
  return {
    type: ACTION_TYPES.SIGN_IN,
    payload,
  };
};

export const forgotPassword = (payload) => {
  return {
    type: ACTION_TYPES.FORGOT_PASSWORD,
    payload,
  };
};

export const signOut = (payload) => {
  return {
    type: ACTION_TYPES.SIGN_OUT,
    payload,
  };
};

export const resetPassword = (payload) => {
  return {
    type: ACTION_TYPES.RESET_PASSWORD,
    payload,
  };
};

export const resetUserPassword = (payload) => {
  return {
    type: ACTION_TYPES.RESET_USER_PASSWORD,
    payload,
  };
};
