//libs
import React, { useState } from "react";

//constants
import { Icomoon } from "src/assets/icons";
import { CustomButton } from "src/components";
import { PERMISSIONS_TYPES, STRINGS } from "src/shared/constants";

//styles
import S from "./Permissions.module.scss";

export const Permissions = ({ data, handleAddPermission = () => {}, role }) => {
  const [permissions, setPermissions] = useState([...data]);

  const handlePermissions = () => {
    handleAddPermission(permissions, role);
  };

  return (
    <div className={S.dropContainer}>
      <ul className={S.list}>
        {PERMISSIONS_TYPES.map((d) => {
          return (
            <li key={d.value} className={S.listItem}>
              {permissions.includes(d.value) ? (
                <Icomoon
                  className={S.listItemChecked}
                  icon={"checkbox-checked"}
                  onClick={() => {
                    permissions.splice(permissions.indexOf(d.value), 1);
                    setPermissions([...permissions]);
                  }}
                />
              ) : (
                <div
                  className={S.listItemUnchecked}
                  onClick={() => {
                    permissions.push(d.value);
                    setPermissions([...permissions]);
                  }}
                />
              )}
              <span className={S.listItemLabel}>{d.label}</span>
            </li>
          );
        })}
      </ul>
      <div className={S.save}>
        <CustomButton
          title={STRINGS.SAVE}
          buttonClass={S.saveButton}
          onClick={handlePermissions}
        />
      </div>
    </div>
  );
};
