import { ACTION_TYPES } from "./actionTypes";

export const startLoading = () => {
  return {
    type: ACTION_TYPES.START_LOADING,
  };
};

export const stopLoading = () => {
  return {
    type: ACTION_TYPES.STOP_LOADING,
  };
};

export const updateAuthTokenRedux = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_AUTH_TOKEN,
    payload,
  };
};

export const uploadImage = (payload) => {
  return {
    type: ACTION_TYPES.UPLOAD_IMAGE,
    payload,
  };
};

export const updateUserDetailsRedux = (payload) => {
  return {
    type: ACTION_TYPES.UPDATE_USER_DETAILS,
    payload,
  };
};

export const setReportData = (payload) => {
  return {
    type: ACTION_TYPES.SET_REPORT_DATA,
    payload,
  };
};
