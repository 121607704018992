import { all, fork } from "redux-saga/effects";
import UserSaga from "./users";
import PermissionSaga from "./permissions";
import AuthSaga from "./auth";
import ProviderSaga from "./providers";
import DeviceSaga from "./devices";
import DashboardSaga from "./dashboard";
import NotificationAndEmailSaga from "./notificationAndEmail";
import PdfReportSaga from "./pdfReport";
import ConfigSaga from "./config";

function* RootSaga() {
  yield all([
    fork(AuthSaga),
    fork(UserSaga),
    fork(PermissionSaga),
    fork(ProviderSaga),
    fork(DeviceSaga),
    fork(DashboardSaga),
    fork(NotificationAndEmailSaga),
    fork(PdfReportSaga),
    fork(ConfigSaga),
  ]);
}

export default RootSaga;
