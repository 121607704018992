//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//constants
import {
  DEVICE_TYPES,
  FORM_TYPES,
  STRINGS,
  WARNINGS,
} from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import { CustomButton, CustomInput, CustomSelect } from "src/components";

export const AddDeviceForm = ({
  onSubmit = () => {},
  handleCancel = () => {},
  formType = "",
  formData = {},
}) => {
  const FILEDS = [
    { name: STRINGS.DEVICE_NAME, label: STRINGS.DEVICE_NAME_LABEL },
    { name: STRINGS.DEVICE_IMEI, label: STRINGS.DEVICE_IMEI_LABEL },
    // {
    //   name: STRINGS.DEVICE_QUANTITY,
    //   label: STRINGS.DEVICE_QUANTITY_LABEL,
    //   type: "number",
    // },
  ];

  const fieldRefs = Array(FILEDS.length).fill(React.createRef());

  const isAdd = formType === FORM_TYPES.add;
  const isView = formType === FORM_TYPES.view;

  const hanldeFormSubmit = (values, { resetForm }) => {
    onSubmit(values, () => {
      resetForm();
    });
  };

  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={{
        id: formData?.id || "",
        deviceName: formData.name || "",
        deviceType: formData?.type || "",
        deviceIMEI: formData?.deviceId || "",
        deviceQty: formData?.quantity || 1,
      }}
      onSubmit={hanldeFormSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        resetForm,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className={S.form}>
          <div className={S.formBody}>
            <p className={S.subHeading}>{STRINGS.DEVICE_DETAILS}</p>
            <div className={S.formBodyFields}>
              <CustomSelect
                label={STRINGS.DEVICE_TYPE_LABEL}
                name={STRINGS.DEVICE_TYPE}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                containerClass={S.formBodyFieldsContainer}
                labelClass={S.formBodyFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.DEVICE_TYPE]}
                handleBlur={handleBlur}
                options={DEVICE_TYPES}
              />
              {FILEDS.map((field, idx) => (
                <CustomInput
                  key={idx}
                  label={field.label}
                  inputRef={(r) => (fieldRefs[idx] = r)}
                  name={field.name}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyFieldsContainer}
                  labelClass={S.formBodyFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values[field.name]}
                  type={field.type}
                  readOnly={!!isView}
                  handleBlur={handleBlur}
                />
              ))}
            </div>
          </div>
          <div className={S.formFooter}>
            <CustomButton
              title={!!!isView ? STRINGS.CANCEL : STRINGS.CLOSE}
              onClick={() => {
                handleCancel(() => {
                  resetForm();
                });
              }}
              buttonClass={cx(S.formFooterButton, S.formFooterCancel)}
            />
            {!!!isView && (
              <CustomButton
                type="submit"
                title={isAdd ? STRINGS.SUBMIT : STRINGS.UPDATE}
                onClick={handleSubmit}
                buttonClass={S.formFooterButton}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.deviceName) {
    errors.deviceName = WARNINGS.REQUIRED;
  }
  if (!values.deviceType) {
    errors.deviceType = WARNINGS.REQUIRED;
  }
  if (!values.deviceIMEI) {
    errors.deviceIMEI = WARNINGS.REQUIRED;
  }
  if (!values.deviceQty) {
    errors.deviceQty = WARNINGS.REQUIRED;
  }
  return errors;
};
