//libs
import React from "react";
import { BreadcrumbMenu } from "src/components";
import {
  CONFIG,
  DEVICES,
  ROLES_AND_PERMISSIONS,
  STRINGS,
} from "src/shared/constants";

//styles
import S from "./style.module.scss";
import { Icomoon } from "src/assets/icons";
import { useHistory } from "react-router-dom";

const Configure = () => {
  const history = useHistory();

  const renderWidgets = () =>
    CONFIGURE_WIDGETS.map((ele, i) => (
      <div key={i} className={S.widget} onClick={() => history.push(ele.path)}>
        <p className={S.widgetText}>{ele.title}</p>
        <Icomoon className={S.widgetIcon} icon={ele.icon} />
      </div>
    ));

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.CONFIGURE} />
      <div className={S.container}>{renderWidgets()}</div>
    </section>
  );
};

export default Configure;

const CONFIGURE_WIDGETS = [
  {
    title: STRINGS.ROLES_AND_PERMISSIONS,
    path: ROLES_AND_PERMISSIONS,
    icon: "key",
  },
  { title: STRINGS.DEVICES, path: DEVICES, icon: "cpu" },
  { title: STRINGS.CONFIG, path: CONFIG, icon: "cloud" },
];
