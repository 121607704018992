//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//constants
import { APP_TYPES, FORM_TYPES, STRINGS, WARNINGS } from "src/shared/constants";

//components
import { Field, Form, Formik } from "formik";
import { CustomButton, CustomTextArea } from "src/components";
import { Checkbox } from "../Checkbox";

export const SendNotificationForm = ({
  onSubmit = () => {},
  handleCancel = () => {},
  readOnly = "",
  formData = {},
  notificationType = "",
}) => {
  const FILEDS = [
    { name: STRINGS.MESSAGE, label: STRINGS.NOTIFICATION_MESSAGE },
  ];

  const fieldRefs = Array(FILEDS.length).fill(React.createRef());
  const isView = readOnly === FORM_TYPES.view;

  const hanldeFormSubmit = (values, { resetForm }) => {
    onSubmit(values, () => {
      resetForm();
    });
  };

  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={{
        message: formData.message || "",
        appType: formData.appType || [],
        notificationType: notificationType || "",
      }}
      onSubmit={hanldeFormSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        resetForm,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className={S.form}>
          <div className={S.formBody}>
            <div>
              <label className={S.formBodyLabel}>
                {STRINGS.APP_TYPE_LABEL}
              </label>
              <span className={S.error}>
                {errors[STRINGS.APP_TYPE] && touched[STRINGS.APP_TYPE]
                  ? errors[STRINGS.APP_TYPE]
                  : ""}
              </span>
              <div className={S.list}>
                {APP_TYPES.map((app, key) => (
                  <Field
                    name={STRINGS.APP_TYPE}
                    type="checkbox"
                    component={Checkbox}
                    value={app.label}
                    label={app.label}
                    values={values}
                    readOnly={!!isView}
                    key={key}
                  />
                ))}
                <Field
                  name={STRINGS.APP_TYPE}
                  type="checkbox"
                  component={Checkbox}
                  value={["All"]}
                  label={"All"}
                  values={values}
                  readOnly={!!isView}
                  onChange={() => {
                    if (values.appType.length === APP_TYPES.length) {
                      setFieldValue("appType", []);
                      return;
                    }
                    setFieldValue("appType", [
                      APP_TYPES[0].label,
                      APP_TYPES[1].label,
                      APP_TYPES[2].label,
                    ]);
                  }}
                  checked={values.appType.length === APP_TYPES.length}
                />
              </div>
            </div>

            <div className={S.formBodyTextArea}>
              {FILEDS.map((field, idx) => (
                <CustomTextArea
                  key={idx}
                  label={field.label}
                  inputRef={(r) => (fieldRefs[idx] = r)}
                  name={field.name}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyTextAreaContainer}
                  labelClass={S.formBodyLabel}
                  inputClass={S.formBodyTextAreaInput}
                  value={values[field.name]}
                  type={field.type}
                  readOnly={!!isView}
                  handleBlur={handleBlur}
                  rows={2}
                />
              ))}
            </div>
          </div>
          <div className={S.formFooter}>
            <CustomButton
              title={!!!isView ? STRINGS.CANCEL : STRINGS.CLOSE}
              onClick={() => {
                handleCancel(() => {
                  resetForm();
                });
              }}
              buttonClass={cx(S.formFooterButton, S.formFooterCancel)}
            />
            {!!!isView && (
              <CustomButton
                type="submit"
                title={STRINGS.SEND}
                onClick={handleSubmit}
                buttonClass={S.formFooterButton}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.message) {
    errors.message = WARNINGS.REQUIRED;
  }
  if (!values.appType || !values.appType.length) {
    errors.appType = WARNINGS.REQUIRED;
  }
  return errors;
};
