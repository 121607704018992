import { ApexChart } from "apex-react-charts";
import React from "react";
import S from "./style.module.scss";

const options = {
  chart: {
    height: 350,
    type: "area",
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 4,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};

const AreaLineChart = ({
  title,
  data = [],
  height = 180,
  width = "100%",
  propOptions = {},
  tickAmount = 0,
}) => {
  const series = [
    {
      name: "1",
      data: data.map((d) => d?.y),
    },
  ];

  const Labels = data.map((d) => d?.x);

  return (
    <>
      <div className={S.header}>
        <h1 className={S.headerTitle}>{title}</h1>
      </div>
      <ApexChart
        options={{
          ...options,
          labels: Labels,
          yaxis: {
            tickAmount: tickAmount || data?.length + 1,
            labels: {
              offsetX: -10,
            },
          },
          ...propOptions,
        }}
        series={series}
        type="area"
        height={height}
        width={"100%"}
      />
    </>
  );
};

export default React.memo(AreaLineChart);
