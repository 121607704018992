//libs
import React from "react";
import moment from "moment";

//styles
import S from "./style.module.scss";

//constants
import { STRINGS } from "src/shared/constants";

const ProviderDetails = ({ data }) => {
  const { hospitalData } = data;
  return (
    <div className={S.container}>
      <div className={S.details}>
        <div className={S.detailsItem}>
          <p className={S.detailsTitle}>{STRINGS.NAME_LABEL}</p>
          <p>{data.fullName || STRINGS.EMPTY_DATA}</p>
        </div>
        <div className={S.detailsItem}>
          <p className={S.detailsTitle}>{STRINGS.EMAIL_LABEL}</p>
          <p>{data.email || STRINGS.EMPTY_DATA}</p>
        </div>
        <div className={S.detailsItem}>
          <p className={S.detailsTitle}>{STRINGS.MEMBER_SINCE}</p>
          <p>
            {data.createdAt
              ? moment(data.createdAt).format("Do MMMM YYYY")
              : STRINGS.EMPTY_DATA}
          </p>
        </div>
      </div>
      {hospitalData && (
        <>
          <h2 className={S.detailsHeading}>{STRINGS.HOSPITAL_DETAILS}</h2>
          <div className={S.details}>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.ID}</p>
              <p>{hospitalData.uniqueId || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}> {STRINGS.NAME_LABEL}</p>
              <p>{hospitalData.name || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}> {STRINGS.MEMBER_SINCE}</p>
              <p>
                {hospitalData.createdAt
                  ? moment(hospitalData.createdAt).format("Do MMMM YYYY")
                  : STRINGS.EMPTY_DATA}
              </p>
            </div>

            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.CONTACT_NUMBER_LABEL}</p>
              <p>{hospitalData.phoneNumber || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.BUILDING_LABEL}</p>
              <p>{hospitalData.buildingNumber || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.STREET_LABEL}</p>
              <p>{hospitalData.streetName || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.TOWN_LABEL}</p>
              <p>{hospitalData.town || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.CITY_LABEL}</p>
              <p>{hospitalData.city || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.POSTALCODE_LABEL}</p>
              <p>{hospitalData.postalCode || STRINGS.EMPTY_DATA}</p>
            </div>
            <div className={S.detailsItem}>
              <p className={S.detailsTitle}>{STRINGS.COUNTRY_LABEL}</p>
              <p>{hospitalData.country || STRINGS.EMPTY_DATA}</p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default React.memo(ProviderDetails);
