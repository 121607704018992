//libs
import React from "react";
import { withSnackbar } from "notistack";

//styles
import S from "./resetPassword.module.scss";

//hooks
import { useHistory } from "react-router-dom";

//constants
import { STRINGS, LOGIN } from "src/shared/constants";
import Logo from "src/assets/images/asthmaGo_logo.png";

//form
import { ResetPasswordForm } from "./resetPasswordForm";

const ResetPassword = ({ resetPassword = () => {}, enqueueSnackbar }) => {
  const history = useHistory();

  const handleSubmit = (values) => {
    const { confirmPassword, ...rest } = values;
    resetPassword({
      payload: { ...rest },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        history.push(LOGIN);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  return (
    <section className={S.section}>
      <div className={S.container}>
        <div className={S.containerLogo}>
          <img src={Logo} alt="" width="100%" />
        </div>
        <div className={S.containerRight}>
          <h1 className={S.containerRightHeading}>{STRINGS.RESET_PASSWORD}</h1>
          <p className={S.message}>{STRINGS.EMAIL_SENT}</p>
          <ResetPasswordForm onSubmit={handleSubmit} />
        </div>
      </div>
    </section>
  );
};

export default withSnackbar(ResetPassword);
