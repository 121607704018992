//libs
import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

//components
import {
  BreadcrumbMenu,
  ConfirmDialog,
  CustomButton,
  CustomPagination,
  HalfCardModal,
} from "src/components";
import { AddSubAdminForm } from "./AddSubAdminsForm";
import { SubAdminsTable } from "./SubAdminsTable";

//constants
import {
  FORM_TYPES,
  STRINGS,
  USER_PERMISSIONS,
  USER_ROLES,
  WARNINGS,
} from "src/shared/constants";

//styles
import S from "./SubAdmins.module.scss";

const SubAdmins = ({
  startLoading = () => {},
  stopLoading = () => {},
  addSubAdmin = () => {},
  uploadImage = () => {},
  deleteSubAdmin = () => {},
  enqueueSnackbar = () => {},
  updateSubAdmin = () => {},
  getAdmins = () => {},
  getAdmin = () => {},
  userDetails = {},
}) => {
  const initialFormStates = {
    formData: {},
    formViewType: FORM_TYPES.add,
  };

  const [showHalfCard, setShowHalfCard] = useState(false);
  const [formStates, setFormStates] = useState(initialFormStates);
  const [adminList, setAdminsList] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [listCount, setListCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchAdminsList();
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchAdminsList = () => {
    getAdmins({
      payload: {
        role: USER_ROLES.SUB_ADMIN,
        limit: 10,
        offset: (currentPage - 1) * 10,
      },
      success: (data) => {
        setAdminsList([...data.data]);
        setListCount(data.count);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const fetchAdmin = (userId, formType) => {
    getAdmin({
      payload: { userId },
      success: (data) => {
        setFormStates({
          formData: data?.data || {},
          formViewType: formType,
        });
        setShowHalfCard(true);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleSubmit = async (values, resetForm) => {
    if (
      !PERMISSIONS.includes(USER_PERMISSIONS.ADD) &&
      !PERMISSIONS.includes(USER_PERMISSIONS.UPDATE)
    )
      return;
    let dataObj = {
      role: USER_ROLES.SUB_ADMIN,
      email: values.email,
      phoneNumber: values.phoneNumber,
      fullName: values.firstName + " " + values.lastName,
      profilePicture: values.file ?? values.profilePicture,
    };

    if (values.file) {
      handleUploadImage(values.file, (imageUrl) => {
        dataObj.profilePicture = imageUrl;
        sendSubAdmin(values.id, dataObj, resetForm);
      });
    } else {
      sendSubAdmin(values.id, dataObj, resetForm);
    }
  };

  const sendSubAdmin = (id = "", dataObj = {}, resetForm = () => {}) => {
    if (id && formStates.formViewType === FORM_TYPES.edit) {
      handleUpdateSubAdmin(dataObj, id, resetForm);
    } else {
      handleAddSubAdmin(dataObj, resetForm);
    }
  };

  const handleAddSubAdmin = (values, resetForm = () => {}) => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.ADD)) return;
    addSubAdmin({
      payload: { ...values },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        fetchAdminsList();
        setFormStates(initialFormStates);
        setShowHalfCard(false);
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleUpdateSubAdmin = (
    values = {},
    userId = "",
    resetForm = () => {}
  ) => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.UPDATE)) return;
    updateSubAdmin({
      payload: { values, userId },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        fetchAdminsList();
        setFormStates(initialFormStates);
        setShowHalfCard(false);
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleUploadImage = async (file, callback) => {
    uploadImage({
      payload: file,
      success: (data) => {
        callback(data.fileUrl);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" }); //requires server for images
        callback("");
      },
    });
  };

  const handleDeleteSubAdmin = (userId = "") => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.DELETE)) return;
    if (userDetails.id === userId) {
      enqueueSnackbar(WARNINGS.SELF_DELETE, { variant: "error" });
      return;
    }
    setShowDialog(true);
    setDeleteUser(userId);
  };

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    setTimeout(() => {
      setFormStates({
        formData: {},
        formViewType: FORM_TYPES.add,
      });
      callback();
    }, 320);
  };

  const handleAddButton = () => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.ADD)) return;
    setShowHalfCard(true);
    setFormStates({ ...formStates, formViewType: FORM_TYPES.add });
  };

  const handleViewDetails = (id) => {
    fetchAdmin(id, FORM_TYPES.view);
  };

  const handleEdit = (id) => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.UPDATE)) return;
    fetchAdmin(id, FORM_TYPES.edit);
  };

  const handleActivate = (id) => {
    handleUpdateSubAdmin({ isEmailVerified: true }, id);
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      deleteSubAdmin({
        payload: { userId: deleteUser },
        success: (msg) => {
          fetchAdminsList();
          enqueueSnackbar(msg, { variant: "success" });
        },
        fail: (msg) => {
          enqueueSnackbar(msg, { variant: "error" });
        },
      });
    }
    setShowDialog(false);
    setDeleteUser("");
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const PERMISSIONS = userDetails.permissions || [];

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.SUB_ADMINS}>
        {!!PERMISSIONS.includes(USER_PERMISSIONS.ADD) && (
          <CustomButton
            title={STRINGS.ADD}
            buttonClass={S.headButton}
            icon={"user"}
            iconClass={S.headButtonIcon}
            onClick={handleAddButton}
          />
        )}
      </BreadcrumbMenu>

      <SubAdminsTable
        data={adminList}
        handleDelete={handleDeleteSubAdmin}
        handleViewDetails={handleViewDetails}
        handleEdit={handleEdit}
        handleActivate={handleActivate}
        permissions={PERMISSIONS}
      />

      {!!adminList.length && (
        <CustomPagination
          count={listCount}
          selectedPage={currentPage}
          handlePagination={handlePagination}
          currentPageLength={adminList.length}
        />
      )}

      <HalfCardModal
        showCard={showHalfCard}
        // onClose={handleFormClose}
        title={
          formStates.formViewType === FORM_TYPES.add
            ? STRINGS.ADD_SUB_ADMIN
            : formStates.formViewType === FORM_TYPES.edit
            ? STRINGS.UPDATE_SUB_ADMIN
            : STRINGS.SUB_ADMIN_DETAILS
        }
      >
        <AddSubAdminForm
          formData={formStates.formData}
          formType={formStates.formViewType}
          handleCancel={handleFormClose}
          onSubmit={handleSubmit}
          startLoading={startLoading}
          stopLoading={stopLoading}
        />
      </HalfCardModal>

      {showDialog && (
        <ConfirmDialog
          title={"Are you sure you want to delete user?"}
          onClose={handleConfirmDialog}
        />
      )}
    </section>
  );
};

export default withSnackbar(SubAdmins);
