import { connect } from "react-redux";
import { forgotPassword } from "src/redux/actions";
import ForgotPassword from "./ForgotPassword";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    forgotPassword: (payload) => dispatch(forgotPassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
