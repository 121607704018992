//effects
import { all, takeLatest } from "redux-saga/effects";

//services
import { getRequest, sanitizeQuery } from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import { ACTION_TYPES } from "../actions";

function* getRecordsForPdf({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    const { data = {} } = yield getRequest({
      API: API.GET_RECORDS_FOR_PDF(`${sanitizeQuery(payload)}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  }
}

function* getSpiroLinkRecords({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    const { data = {} } = yield getRequest({
      API: API.GET_SPIRO_RECORDS_FOR_PDF(`${sanitizeQuery(payload)}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  }
}

function* PdfReportSaga() {
  yield all([
    takeLatest(ACTION_TYPES.GET_RECORDS_FOR_PDF, getRecordsForPdf),
    takeLatest(ACTION_TYPES.GET_SPIRO_LINK_RECORDS, getSpiroLinkRecords),
  ]);
}

export default PdfReportSaga;
