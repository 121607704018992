//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//icons
import { Icomoon } from "src/assets/icons";

export const Checkbox = ({
  field: { name, value },
  form: { setFieldTouched, setFieldValue },
  label,
  values,
  checked,
  onChange,
  ...props
}) => {
  const handleChange = () => {
    if (props.readOnly) return;
    if (onChange && typeof onChange === "function") {
      onChange();
      return;
    }
    let checkedValues = [...values.appType];
    const pos = checkedValues.indexOf(value);
    if (pos >= 0) {
      checkedValues.splice(pos, 1);
    } else {
      checkedValues.push(value);
    }
    setFieldTouched(name);
    setFieldValue(name, checkedValues);
  };

  return (
    <div className={S.checkbox}>
      {values?.appType?.includes(value) || checked ? (
        <Icomoon
          className={cx(S.checkboxChecked, {
            [S.checkboxDisabled]: props.readOnly,
          })}
          icon={"checkbox-checked"}
          onClick={handleChange}
        />
      ) : (
        <div
          className={cx(S.checkboxUnchecked, {
            [S.checkboxDisabled]: props.readOnly,
          })}
          onClick={handleChange}
        />
      )}
      <span className={S.checkboxLabel}>{label}</span>
    </div>
  );
};
