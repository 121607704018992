//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//constants
import { NOTIFICATION_NAMES, STRINGS } from "src/shared/constants";

//components
import { Icomoon } from "src/assets/icons";
import moment from "moment";

export const NotificationsTable = ({
  data = [],
  handleDelete = (id) => {},
  handleSort = () => {},
  handleSelect = () => {},
}) => {
  return (
    <div className={S.tableContainer}>
      {!!!data.length ? (
        <p className={S.noData}>{STRINGS.NO_NOTIFICATIONS_FOUND}</p>
      ) : (
        <table className={S.usersTable}>
          <thead>
            <tr className={cx(S.usersTableRow, S.usersTableHeading)}>
              <th>
                <span className={S.usersTableText}>Notification ID</span>
              </th>
              <th>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={S.usersTableText}>Notification Type</span>
                  <Icomoon
                    className={cx(S.icon, S.sort)}
                    icon={"sort"}
                    color="#fff"
                    onClick={() => handleSort("notificationType")}
                  />
                </div>
              </th>
              <th>
                <span className={S.usersTableText}>Message</span>
              </th>
              <th>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={S.usersTableText}>Date of Creation</span>
                  <Icomoon
                    className={cx(S.icon, S.sort)}
                    icon={"sort"}
                    color="#fff"
                    onClick={() => handleSort("createdAt ")}
                  />
                </div>
              </th>
              <th>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className={S.usersTableText}>App Type</span>
                  <Icomoon
                    className={cx(S.icon, S.sort)}
                    icon={"sort"}
                    color="#fff"
                    onClick={() => handleSort("appType")}
                  />
                </div>
              </th>
              <th>
                <span className={S.usersTableText}>Action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, key) => (
              <tr key={key} className={S.usersTableRow}>
                <td style={{ display: "flex", alignItems: "center" }}>
                  <span className={S.usersTableText}>
                    {d.id || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {NOTIFICATION_NAMES[d.notificationType] ||
                      STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.message || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.createdAt
                      ? moment(d.createdAt).format("MMM Do, YYYY")
                      : STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.appType || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <Icomoon
                    className={cx(S.icon, S.view)}
                    icon={"eye"}
                    onClick={() => handleSelect(d)}
                  />
                  <Icomoon
                    className={cx(S.icon, S.delete)}
                    icon={"bin"}
                    onClick={() => handleDelete(d.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
