//libs
import React from "react";
import cx from "classnames";

//style
import S from "./style.module.scss";
import { Icomoon } from "src/assets/icons";

const CustomButton = ({
  title = "",
  type = "button",
  onClick = () => {},
  buttonClass = "",
  iconClass = "",
  icon,
  id = "",
}) => {
  return (
    <button
      type={type}
      className={cx(S.customButton, buttonClass)}
      onClick={onClick}
      id={id}
    >
      {title}
      {icon && <Icomoon className={iconClass} icon={icon} color="#fff" />}
    </button>
  );
};

export default CustomButton;
