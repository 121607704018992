export const ACTION_TYPES = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING",
  UPDATE_AUTH_TOKEN: "UPDATE_AUTH_TOKEN",
  SIGN_IN: "SIGN_IN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  SIGN_OUT: "SIGN_OUT",
  RESET_PASSWORD: "RESET_PASSWORD",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  ADD_USER: "ADD_USER",
  GET_USERS: "GET_USERS",
  GET_USER: "GET_USER",
  UPDATE_USER: "UPDATE_USER",
  DELETE_USER: "DELETE_USER",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  ADD_PERMISSION: "ADD_PERMISSION",
  GET_PERMISSION: "GET_PERMISSION",
  GET_PERMISSION_REDUX: "GET_PERMISSION_REDUX",
  ADD_PROVIDER: "ADD_PROVIDER",
  ADD_DEVICE: "ADD_DEVICE",
  GET_DEVICES: "GET_DEVICES",
  GET_DEVICE: "GET_DEVICE",
  UPDATE_DEVICE: "UPDATE_DEVICE",
  DELETE_DEVICE: "DELETE_DEVICE",
  GET_DASHBOARD_DATA: "GET_DASHBOARD_DATA",
  SEND_NOTIFICATION: "SEND_NOTIFICATION",
  GET_USER_REPORT: "GET_USER_REPORT",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  GET_OXY_TEST_SESSION_DATA: "GET_OXY_TEST_SESSION_DATA",
  UPLOAD_BULK_DEVICE: "UPLOAD_BULK_DEVICE",
  GET_NOTIFICATIONS_LIST: "GET_NOTIFICATIONS_LIST",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  GET_RECORDS_FOR_PDF: "GET_RECORDS_FOR_PDF",
  RESET_USER_PASSWORD: "RESET_USER_PASSWORD",
  GET_TEST_SESSIONS_DATA: "GET_TEST_SESSIONS_DATA",
  GET_USER_SESSIONS: "GET_USER_SESSIONS",
  RECREATE_SLEEP_ANALYSIS: "RECREATE_SLEEP_ANALYSIS",
  GET_CONFIG: "GET_CONFIG",
  UPDATE_CONFIG: "UPDATE_CONFIG",
  GET_SPIRO_LINK_RECORDS: "GET_SPIRO_LINK_RECORDS",
};
