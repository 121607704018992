import { ACTION_TYPES } from "./actionTypes";

export const getRecordsForPdf = (payload) => {
  return {
    type: ACTION_TYPES.GET_RECORDS_FOR_PDF,
    payload,
  };
};

export const getSpiroLinkRecords = (payload) => {
  return {
    type: ACTION_TYPES.GET_SPIRO_LINK_RECORDS,
    payload,
  };
};
