//libs
import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

//constants
import { DEVICE_TYPES, FORM_TYPES, STRINGS } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//components
import {
  BreadcrumbMenu,
  ConfirmDialog,
  CustomButton,
  CustomPagination,
  HalfCardModal,
  SearchBox,
} from "src/components";
import { AddDeviceForm } from "../AddDeviceForm";
import { DevicesTable } from "../DevicesTable";

const DevicesView = ({
  getDevices = () => { },
  getDevice = () => { },
  enqueueSnackbar = () => { },
  addDevice = () => { },
  updateDevice = () => { },
  deleteDevice = () => { },
  uploadBulkDevice = () => { },
}) => {
  const [showHalfCard, setShowHalfCard] = useState(false);
  const [formStates, setFormStates] = useState({});
  const [devicesList, setDevicesList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [sortKey, setSortKey] = useState("type");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [filterDeviceType, setFilterDeviceType] = useState('');

  const initialFormStates = {
    formData: {},
    formViewType: FORM_TYPES.add,
  };

  useEffect(() => {
    fetchDevices();
    // eslint-disable-next-line
  }, [currentPage, sortOrder, sortKey, filterDeviceType]);

  const fetchDevices = () => {
    getDevices({
      payload: {
        limit: 10,
        offset: (currentPage - 1) * 10,
        searchString: query,
        sortKey,
        sortOrder,
        filterDeviceType,
      },
      success: (data) => {
        setDevicesList([...data.data]);
        setListCount(data.count);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const fetchDevice = (deviceId, formType) => {
    getDevice({
      payload: { deviceId },
      success: (data) => {
        setFormStates({
          formData: data?.data || {},
          formViewType: formType,
        });
        setShowHalfCard(true);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    fetchDevices();
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    setTimeout(() => {
      setFormStates({
        formData: {},
      });
      callback();
    }, 320);
  };

  const handleSubmit = async (values, resetForm) => {
    if (formStates.formViewType === FORM_TYPES.edit) {
      handleUpdateDevice(values, resetForm);
    } else {
      handleAddDevice(values, resetForm);
    }
  };

  const handleAddDevice = (values, resetForm = () => { }) => {
    const { deviceIMEI, deviceName, deviceType, deviceQty } = values;
    addDevice({
      payload: {
        deviceId: deviceIMEI,
        name: deviceName,
        type: deviceType,
        quantity: deviceQty,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        fetchDevices();
        setFormStates(initialFormStates);
        setShowHalfCard(false);
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleUpdateDevice = (values = {}, resetForm = () => { }) => {
    const { id, deviceIMEI, deviceName, deviceType, deviceQty } = values;
    updateDevice({
      payload: {
        values: {
          name: deviceName,
          type: deviceType,
          quantity: deviceQty,
          deviceId: deviceIMEI,
        },
        deviceId: id,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        fetchDevices();
        setFormStates(initialFormStates);
        setShowHalfCard(false);
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleDeleteDevice = (id = "") => {
    setShowDialog(true);
    setDeviceId(id);
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      deleteDevice({
        payload: { id: deviceId },
        success: (msg) => {
          fetchDevices();
          enqueueSnackbar(msg, { variant: "success" });
        },
        fail: (msg) => {
          enqueueSnackbar(msg, { variant: "error" });
        },
      });
    }
    setShowDialog(false);
    setDeviceId("");
  };

  const handleAddButton = () => {
    setShowHalfCard(true);
    setFormStates({ ...formStates, formViewType: FORM_TYPES.add });
  };

  const handleEdit = (id) => {
    fetchDevice(id, FORM_TYPES.edit);
  };

  const handleFileSelect = (e) => {
    if (e.target.files?.length) {
      setFile(e.target.files[0]);
    }
  };

  const handleFileUpload = () => {
    uploadBulkDevice({
      payload: file,
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        setFile({});
        fetchDevices();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleSort = () => {
    setSortKey("type");
    switch (sortOrder) {
      case "DESC":
        setSortOrder("ASC");
        break;

      case "ASC":
        setSortOrder("DESC");
        break;

      default:
        break;
    }
  };

  const [file, setFile] = useState({});

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.CONFIGURE} subTitle={STRINGS.DEVICES}>
        <CustomButton
          title={STRINGS.ADD}
          buttonClass={S.headButton}
          icon={"cpu"}
          iconClass={S.headButtonIcon}
          onClick={handleAddButton}
        />
      </BreadcrumbMenu>

      <div className={S.header}>
        <SearchBox
          className={S.searchBox}
          handleSearch={handleSearch}
          value={query}
          setValue={setQuery}
        />

        <select
          className={S.dateInput}
          onChange={(e) => setFilterDeviceType(e.target.value)}
          value={filterDeviceType}
        >
          <option
            className={S.option}
            value={''}
            label={'ALL'}
          />
          {DEVICE_TYPES.slice(0, 4).map((device) => (
            <option
              className={S.option}
              value={device.value}
              label={device.label}
            />
          ))}
        </select>

        <div className={S.customFile}>
          {file?.name ? (
            <div className={S.flexdiv}>
              <p className={S.customFileText}>{file?.name}</p>
              <button
                className={S.customFileButton}
                type="button"
                onClick={handleFileUpload}
              >
                {STRINGS.UPLOAD}
              </button>
            </div>
          ) : (
            <label className={S.customFileLabel}>
              <input
                className={S.customFileInput}
                type="file"
                accept=".csv, text/csv"
                onChange={handleFileSelect}
              />
              Bulk Add Devices
            </label>
          )}
        </div>
      </div>

      <DevicesTable
        data={devicesList}
        handleDelete={handleDeleteDevice}
        handleEdit={handleEdit}
        handleSort={handleSort}
      />

      {!!devicesList.length && (
        <CustomPagination
          count={listCount}
          selectedPage={currentPage}
          handlePagination={handlePagination}
          currentPageLength={10}
        />
      )}

      <HalfCardModal showCard={showHalfCard} title={STRINGS.ADD_DEVICE}>
        <AddDeviceForm
          formData={formStates.formData}
          formType={formStates.formViewType}
          handleCancel={handleFormClose}
          onSubmit={handleSubmit}
        />
      </HalfCardModal>

      {showDialog && (
        <ConfirmDialog
          title={"Are you sure you want to delete device?"}
          onClose={handleConfirmDialog}
        />
      )}
    </section>
  );
};

export default withSnackbar(DevicesView);
