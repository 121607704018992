import { combineReducers } from "redux";
import CommonReducer from "./common";
import PermissionsReducer from "./permissions";

const RootReducer = combineReducers({
  common: CommonReducer,
  permissions: PermissionsReducer,
});

export default RootReducer;
