//libs
import React from "react";
import moment from "moment";

//helper
import cx from "classnames";
import { matchUserImage } from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import ProfilePic from "src/assets/images/profile.png";
import { MEMBER_PROFILE, STRINGS } from "src/shared/constants";

//components
import { useHistory } from "react-router-dom";

export const MembersTable = ({ data = [], loading = false }) => {
  const history = useHistory();

  return (
    <div className={S.tableContainer}>
      {!!!data.length && !!!loading ? (
        <p className={S.noData}>{STRINGS.NO_MEMBERS_FOUND}</p>
      ) : (
        <table className={S.usersTable}>
          <thead>
            <tr className={cx(S.usersTableRow, S.usersTableHeading)}>
              <th>
                <span className={S.usersTableText}>Id</span>
              </th>
              <th>
                <span className={S.usersTableText}>Name</span>
              </th>
              <th>
                <span className={S.usersTableText}>Email</span>
              </th>
              <th>
                <span className={S.usersTableText}>Date of Birth</span>
              </th>
              <th>
                <span className={S.usersTableText}>Gender</span>
              </th>
              <th>
                <span className={S.usersTableText}>Race</span>
              </th>
              <th>
                <span className={S.usersTableText}>Country</span>
              </th>
              <th>
                <span className={S.usersTableText}>Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => (
              <tr
                key={d.id}
                className={S.usersTableRow}
                onClick={() =>
                  history.push({ pathname: MEMBER_PROFILE, search: d.id })
                }
              >
                <td>
                  <span className={S.usersTableText}>
                    {d.uniqueId || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={
                      d.profilePicture
                        ? matchUserImage(d.profilePicture)
                        : ProfilePic
                    }
                    alt=""
                    className={S.usersTableImage}
                  />
                  <span
                    className={S.usersTableText}
                    style={{ marginLeft: "16px" }}
                  >
                    {d.fullName || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.email || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.dob
                      ? moment(d.dob).format("MMM Do, YYYY")
                      : STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.gender || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.race || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.country || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <div
                    className={cx(
                      S.status,
                      d.isEmailVerified && !!!d.isBlocked
                        ? S.statusActive
                        : S.statusPending
                    )}
                  >
                    {d.isEmailVerified
                      ? d.isBlocked
                        ? STRINGS.BLOCKED
                        : STRINGS.ACTIVE
                      : STRINGS.PENDING}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
