import { ACTION_TYPES } from "./actionTypes";

export const addPermission = (payload) => {
  return {
    type: ACTION_TYPES.ADD_PERMISSION,
    payload,
  };
};

export const deletePermission = (payload) => {
  return {
    type: ACTION_TYPES.DELETE_PERMISSION,
    payload,
  };
};

export const getPermission = (payload) => {
  return {
    type: ACTION_TYPES.GET_PERMISSION,
    payload,
  };
};

export const getPermissionRedux = (payload) => {
  return {
    type: ACTION_TYPES.GET_PERMISSION_REDUX,
    payload,
  };
};
