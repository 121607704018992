//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import {
  getRequest,
  sanitizeQuery,
  updateAuthToken,
} from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  startLoading,
  stopLoading,
  updateAuthTokenRedux,
} from "../actions";

function* getDashboardData({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data = {} } = yield getRequest({
      API: API.GET_DASHBOARD_DATA,
    });

    if (data.statusCode === RESPONSE.success) {
      success(data.data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getTestSessionData({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data = {} } = yield getRequest({
      API: API.GET_TEST_SESSIONS_DATA(`${sanitizeQuery(payload)}`),
    });

    if (data.statusCode === RESPONSE.success) {
      success(data.data);
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getUserSessionData({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());

    const { data = {} } = yield getRequest({
      API: API.GET_USER_SESSIONS_DATA(`${sanitizeQuery(payload)}`),
    });

    if (data.statusCode === RESPONSE.success) {
      success(data.data);
    } else {
      // fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* DashboardSaga() {
  yield all([
    takeLatest(ACTION_TYPES.GET_DASHBOARD_DATA, getDashboardData),
    takeLatest(ACTION_TYPES.GET_TEST_SESSIONS_DATA, getTestSessionData),
    takeLatest(ACTION_TYPES.GET_USER_SESSIONS, getUserSessionData),
  ]);
}

export default DashboardSaga;
