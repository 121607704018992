//libs
import React from "react";

//styles
import S from "./style.module.scss";

const BreadcrumbMenu = ({ title = "", subTitle = "", children }) => {
  return (
    <div className={S.head}>
      <p className={S.headTitle}>
        <span>{title}</span>
        {subTitle && ` > ${subTitle}`}
      </p>
      {children}
    </div>
  );
};

export default React.memo(BreadcrumbMenu);
