import { connect } from "react-redux";
import {
  deleteNotification,
  getNotificationsList,
  sendNotification,
} from "src/redux/actions";
import Notifications from "./IndexPage";

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationsList: (payload) => dispatch(getNotificationsList(payload)),
    sendNotification: (payload) => dispatch(sendNotification(payload)),
    deleteNotification: (payload) => dispatch(deleteNotification(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
