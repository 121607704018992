//routes
import {
  DASHBOARD,
  PROVIDERS,
  MEMBERS,
  CONFIGURE,
  SUB_ADMINS,
  CHANGE_PASSWORD,
  PROVIDER_PROFILE,
  NOTIFICATIONS,
} from "./routes";

//constants
import { STRINGS } from "./strings";

export const SideBarMenu1 = [
  { title: STRINGS.DASHBOARD, link: DASHBOARD, icon: "dashboard", exact: true },
  { title: STRINGS.SUB_ADMINS, link: SUB_ADMINS, icon: "user", exact: true },
  { title: STRINGS.PROVIDERS, link: PROVIDERS, icon: "doctor" },
  { title: STRINGS.MEMBERS, link: MEMBERS, icon: "group" },
  { title: STRINGS.NOTIFICATIONS, link: NOTIFICATIONS, icon: "bell" },
  { title: STRINGS.CONFIGURE, link: CONFIGURE, icon: "cog" },
  { title: STRINGS.CHANGE_PASSWORD, link: CHANGE_PASSWORD, icon: "key" },
];

export const SideBarMenu2 = [
  { title: STRINGS.DASHBOARD, link: DASHBOARD, icon: "dashboard", exact: true },
  { title: STRINGS.SUB_ADMINS, link: SUB_ADMINS, icon: "user", exact: true },
  { title: STRINGS.PROVIDERS, link: PROVIDERS, icon: "doctor" },
  { title: STRINGS.MEMBERS, link: MEMBERS, icon: "group" },
  { title: STRINGS.NOTIFICATIONS, link: NOTIFICATIONS, icon: "bell" },
  { title: STRINGS.CHANGE_PASSWORD, link: CHANGE_PASSWORD, icon: "key" },
];

export const ProviderSideBar = [
  { title: STRINGS.PROVIDERS, link: PROVIDER_PROFILE, icon: "doctor" },
  { title: STRINGS.CHANGE_PASSWORD, link: CHANGE_PASSWORD, icon: "key" },
];
