import React from "react";

//styles
import styles from "./Report.module.scss";

//widgets
import SpiroLinkGraph from "./SpiroLinkGraph";

//helpers
import moment from "moment-timezone";

//assets
import Page from "../../Page";

const Report = ({
  dateRange,
  records = [],
  userData = {},
  timezone = "",
  totalPages = 0,
  setTotalPages = () => {},
}) => {
  records = [
    records[0],
    records[1],
    records[2],
    [...records[3], ...records[4]],
  ];

  let count = 1;

  return (
    <div>
      {records?.map((record, key) => {
        const data = [
          ...record.map((r) => ({
            date: moment(r.createdAt).tz(timezone).format("MM-DD-YYYY"),
            time: moment(r.createdAt).tz(timezone).format("HH:mm A"),
            pef: r.pef ? Number(r.pef).toFixed(2) : null,
            pefPercent: r.pef
              ? Number(userData?.pef)
                ? `${Number((r.pef / userData.pef) * 100).toFixed(2)}%`
                : "N/A"
              : null,
            fev1: r.fev1 ? Number(r.fev1).toFixed(2) : null,
            fev1Percent: r.fev1
              ? Number(userData?.fev1)
                ? `${Number((r.fev1 / userData.fev1) * 100).toFixed(2)}%`
                : "N/A"
              : null,
          })),
        ];

        const { start, end } = getWeekDates(data[0]?.date);

        const graphData = [
          ...data.map((d) => ({
            ...d,
            date: moment(d.date).format("MM-DD"),
          })),
        ];

        if (!record?.find((r) => r?.pef)) {
          return null;
        }

        count += 1;
        setTotalPages(count);

        return (
          <Page key={key} currentPage={count} totalPages={totalPages}>
            <div className={styles.body}>
              <div className={styles.heading}>
                <div className={styles.headingTitle}>{`Lung Health Report`}</div>
                <div className={styles.headingSub}>{`${start} - ${end}`}</div>
              </div>
              <SpiroLinkGraph tickAmount={8} data={graphData} dataLabelsEnabled height={320} />

              <table className={styles.bodyTable} border="1">
                <thead className={styles.bodyTableHeading}>
                  <tr>
                    <th>{"Date"}</th>
                    <th>{"Time"}</th>
                    <th>{"PEF"}</th>
                    <th>{"PEF%"}</th>
                    <th>{"FEV1"}</th>
                    <th>{"FEV1%"}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row, id) =>
                    row?.pef ? (
                      <tr className={styles.bodyTableRow} key={id}>
                        <td>{row.date}</td>
                        <td>{row.time}</td>
                        <td>{row.pef}</td>
                        <td>{row.pefPercent}</td>
                        <td>{row.fev1}</td>
                        <td>{row.fev1Percent}</td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </Page>
        );
      })}
    </div>
  );
};

export default React.memo(Report);

function getWeekDates(inputDate) {
  const date = new Date(inputDate);

  // Calculate the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  const day = date.getDate();

  // Calculate the starting date of the week (Monday)
  let startDate = new Date(date);
  let endDate = new Date(date);

  if (day <= 7) {
    startDate = new Date(date.getFullYear(), date.getMonth(), 2);
    endDate = new Date(date.getFullYear(), date.getMonth(), 8);
  } else if (day > 7 && day <= 14) {
    startDate = new Date(date.getFullYear(), date.getMonth(), 9);
    endDate = new Date(date.getFullYear(), date.getMonth(), 15);
  } else if (day > 14 && day <= 21) {
    startDate = new Date(date.getFullYear(), date.getMonth(), 16);
    endDate = new Date(date.getFullYear(), date.getMonth(), 22);
  } else if (day > 21 && day <= 28) {
    startDate = new Date(date.getFullYear(), date.getMonth(), 23);
    endDate = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }

  return {
    start: moment(startDate.toISOString().split("T")[0]).format("MM-DD-YYYY"),
    end: moment(endDate.toISOString().split("T")[0]).format("MM-DD-YYYY"),
  };
}
