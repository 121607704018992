import { connect } from "react-redux";
import {
  getDashboardData,
  getTestSessionsData,
  getUserSessionsData,
} from "src/redux/actions";
import Dashboard from "./Dashboard";

const mapStateToProps = (state) => {
  return {
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDashboardData: (payload) => dispatch(getDashboardData(payload)),
    getTestSessionsData: (payload) => dispatch(getTestSessionsData(payload)),
    getUserSessionsData: (payload) => dispatch(getUserSessionsData(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
