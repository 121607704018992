//routes

export const LOGIN = "/login";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password";
export const DASHBOARD = "/";
export const SUB_ADMINS = "/sub-admins";
export const PROVIDERS = "/providers";
export const MEMBERS = "/members";
export const CONFIGURE = "/configure";
export const CHANGE_PASSWORD = "/change-password";
export const ROLES_AND_PERMISSIONS = "/configure/roles-and-permissions";
export const DEVICES = "/configure/devices";
export const CONFIG = "/configure/config";
export const NOTIFICATIONS = "/notifications";
export const MEMBER_PROFILE = "/members/profile";
export const PROVIDER_PROFILE = "/providers/profile";
export const REPORT_PDF_DOWNLOAD = "/pdf-report";
