//libs
import React from "react";

//constants
import { ChartColors, STRINGS } from "src/shared/constants";

//components
import { ApexChart } from "apex-react-charts";

const SessionsCountBar = ({ data = [] }) => {
  if (!Object.entries(data)?.length)
    return <div style={{ textAlign: "center" }}>{STRINGS.NOT_ENOUGH_DATA}</div>;

  let values = Object.values(data).reduce((prev, cur, i) => {
    let p = prev;
    const keys = Object.keys(cur);
    let key = "";
    keys.forEach((k) => {
      key = `${k}`;
      p[key] = p[key] ?? [];
      p[key].push(cur[key] ?? 0);
    });
    return p;
  }, {});

  const { one, two, three, four, five, six } = ChartColors;

  const series = [
    ...Object.entries(values).map(([key, value]) => ({
      name: key,
      data: value,
    })),
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        barHeight: "100%",
        columnWidth: Object.entries(data).length * 3,
        dataLabels: {
          position: "top",
          orientation: "vertical",
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    colors: [one, two, three, four, five, six],
    dataLabels: {
      enabled: true,
      style: {
        colors: ["#000"],
      },
      formatter: function (val) {
        return val ? val : "";
      },
      offsetX: 0,
      offsetY: 10,
    },
    xaxis: {
      categories: [...Object.entries(data).map((e) => e[0].replace("_", ""))],
    },
    yaxis: {
      labels: {
        show: true,
        formatter: (value) => {
          return value.toFixed(0);
        },
      },
    },
    grid: {
      show: false,
    },
  };

  return series?.length ? (
    <div>
      <ApexChart options={options} series={series} type="bar" />
    </div>
  ) : (
    <div style={{ textAlign: "center" }}>{STRINGS.NOT_ENOUGH_DATA}</div>
  );
};

export default React.memo(SessionsCountBar);
