import { connect } from "react-redux";
import { getConfig, updateConfig } from "src/redux/actions";
import Config from "./Config";

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateConfig: (payload) => dispatch(updateConfig(payload)),
    getConfig: (payload) => dispatch(getConfig(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);
