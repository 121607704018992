//libs
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";

//style
import S from "./style.module.scss";

//Views
import {
  Configure,
  Dashboard,
  Members,
  SubAdmins,
  ChangePassword,
  RolesAndPermissions,
  Providers,
  MemberProfile,
  ProviderProfile,
  Devices,
  Notifications,
  Config,
} from "src/views";

//constants
import {
  CHANGE_PASSWORD,
  CONFIGURE,
  DASHBOARD,
  MEMBERS,
  PROVIDERS,
  ROLES_AND_PERMISSIONS,
  SUB_ADMINS,
  USER_PERMISSIONS,
  USER_ROLES,
  MEMBER_PROFILE,
  PROVIDER_PROFILE,
  DEVICES,
  NOTIFICATIONS,
  CONFIG,
} from "src/shared/constants";

//components
import { Header, NavSidebar } from "src/components";

const PrivateRoutes = ({ userDetails }) => {
  const ROLE = userDetails.role || "";
  console.log("ROLE", ROLE)
  const Permissions = userDetails.permissions || [];

  const getSuperAdminRoutes = () =>
    SUPER_ADMIN_ROUTES.map((e, i) => (
      <Route key={i} exact path={e.path} component={e.component} />
    ));

  const getProviderRoutes = () => (
    <Switch>
      {PROIVDER_ROUTES.map((e, i) => (
        <Route key={i} path={e.path} component={e.component} />
      ))}
      <Route
        path="*"
        component={() => <Redirect push to={PROVIDER_PROFILE} />}
      />
    </Switch>
  );

  return (
    <Router>
      <Header />
      <div className={S.container}>
        <NavSidebar />
        {ROLE === USER_ROLES.PROVIDER ? (
          getProviderRoutes()
        ) : (
          <Switch>
            <Route exact path={DASHBOARD} component={Dashboard} />
            <Route exact path={PROVIDERS} component={Providers} />
            <Route exact path={MEMBERS} component={Members} />
            <Route exact path={CHANGE_PASSWORD} component={ChangePassword} />
            <Route path={MEMBER_PROFILE} component={MemberProfile} />
            <Route exact path={PROVIDER_PROFILE} component={ProviderProfile} />
            {Permissions.includes(USER_PERMISSIONS.VIEW) && (
              <Route exact path={SUB_ADMINS} component={SubAdmins} />
            )}
            {ROLE === USER_ROLES.SUPER_ADMIN && getSuperAdminRoutes()}
            <Route exact path={NOTIFICATIONS} component={Notifications} />
            <Route
              path="*"
              component={() => <Redirect push to={DASHBOARD} />}
            />
          </Switch>
        )}
      </div>
    </Router>
  );
};

export default PrivateRoutes;

const SUPER_ADMIN_ROUTES = [
  { path: CONFIGURE, component: Configure },
  { path: ROLES_AND_PERMISSIONS, component: RolesAndPermissions },
  { path: DEVICES, component: Devices },
  { path: CONFIG, component: Config },
];

const PROIVDER_ROUTES = [
  { path: PROVIDER_PROFILE, component: ProviderProfile },
  { path: CHANGE_PASSWORD, component: ChangePassword },
  { path: MEMBER_PROFILE, component: MemberProfile },
];
