//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";
import { USER_IMAGES } from "src/shared/constants";

const SelectAvatar = ({ onClose = () => {}, handleSelect = () => {} }) => {
  return (
    <>
      <div className={S.overlay} onClick={onClose} />
      <div className={cx(S.container)}>
        <p className={S.containerHeader}>{"Select user avatar"}</p>
        <div className={S.containerWrapper}>
          {USER_IMAGES.map((ui) => (
            <img
              className={S.containerWrapperImage}
              key={ui._id}
              src={ui._image}
              alt=""
              onClick={() => handleSelect(ui._id)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectAvatar;
