import { ACTION_TYPES } from "../actions";

const initialState = {
  permissions: {},
};

const PermissionsReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_PERMISSION_REDUX:
      return {
        ...state,
        permissions: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default PermissionsReducer;
