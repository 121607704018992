import React from "react";

//styles
import styles from "./Summary.module.scss";

//widgets
import SpiroLinkGraph from "../Report/SpiroLinkGraph";

//helpers
import moment from "moment-timezone";

const TITLES = [
  "Predicted PEF",
  "Highest PEF and PEF%",
  "Lowest PEF and PEF%",
  "Predicted FEV1",
  "Highest FEV1 and FEV1%",
  "Lowest FEV1 and FEV1%",
  "Number of Tests Performed",
];

const Summary = ({
  summary = [],
  records = [],
  userData = {},
  timezone,
  totalPages = 2,
}) => {
  const BodyText = () => {
    return TITLES?.map((title, key) => {
      return (
        <div key={key} className={styles.bodyText}>
          <div className={styles.bodyTextTitle}>{title}</div>
          <div className={styles.bodyTextSub}>
            {key !== TITLES.length - 1 && summary?.[key][1]
              ? `${summary?.[key][0]} and ${summary?.[key][1]}%`
              : `${summary?.[key][0]}`}
          </div>
        </div>
      );
    });
  };

  const data = [
    ...records.map((r) => ({
      date: moment(r.createdAt).tz(timezone).format("DD"),
      time: moment(r.createdAt).tz(timezone).format("HH:mm A"),
      pef: r.pef ? Number(r.pef).toFixed(2) : null,
      pefPercent: r.pef
        ? `${Number((r.pef / userData.pef) * 100).toFixed(2)}%`
        : null,
      fev1: r.fev1 ? Number(r.fev1).toFixed(2) : null,
      fev1Percent: r.fev1
        ? `${Number((r.fev1 / userData.fev1) * 100).toFixed(2)}%`
        : null,
    })),
  ];

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles.heading}>
          <div className={styles.headingTitle}>{"Lung Health Summary"}</div>
          <div className={styles.headingLine} />
        </div>

        <div className={styles.bodyContainer}>
          <BodyText />
        </div>
      </div>

      <SpiroLinkGraph
        tickAmount={10}
        data={data}
        height={320}
        titles={["Monthly PEF Trend", "Monthly FEV1 Trend"]}
      />
    </div>
  );
};

export default React.memo(Summary);
