//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//components
import CustomButton from "../CustomButton";

//constants
import { STRINGS } from "src/shared/constants";

const ConfirmDialog = ({
  title = "",
  containerClass,
  onClose = () => {},
  yesTitle = STRINGS.DELETE,
}) => {
  const handleClose = () => {
    onClose(false);
  };

  const handleYes = () => {
    onClose(true);
  };

  return (
    <>
      <div className={S.overlay} onClick={handleClose} />
      <div className={cx(S.container, containerClass)}>
        <p className={S.containerHeader}>{title}</p>
        <div className={S.containerBody}>
          <CustomButton
            buttonClass={S.containerButton}
            title={STRINGS.CANCEL}
            onClick={handleClose}
          />
          <CustomButton
            buttonClass={S.containerButton}
            title={yesTitle}
            onClick={handleYes}
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmDialog;
