import { connect } from "react-redux";
import { getUserReport, recreateSleepAnalysisData, setReportData } from "src/redux/actions";
import MemberDetails from "./MemberDetails";

const mapStateToProps = (state) => {
  return {
    userDetails: state.common.userDetails,
    reportData: state.common.reportData,
    loading: state.common.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserReport: (payload) => dispatch(getUserReport(payload)),
    setReportData: (payload) => dispatch(setReportData(payload)),
    recreateSleepAnalysisData: (payload) => dispatch(recreateSleepAnalysisData(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
