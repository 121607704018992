//libs
import React from "react";
import { NavLink } from "react-router-dom";

//assets
import { Icomoon } from "src/assets/icons";
import { STRINGS, USER_PERMISSIONS, USER_ROLES } from "src/shared/constants";

//constants
import {
  ProviderSideBar,
  SideBarMenu1,
  SideBarMenu2,
} from "src/shared/constants/menus";

//styles
import S from "./NavSidebar.module.scss";

const NavSidebar = ({ userDetails }) => {
  const ROLE = userDetails.role;
  const PERMISSIONS = userDetails.permissions || [];
  let SideBarMenu =
    ROLE === USER_ROLES.SUPER_ADMIN
      ? SideBarMenu1
      : ROLE === USER_ROLES.PROVIDER
        ? ProviderSideBar
        : SideBarMenu2;
  if (!PERMISSIONS.includes(USER_PERMISSIONS.VIEW)) {
    SideBarMenu = SideBarMenu.filter((a) => a.title !== STRINGS.SUB_ADMINS);
  }

  return (
    <section className={S.section}>
      <ul className={S.list}>
        {SideBarMenu.map((menu, idx) => (
          <li key={idx} className={S.listItem}>
            <NavLink
              exact={menu.exact}
              className={S.listItemLink}
              to={menu.link}
              activeClassName={S.activeLink}
            >
              <Icomoon className={S.listItemIcon} icon={menu.icon} />
              <p className={S.listItemText}>{menu.title}</p>
            </NavLink>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default NavSidebar;
