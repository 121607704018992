//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import {
  deleteRequest,
  getRequest,
  postRequest,
  sanitizeQuery,
  updateAuthToken,
} from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  startLoading,
  stopLoading,
  updateAuthTokenRedux,
} from "../actions";

function* sendEmailNotification({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.SEND_NOTIFICATION,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getEmailNotification({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.NOTIFICATION_V2(`${sanitizeQuery(payload)}`),
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.data);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* deleteEmailNotification({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield deleteRequest({
      API: API.NOTIFICATION(),
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      yield put(updateAuthTokenRedux(""));
      updateAuthToken("");
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* NotificationAndEmailSaga() {
  yield all([
    takeLatest(ACTION_TYPES.SEND_NOTIFICATION, sendEmailNotification),
    takeLatest(ACTION_TYPES.GET_NOTIFICATIONS_LIST, getEmailNotification),
    takeLatest(ACTION_TYPES.DELETE_NOTIFICATION, deleteEmailNotification),
  ]);
}

export default NotificationAndEmailSaga;
