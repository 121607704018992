import { connect } from "react-redux";
import {
  getDevice,
  getDevices,
  addDevice,
  deleteDevice,
  updateDevice,
  uploadBulkDevice,
} from "src/redux/actions";
import DevicesView from "./DevicesView";

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDevice: (payload) => dispatch(getDevice(payload)),
    getDevices: (payload) => dispatch(getDevices(payload)),
    addDevice: (payload) => dispatch(addDevice(payload)),
    uploadBulkDevice: (payload) => dispatch(uploadBulkDevice(payload)),
    updateDevice: (payload) => dispatch(updateDevice(payload)),
    deleteDevice: (payload) => dispatch(deleteDevice(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicesView);
