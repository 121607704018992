//libs
import React from "react";

//styles
import S from "./style.module.scss";

const CountCard = ({ title = "", count = 0, background = "black" }) => {
  return (
    <div className={S.container} style={{ background }}>
      <p>{title}</p>
      <p>{count}</p>
    </div>
  );
};

export default React.memo(CountCard);
