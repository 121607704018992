import { connect } from "react-redux";
import { resetPassword } from "src/redux/actions";
import ResetPassword from "./resetPassword";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (payload) => dispatch(resetPassword(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
