import { connect } from "react-redux";
import { changePassword, updateUserDetailsRedux } from "src/redux/actions";
import ChangePassword from "./ChangePassword";

const mapStateToProps = (state) => {
  return {
    userDetails: state.common.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePassword: (payload) => dispatch(changePassword(payload)),
    updateUserDetails: (payload) => dispatch(updateUserDetailsRedux(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
