//libs
import React, { useEffect, useState } from "react";
import moment from "moment";

//helper
import cx from "classnames";
import {
  capitalizeText,
  convertToCms,
  convertToFeet,
  convertToKg,
  convertToLBS,
  matchUserImage,
  validContactNumber,
} from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import {
  COUNTRIES,
  GENDERS,
  nameRegex,
  phoneRegex,
  RACES,
  STRINGS,
  WARNINGS,
} from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  SelectAvatar,
} from "src/components";
import { Icomoon } from "src/assets/icons";
import ProfilePic from "src/assets/images/profile.png";

export const EditUserForm = ({
  onSubmit = () => {},
  handleCancel = () => {},
  startLoading,
  stopLoading,
  formData = {},
}) => {
  const [selectAvatar, setSelectAvatar] = useState(false);

  const NAME_FILEDS = [
    {
      name: STRINGS.FIRST_NAME,
      label: STRINGS.FIRST_NAME_LABEL,
      capitalize: true,
    },
    {
      name: STRINGS.LAST_NAME,
      label: STRINGS.LAST_NAME_LABEL,
      capitalize: true,
    },
    { name: STRINGS.EMAIL, label: STRINGS.EMAIL_LABEL },
  ];

  const USER_DETAILS = [
    { name: "height", label: "Height", type: "number" },
    { name: "weight", label: "Weight", type: "number" },
    { name: "memberId", label: "Member Id" },
  ];

  const nameRefs = Array(NAME_FILEDS.length).fill(React.createRef());
  const userDetailsRefs = Array(USER_DETAILS.length).fill(React.createRef());

  const [userImage, setUserImage] = useState("");
  const [imageId, setImageId] = useState("");

  const resetImage = () =>
    setUserImage(
      formData.profilePicture ? matchUserImage(formData.profilePicture) : ""
    );

  useEffect(() => {
    resetImage();
    // eslint-disable-next-line
  }, [formData.profilePicture]);

  const hanldeFormSubmit = (values, { resetForm }) => {
    if (values.dob) {
      values.dob = new Date(values.dob).toISOString();
    }

    const { firstName, lastName, weight, height, ...rest } = values;
    rest.fullName = firstName + " " + lastName;

    if (imageId) {
      rest.profilePicture = imageId;
    }

    if (weight) {
      rest.weight = convertToKg(weight);
    }

    if (height) {
      rest.height = convertToCms(String(height));
    }

    onSubmit(rest, () => {
      resetImage();
      resetForm();
    });
  };

  const handleImageSelect = (id) => {
    setUserImage(matchUserImage(id));
    setImageId(id);
    setSelectAvatar(false);
  };

  return (
    <>
      <Formik
        validate={validate}
        enableReinitialize
        initialValues={{
          firstName: formData?.fullName?.split(" ")[0] || "",
          lastName: formData?.fullName?.split(" ")[1] || "",
          email: formData?.email || "",
          phoneNumber: formData?.phoneNumber || "",
          country: formData?.country || "",
          dob: formData.dob ? moment(formData?.dob).format("YYYY-MM-DD") : "",
          gender: formData?.gender || "",
          height: formData?.height ? convertToFeet(formData?.height) : "",
          weight: formData?.weight ? convertToLBS(formData?.weight) : "",
          race: formData?.race || "",
          profilePicture: formData?.profilePicture || "",
          memberId: formData?.memberId || "",
        }}
        onSubmit={hanldeFormSubmit}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          resetForm,
          setFieldValue,
          errors,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit} className={S.form}>
            <div className={S.formBody}>
              <div className={S.formBodyTop}>
                <div className={S.formBodyTopImage}>
                  <img
                    src={userImage ? userImage : ProfilePic}
                    alt=""
                    width="100%"
                    height="100%"
                    onLoad={() => userImage && stopLoading()}
                  />
                  <div
                    className={S.formBodyTopImageLabel}
                    onClick={() => {
                      setSelectAvatar(true);
                    }}
                  >
                    <Icomoon icon="camera" className={S.formBodyTopImageIcon} />
                  </div>
                </div>
                <div className={S.formBodyTopFields}>
                  {NAME_FILEDS.map((field, idx) => (
                    <CustomInput
                      key={idx}
                      label={field.label}
                      inputRef={(r) => (nameRefs[idx] = r)}
                      name={field.name}
                      errors={errors}
                      touched={touched}
                      handleChange={(e) => {
                        setFieldValue(
                          field.name,
                          field.capitalize
                            ? capitalizeText(e.target.value)
                            : e.target.value
                        );
                      }}
                      containerClass={S.formBodyTopFieldsContainer}
                      labelClass={S.formBodyTopFieldsLabel}
                      inputClass={S.formBodyInput}
                      value={values[field.name]}
                      type={field.type}
                      readOnly={field.readOnly}
                      handleBlur={handleBlur}
                    />
                  ))}
                </div>
              </div>
              <div className={S.formBodyMid}>
                <CustomInput
                  label={STRINGS.CONTACT_NUMBER_LABEL}
                  name={STRINGS.PHONE_NUMBER}
                  errors={errors}
                  touched={touched}
                  handleChange={(e) => {
                    validContactNumber(e.target.value) &&
                      setFieldValue(STRINGS.PHONE_NUMBER, e.target.value);
                  }}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values[STRINGS.PHONE_NUMBER]}
                  type={"tel"}
                  handleBlur={handleBlur}
                />

                <CustomInput
                  label={"Date of Birth"}
                  name={"dob"}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values["dob"]}
                  type={"date"}
                  handleBlur={handleBlur}
                />

                <CustomSelect
                  label={"Gender"}
                  name={"gender"}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values["gender"]}
                  handleBlur={handleBlur}
                  options={GENDERS}
                />

                <CustomSelect
                  label={"Race"}
                  name={"race"}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values["race"]}
                  handleBlur={handleBlur}
                  options={RACES}
                />

                <CustomSelect
                  label={"Country"}
                  name={"country"}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values["country"]}
                  handleBlur={handleBlur}
                  options={COUNTRIES}
                />

                {USER_DETAILS.map((field, idx) => (
                  <CustomInput
                    key={idx}
                    label={field.label}
                    inputRef={(r) => (userDetailsRefs[idx] = r)}
                    name={field.name}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    containerClass={S.formBodyMidFieldsContainer}
                    labelClass={S.formBodyMidFieldsLabel}
                    inputClass={S.formBodyInput}
                    value={values[field.name]}
                    type={field.type}
                    readOnly={field.readOnly}
                    handleBlur={handleBlur}
                  />
                ))}
              </div>
            </div>
            <div className={S.formFooter}>
              <CustomButton
                title={STRINGS.CANCEL}
                onClick={() => {
                  handleCancel(() => {
                    resetImage();
                    resetForm();
                  });
                }}
                buttonClass={cx(S.formFooterButton, S.formFooterCancel)}
              />
              <CustomButton
                type="submit"
                title={STRINGS.UPDATE}
                onClick={handleSubmit}
                buttonClass={S.formFooterButton}
              />
            </div>
          </Form>
        )}
      </Formik>
      {selectAvatar && (
        <SelectAvatar
          onClose={() => setSelectAvatar(false)}
          handleSelect={handleImageSelect}
        />
      )}
    </>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = WARNINGS.REQUIRED;
  } else if (!nameRegex.test(values.firstName)) {
    errors.firstName = WARNINGS.INVALID_NAME;
  }
  // if (!values.lastName) {
  //   errors.lastName = WARNINGS.REQUIRED;
  // }
  if (values.lastName && !nameRegex.test(values.lastName)) {
    errors.lastName = WARNINGS.INVALID_NAME;
  }
  if (!values.weight) {
    errors.weight = WARNINGS.REQUIRED;
  }
  if (!values.height) {
    errors.height = WARNINGS.REQUIRED;
  }

  // if (!values.phoneNumber) {
  //   errors.phoneNumber = WARNINGS.REQUIRED;
  // } else
  if (values.phoneNumber && !phoneRegex.test(values.phoneNumber)) {
    errors.phoneNumber = WARNINGS.INVALID_CONTACT;
  }
  return errors;
};
