//libs
import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

//constants
import { APP_TYPES, NOTIFICATION_TYPES, STRINGS } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//components
import {
  BreadcrumbMenu,
  ConfirmDialog,
  CustomButton,
  CustomPagination,
  HalfCardModal,
  SearchBox,
} from "src/components";
import { NotificationsTable } from "src/views/Private/Notifications/NotificationsTable";
import { NotificationsForm } from "src/views/Private/Notifications/NotificationsForm";

const Notifications = ({
  enqueueSnackbar = () => {},
  getNotificationsList = () => {},
  sendNotification = () => {},
  deleteNotification = () => {},
}) => {
  const [showHalfCard, setShowHalfCard] = useState(false);
  const [notificationsList, setNotificationsList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [filterDeviceType, setFilterDeviceType] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [sortKey, setSortKey] = useState("");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [readOnly, setReadOnly] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const [notificationId, setNotificationId] = useState("");
  const [formData, setFormData] = useState({});

  const getNotifications = () => {
    getNotificationsList({
      payload: {
        limit: 10,
        offset: (currentPage - 1) * 10,
        searchString: query,
        notificationType,
        sortOrder,
        sortKey,
        appFilter: filterDeviceType,
      },
      success: (data) => {
        setNotificationsList([...data.rows]);
        setListCount(data.count);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleDeleteNotification = (id) => {
    deleteNotification({
      payload: {
        notificationId: id,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        getNotifications();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  useEffect(() => {
    getNotifications();
    // eslint-disable-next-line
  }, [currentPage, sortOrder, sortKey, filterDeviceType, notificationType]);

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    callback();
    setTimeout(() => {
      setFormData({});
    }, 320);
  };

  const handleAddNotification = (values, resetForm = () => {}) => {
    sendNotification({
      payload: {
        notificationMessage: values.message,
        notificationType: values.notificationType,
        appType: values.appType,
      },
      success: (msg) => {
        getNotifications();
        enqueueSnackbar(msg, { variant: "success" });
        setShowHalfCard(false);
        setFormData({});
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleAddButton = () => {
    setReadOnly(0);
    setShowHalfCard(true);
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    getNotifications();
  };

  const handleSort = (type) => {
    setSortKey(type);
    switch (sortOrder) {
      case "DESC":
        setSortOrder("ASC");
        break;

      case "ASC":
        setSortOrder("DESC");
        break;

      default:
        break;
    }
  };

  const handleSelect = (data) => {
    setReadOnly(2);
    setFormData(data);
    setShowHalfCard(true);
  };

  const handleDelete = (id = "") => {
    setShowDialog(true);
    setNotificationId(id);
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      handleDeleteNotification(notificationId);
    }
    setShowDialog(false);
    setNotificationId("");
  };

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.NOTIFICATIONS}>
        <CustomButton
          title={STRINGS.ADD}
          buttonClass={S.headButton}
          icon={"bell"}
          iconClass={S.headButtonIcon}
          onClick={handleAddButton}
        />
      </BreadcrumbMenu>

      <div className={S.header}>
        <SearchBox
          className={S.searchBox}
          handleSearch={handleSearch}
          value={query}
          setValue={setQuery}
          placeholder={STRINGS.SEARCH_NOTIFICATION}
        />

        <div className={S.wrapper}>
          <span className={S.wrapperLabel}>{STRINGS.NOTIFICATION_TYPE}</span>
          <select
            className={S.typeBox}
            onChange={(e) => {
              setNotificationType(e.target.value);
              setFilterDeviceType("");
            }}
            value={notificationType}
          >
            <option className={S.option} value={""} label={"ALL"} />
            {NOTIFICATION_TYPES.map((device, key) => (
              <option
                key={key}
                className={S.option}
                value={device.value}
                label={device.label}
              />
            ))}
          </select>
        </div>

        <div className={S.wrapper}>
          <span className={S.wrapperLabel}>{STRINGS.APP_TYPE_LABEL}</span>
          <select
            className={S.typeBox}
            onChange={(e) => setFilterDeviceType(e.target.value)}
            value={filterDeviceType}
          >
            <option className={S.option} value={""} label={"ALL"} />
            {APP_TYPES.slice(0, 4).map((device, key) => (
              <option
                key={key}
                className={S.option}
                value={device.value}
                label={device.label}
              />
            ))}
          </select>
        </div>
      </div>

      <NotificationsTable
        data={notificationsList}
        handleSort={handleSort}
        handleDelete={handleDelete}
        handleSelect={handleSelect}
      />

      {!!notificationsList.length && (
        <CustomPagination
          count={listCount}
          selectedPage={currentPage}
          handlePagination={handlePagination}
          currentPageLength={10}
        />
      )}

      <HalfCardModal
        showCard={showHalfCard}
        title={readOnly ? STRINGS.VIEW_NOTIFICATION : STRINGS.ADD_NOTIFICATION}
      >
        <NotificationsForm
          handleFormClose={handleFormClose}
          handleSubmit={handleAddNotification}
          readOnly={readOnly}
          data={formData}
        />
      </HalfCardModal>

      {showDialog && (
        <ConfirmDialog
          title={STRINGS.DELETE_NOTIFICATION}
          onClose={handleConfirmDialog}
        />
      )}
    </section>
  );
};

export default withSnackbar(Notifications);
