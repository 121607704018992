import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";

//styling
import "react-datepicker/dist/react-datepicker.css";

const CustomDatePicker = ({
  date = new Date(),
  setDate = () => {},
  inputClass = "",
}) => {
  const dateRef = React.useRef(null);

  const Input = forwardRef(
    ({ placeholder, value, isSecure, id, onClick, className }, ref) => (
      <input
        placeholder={placeholder}
        value={value}
        issecure={isSecure}
        id={id}
        onClick={onClick}
        className={className}
        readOnly
        ref={ref}
      />
    )
  );

  return (
    <DatePicker
      selected={date}
      onChange={(date) => setDate(date)}
      dateFormat="dd-MMMM-yyyy"
      customInput={<Input className={inputClass} ref={dateRef} />}
    />
  );
};

export default React.memo(CustomDatePicker);
