//libs
import React from "react";
import { withSnackbar } from "notistack";

//styles
import S from "./ChangePassword.module.scss";

//hooks
import { useHistory } from "react-router-dom";

//constants
import { STRINGS, DASHBOARD } from "src/shared/constants";

//components
import { ChangePasswordForm } from "./ChangePasswordForm";
import { BreadcrumbMenu } from "src/components";

const ChangePassword = ({
  changePassword = () => {},
  enqueueSnackbar,
  updateUserDetails = () => {},
  userDetails,
}) => {
  const history = useHistory();

  const handleSubmit = (values) => {
    const { confirmPassword, ...rest } = values;
    changePassword({
      payload: { ...rest },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        updateUserDetails({ ...userDetails, userCreatedOwnPassword: true });
        history.push(DASHBOARD);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.CHANGE_PASSWORD} />
      <div className={S.container}>
        <ChangePasswordForm onSubmit={handleSubmit} />
      </div>
    </section>
  );
};

export default withSnackbar(ChangePassword);
