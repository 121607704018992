//libs
import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";
import moment from "moment";

//utils
import { useHistory, useParams } from "react-router-dom";
import { formatDate } from "src/shared/services";

//constants
import { STRINGS } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//widgets
import {
  ActWidget,
  DailyLogWidget,
  SpiroLinkWidget,
  Spo2Widget,
} from "./widgets";

const MemberReport = ({
  getUserReport = () => {},
  enqueueSnackbar = () => {},
  loading = false,
}) => {
  const history = useHistory();
  const [userReport, setUserReport] = useState([]);

  let widgetData = history?.location?.state;
  const ID = history?.location?.search.slice(1);
  const { reportType, date, reportId } = useParams();

  useEffect(() => {
    if (ID && !widgetData) {
      fetchReport(ID);
    }
    // eslint-disable-next-line
  }, [ID]);

  const fetchReport = () => {
    getUserReport({
      payload: {
        userId: ID,
        date: formatDate(date - 0),
        startTime: moment(date).startOf("day").toISOString(),
        endTime: moment(date).endOf("day").toISOString(),
        filterType:
          reportType === 1
            ? 1
            : reportType === 2
            ? 2
            : reportType === 3
            ? 3
            : reportType === 4
            ? 5
            : "",
        appType: 1,
      },
      success: (data) => {
        setUserReport(data.data || []);
        widgetData = data?.data?.find((d) => d.id === reportId);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const printDocument = () => {
    // const input = document.getElementById("divToPrint");
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0);
    //   // pdf.output('dataurlnewwindow');
    //   pdf.save("download.pdf");
    // });

    html2canvas(document.querySelector("#divToPrint"), {
      scrollX: 0,
      scrollY: 0,
      scale: 2,
    }).then((canvas) => {
      // document.body.appendChild(canvas);
      // if you want see your screenshot in body.
      const imgData = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = "filename.png";
      link.href = imgData;
      link.click();
    });
  };

  const deviceType = widgetData?.data?.deviceType || 0;
  return (
    <div className={S.reports}>
      <div className={S.reportsContainer} id="divToPrint">
        {deviceType === 5 && <ActWidget data={widgetData?.data || {}} />}
        {deviceType === 6 && <DailyLogWidget data={widgetData?.data || {}} />}
        {(deviceType === 2 || deviceType === 3 || deviceType === 4) && (
          <Spo2Widget data={widgetData?.data} />
        )}
        {deviceType === 1 && <SpiroLinkWidget data={widgetData?.data || {}} />}
      </div>
      {!!!loading && (
        <div className={S.download}>
          <button className={S.downloadButton} onClick={printDocument}>
            {STRINGS.DOWNLOAD_REPORT}
          </button>
        </div>
      )}
    </div>
  );
};

export default React.memo(MemberReport);
