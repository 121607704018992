import React from "react";
import cx from "classnames";

//styles
import styles from "./CoverPage.module.scss";

//assets
import SpiroLink from "src/assets/images/spirolink.jpeg";

const Cover = ({ dateRange, userName = "", empty = false }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingTitle}>
          <img src={SpiroLink} alt="" />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.bodyTitle}>{"Monthly Lung Health Report"}</div>
        <div className={styles.bodyText}>{"Date Range"}</div>
        <div className={styles.bodyText}>{dateRange}</div>
        <div className={styles.bodyText}>{"for"}</div>
        <div className={cx(styles.bodyText, styles.bodyTitle)}>{userName}</div>
        {empty && (
          <div
            className={cx(styles.bodyText)}
            style={{ textDecoration: "underline" }}
          >
            {"No tests performed yet!"}
          </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Cover);
