//libs
import React from "react";

//decorator
import cx from "classnames";

//styles
import S from "./style.module.scss";

//components
import { MemberImageInfo } from "src/components";

//hooks
import { useHistory } from "react-router-dom";

//constants
import { MEMBER_PROFILE } from "src/shared/constants";

const ListedUserCard = ({ userData = {} }) => {
  const history = useHistory();

  const handleClick = () =>
    history.push({
      pathname: MEMBER_PROFILE,
      search: userData.id,
    });

  return (
    <div
      className={cx(S.container, { [S.containerId]: userData.uniqueId })}
      onClick={handleClick}
    >
      <MemberImageInfo userData={userData} />
    </div>
  );
};

export default React.memo(ListedUserCard);
