//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//components
import CustomButton from "../CustomButton";

//constants
import { STRINGS } from "src/shared/constants";

const PopupBox = ({ title = "", containerClass, onClose = () => {} }) => {
  const handleClick = () => {
    onClose(true);
  };

  return (
    <>
      <div className={S.overlay} />
      <div className={cx(S.container, containerClass)}>
        <p className={S.containerHeader}>{title}</p>
        <div className={S.containerBody}>
          <CustomButton
            buttonClass={S.containerButton}
            title={STRINGS.OK}
            onClick={handleClick}
          />
        </div>
      </div>
    </>
  );
};

export default PopupBox;
