//libs
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

//decorators
import cx from "classnames";

//components
import {
  BreadcrumbMenu,
  ConfirmDialog,
  HalfCardModal,
  MemberImageInfo,
} from "src/components";

//constants
import {
  STRINGS,
  USER_PERMISSIONS,
  USER_ROLES,
  WARNINGS,
} from "src/shared/constants";

//styles
import S from "./style.module.scss";
import { Icomoon } from "src/assets/icons";
import { withSnackbar } from "notistack";
import { AddProvidersForm } from "../AddProvidersForm";
import { ListedUserCard, ProviderDetails } from "../widgets";

const ProviderProfile = ({
  loading = true,
  userDetails = {},
  getProvider = () => {},
  deleteProvider = () => {},
  updateProvider = () => {},
  enqueueSnackbar = () => {},
  startLoading = () => {},
  stopLoading = () => {},
}) => {
  const history = useHistory();
  const permissions = userDetails.permissions || [];
  const ID = history?.location?.search?.slice(1);
  const [providerData, setProviderData] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showHalfCard, setShowHalfCard] = useState(false);
  const [tabs, setTabs] = useState(1);
  const [relatedUsersData, setRelatedUsersData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    const ROLE = userDetails.role || "";
    const id = userDetails.id || "";
    if (ROLE === USER_ROLES.PROVIDER && id) {
      fetchUser(id);
      return;
    }
    if (ID) {
      fetchUser(ID);
    }
    // eslint-disable-next-line
  }, [ID]);

  const fetchUser = (userId) => {
    getProvider({
      payload: {
        userId,
      },
      success: (data) => {
        setProviderData(data.data || {});
        setRelatedUsersData(data.users || []);
        setTabs(1);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleDeleteClick = (userId = ID) => {
    if (!permissions.includes(USER_PERMISSIONS.DELETE)) {
      enqueueSnackbar(WARNINGS.DELETE_DENIED, { variant: "warning" });
      return;
    }
    if (userDetails.id === userId) {
      enqueueSnackbar(WARNINGS.SELF_DELETE, { variant: "error" });
      return;
    }
    setShowDialog(true);
  };

  const handleUpdate = (values, callback) => {
    if (!permissions.includes(USER_PERMISSIONS.UPDATE)) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    const { id, email, firstName, lastName, isEmailVerified, ...rest } = values;
    updateProvider({
      payload: {
        userId: ID,
        values: {
          hospitalDetails: { ...rest },
          fullName: firstName + " " + lastName,
          email,
          isEmailVerified,
        },
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        if (callback) callback();
        fetchUser(ID);
        setShowHalfCard(false);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleDelete = () => {
    deleteProvider({
      payload: {
        userId: ID,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        history.goBack();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleConfirmDialog = (yes) => {
    if (yes) {
      handleDelete();
    }
    setShowDialog(false);
  };

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    setTimeout(() => {
      callback();
    }, 320);
  };

  const hanldeEditClick = () => {
    if (!permissions.includes(USER_PERMISSIONS.DELETE)) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    setShowHalfCard(true);
    setIsEdit(1);
  };

  const handleActivateUser = () => {
    if (!permissions.includes(USER_PERMISSIONS.UPDATE)) {
      enqueueSnackbar(WARNINGS.UPDATE_DENIED, { variant: "warning" });
      return;
    }
    let values = {
      firstName: providerData?.fullName?.split(" ")[0] || "",
      lastName: providerData?.fullName?.split(" ")[1] || "",
      email: providerData?.email,
      isEmailVerified: true,
      ...providerData?.hospitalData,
    };
    handleUpdate(values);
  };

  return (
    <section className={cx(S.section, S.sectionClass)}>
      <BreadcrumbMenu title={STRINGS.PROVIDERS} subTitle={STRINGS.PROFILE}>
        {userDetails.role !== 4 && (
          <div className={S.headerButtons}>
            <div className={S.tooltip} onClick={hanldeEditClick}>
              <Icomoon
                className={cx(S.icon, S.edit, {
                  [S.disable]: !permissions.includes(USER_PERMISSIONS.UPDATE),
                })}
                icon={"pencil"}
              />
              <span className={S.tooltiptext}>{STRINGS.EDIT}</span>
            </div>
            <div className={S.tooltip} onClick={handleDeleteClick}>
              <Icomoon
                className={cx(S.icon, S.delete, {
                  [S.disable]: !permissions.includes(USER_PERMISSIONS.DELETE),
                })}
                icon={"bin"}
              />
              <span className={S.tooltiptext}>{STRINGS.DELETE}</span>
            </div>
            {!loading && !!!providerData.isEmailVerified && (
              <div className={S.tooltip} onClick={handleActivateUser}>
                <Icomoon
                  className={cx(S.icon, S.view, S.power, {
                    [S.powerOff]: !providerData?.isEmailVerified,
                  })}
                  icon={"power-off"}
                />
                <span className={S.tooltiptext}>{STRINGS.ACTIVATE}</span>
              </div>
            )}
          </div>
        )}
      </BreadcrumbMenu>

      {providerData.id && (
        <div className={S.container}>
          <div className={S.containerLeft}>
            <MemberImageInfo userData={providerData} provider={true} />
            <div>
              <p style={{ margin: "16px 0px", textAlign: "center" }}>
                Linked Users : {relatedUsersData.length}
              </p>
            </div>
          </div>
          <div className={S.containerRight}>
            <div className={S.containerRightTabs}>
              <p
                onClick={() => setTabs(1)}
                className={cx(S.containerRightTabsTitle, {
                  [S.containerRightTabsActive]: tabs === 1,
                })}
              >
                Provider Details
              </p>
              {providerData.role === 4 && (
                <p
                  onClick={() => setTabs(2)}
                  className={cx(
                    S.containerRightTabsTitle,
                    tabs === 2 && S.containerRightTabsActive
                  )}
                >
                  Linked Users
                </p>
              )}
            </div>
            {tabs === 1 && <ProviderDetails data={providerData} />}
            {tabs === 2 && <SecondaryUsers data={relatedUsersData || []} />}
          </div>
        </div>
      )}

      <HalfCardModal showCard={showHalfCard} title={STRINGS.UPDATE_PROVIDER}>
        <AddProvidersForm
          formData={providerData}
          handleCancel={handleFormClose}
          onSubmit={handleUpdate}
          startLoading={startLoading}
          stopLoading={stopLoading}
          formType={isEdit}
        />
      </HalfCardModal>

      {showDialog && (
        <ConfirmDialog
          title={STRINGS.DELETE_PROVIDER_MSG}
          onClose={handleConfirmDialog}
          yesTitle={STRINGS.DELETE}
        />
      )}
    </section>
  );
};

export default withSnackbar(ProviderProfile);

const SecondaryUsers = ({ data = [] }) => {
  if (!!!data.length) {
    return (
      <div className={S.noData}>
        <p className={S.noDataText}>No user found</p>
      </div>
    );
  }
  return (
    <div className={S.secondaryUsers}>
      {data.map((user) => (
        <ListedUserCard userData={user} />
      ))}
    </div>
  );
};
