import React from "react";
import cx from "classnames";

//constants
import { REPORT_TITLES, STRINGS } from "src/shared/constants";

//utils
import { daysDiff, getDeviceName, getTestType } from "src/shared/services";

//styles
import S from "./style.module.scss";

//widgets
import { CustomButton } from "src/components";

const List = ({
  data = [],
  dailyLog = {},
  userPef = {},
  onClick = () => {},
  loading = false,
  onClickRecreate = () => {},
}) => {
  const handleViewDetails = (data, key) => {
    onClick(data);
  };

  return data.length ? (
    <div className={S.container}>
      <div className={S.table}>
        <div className={cx(S.tableRow, S.tableHeading)}>
          <p className={S.tableRowData}>#</p>
          <p className={S.tableRowData}>Test Type</p>
          <p className={S.tableRowData}>Device Type</p>
          <p className={S.tableRowData}>time</p>
          <p className={S.tableRowData}>Action</p>
        </div>
        <div>
          {data.map((d, key) => (
            <div
              key={key}
              className={cx(S.tableRow, S.reportRow)}
              onClick={() => handleViewDetails(d, key)}
            >
              <p className={S.tableRowData}>{key + 1}</p>
              <p className={S.tableRowData}>
                {d?.deviceType === 6
                  ? REPORT_TITLES[3]
                  : `${getTestType(d.deviceType)} ${
                      d.testType?.split("_")?.join(" ") ?? ""
                    }`}
              </p>
              <p className={S.tableRowData}>
                {d?.deviceType === 6 || d?.deviceType === 5
                  ? STRINGS.EMPTY_DATA
                  : getDeviceName(d.deviceType)}
              </p>
              <p className={S.tableRowData}>
                {new Date(d.createdAt).toLocaleString()}
              </p>
              {d.deviceType === 3 && daysDiff(d?.updatedAt, new Date()) < 7 ? (
                <CustomButton
                  buttonClass={S.recreateButton}
                  title={STRINGS.RECREATE_SLEEP_ANALYSIS}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickRecreate(d?.id);
                  }}
                />
              ) : (
                <button style={{ opacity: 0 }} buttonClass={S.recreateButton}>
                  {STRINGS.RECREATE_SLEEP_ANALYSIS}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : loading ? null : (
    <div className={S.noData}>
      <p className={S.noDataText}>No records found!</p>
    </div>
  );
};

export default React.memo(List);
