import React from "react";

//styling
import S from "./style.module.scss";

const CustomDaysPicker = ({
  date = "7 days",
  setDate = () => {},
  className = "",
}) => {
  return (
    <div className={S.dateWrapper}>
      <select
        className={S.dateInput}
        onChange={(e) => setDate(e.target.value)}
        value={date}
      >
        <option className={S.option} value="7" label="7 Days" />
        <option className={S.option} value="30" label="30 Days" />
      </select>
    </div>
  );
};

export default React.memo(CustomDaysPicker);
