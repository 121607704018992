//effects
import { all, put, takeLatest } from "redux-saga/effects";

//services
import {
  getRequest,
  permissionsFormatter,
  postRequest,
} from "src/shared/services";

//constants
import { API, RESPONSE, WARNINGS } from "src/shared/constants";

//actions
import {
  ACTION_TYPES,
  getPermissionRedux,
  startLoading,
  stopLoading,
} from "../actions";

function* addPermission({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield postRequest({
      API: API.PERMISSION,
      DATA: { ...payload },
    });
    if (data.statusCode === RESPONSE.success) {
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* getPermission({
  payload: { payload = {}, success = () => {}, fail = () => {} } = {},
}) {
  try {
    yield put(startLoading());
    const { data = {} } = yield getRequest({
      API: API.PERMISSION,
    });
    if (data.statusCode === RESPONSE.success) {
      let p = yield permissionsFormatter(data.data);
      yield put(getPermissionRedux(p));
      success(data.msg);
    } else if (data.statusCode === RESPONSE.unAuthorized) {
      fail(data.msg);
    } else {
      fail(data.msg);
    }
  } catch (error) {
    fail(WARNINGS.ERROR);
  } finally {
    yield put(stopLoading());
  }
}

function* UserSaga() {
  yield all([
    takeLatest(ACTION_TYPES.ADD_PERMISSION, addPermission),
    takeLatest(ACTION_TYPES.GET_PERMISSION, getPermission),
  ]);
}

export default UserSaga;
