import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryGroup,
  VictoryLegend,
} from "victory";
import S from "./style.module.scss";

const BarChart = ({
  title,
  height = 150,
  data = {},
  timezone = "America/Phoenix",
  categories = [],
}) => {
  const [showSleepChart, setShowSleepChart] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (data && data.sleepMarks) {
        setShowSleepChart(true);
      }
    }, 10);
  }, [data, data?.sleepMarks]);

  if (data && !data.sleepMarks) return null;

  function splitDateIntoEqualIntervals(startDate, endData, numberOfIntervals) {
    let diff = endData.getTime() - startDate.getTime();
    let intervalLength = diff / numberOfIntervals;
    let intervals = [];
    for (let i = 0; i <= numberOfIntervals; i++)
      intervals.push(new Date(startDate.getTime() + i * intervalLength));
    return intervals;
  }

  categories = splitDateIntoEqualIntervals(
    new Date(data?.createdAt),
    new Date(data?.updatedAt),
    14
  );

  const colors = ["#28CDAC", "#E8EA99", "#FC8F83"];

  if (!showSleepChart) return null;

  const dataSet = data.sleepMarks
    ?.slice(0, data?.totalDuration)
    ?.map((m, i) => {
      return {
        category: `${new Date(data?.createdAt).getTime() + i * 1000}}`,
        value1: m == 2 ? 1 : 0,
        value2: m == 1 ? 2 : 0,
        value3: m == 0 ? 3 : 0,
      };
    });

  return (
    <>
      <div className={S.header}>
        <h1 className={S.headerTitle}>{title}</h1>
      </div>
      <VictoryChart
        height={height}
        width={640}
        domainPadding={0}
        padding={{ top: 12, bottom: 40, left: 32, right: 32 }}
      >
        <VictoryLegend
          x={260}
          y={height - 16}
          orientation="horizontal"
          gutter={8}
          style={{ labels: { fontSize: 9 } }}
          data={[
            { name: "Restfull", symbol: { fill: colors[0] } },
            { name: "Restless", symbol: { fill: colors[1] } },
            { name: "Awake", symbol: { fill: colors[2] } },
          ]}
        />
        <VictoryAxis
          dependentAxis
          tickValues={[0, 1, 2, 3]}
          style={{ tickLabels: { fontSize: 9 }, axis: { stroke: "#ccc" } }}
        />
        <VictoryGroup offset={0}>
          <VictoryBar
            data={dataSet}
            x=""
            y="value1"
            barWidth={1}
            alignment="start"
            style={{ data: { fill: colors[2] } }}
          />
          <VictoryBar
            data={dataSet}
            x=""
            y="value2"
            barWidth={1}
            alignment="middle"
            style={{ data: { fill: colors[1] } }}
          />
          <VictoryBar
            data={dataSet}
            x=""
            y="value3"
            barWidth={1}
            alignment="end"
            style={{ data: { fill: colors[0] } }}
          />
        </VictoryGroup>
        <VictoryChart>
          <VictoryAxis
            style={{ tickLabels: { fontSize: 9 }, axis: { stroke: "#ccc" } }}
            tickValues={dataSet.map((cat) => cat.category)}
            tickFormat={(d2, i) =>
              `${moment(categories[i]).tz(timezone).format("hh:mm:ss")}`
            }
            tickCount={14}
          />
        </VictoryChart>
      </VictoryChart>
    </>
  );
};

export default React.memo(BarChart);
