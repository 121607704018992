//libs
import React from "react";

//constants
import ProfilePic from "src/assets/images/profile.png";
import { STRINGS } from "src/shared/constants";
import { ageCalculator, matchUserImage } from "src/shared/services";

//style
import S from "./style.module.scss";

const MemberImageInfo = ({ userData = {}, provider = false }) => {
  return (
    <div className={S.user}>
      <div className={S.userImage}>
        <img
          src={
            userData.profilePicture
              ? matchUserImage(userData.profilePicture)
              : ProfilePic
          }
          alt=""
          width="100%"
          height="100%"
        />
      </div>
      <p className={S.userName}>{userData.fullName || ""}</p>
      {!!!provider && (
        <>
          <p className={S.userStats}>
            {userData.gender ? `${userData.gender}, ` : ""}
            {userData.dob ? `${ageCalculator(userData.dob)} Yrs` : ""}
          </p>
          <p className={S.userStats}>{userData.country || ""}</p>
        </>
      )}
      {!!userData.uniqueId && (
        <p className={S.userStats}>{userData.uniqueId}</p>
      )}
      {provider ? (
        <p className={S.status}>
          <span
            className={
              userData.isEmailVerified ? S.statusActive : S.statusPending
            }
          >
            {userData.isEmailVerified ? STRINGS.ACTIVE : STRINGS.PENDING}
          </span>
        </p>
      ) : (
        <p className={S.status}>
          <span
            className={
              userData.isBlocked || !userData.isEmailVerified
                ? S.statusPending
                : S.statusActive
            }
          >
            {userData.isEmailVerified
              ? userData.isBlocked
                ? STRINGS.BLOCKED
                : STRINGS.ACTIVE
              : STRINGS.PENDING}
          </span>
        </p>
      )}
      {!!userData.createdUserData && (
        <p className={S.userCreator}>
          <span> created by: </span>
          {userData.createdUserData?.fullName || ""}
        </p>
      )}
    </div>
  );
};

export default React.memo(MemberImageInfo);
