//libs
import { withSnackbar } from "notistack";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

//components
import {
  BreadcrumbMenu,
  CustomButton,
  CustomPagination,
  HalfCardModal,
  SearchBox,
} from "src/components";

//constants
import {
  FORM_TYPES,
  PROVIDER_PROFILE,
  STRINGS,
  USER_PERMISSIONS,
  USER_ROLES,
} from "src/shared/constants";
import { AddProvidersForm } from "../AddProvidersForm";
import { ProvidersTable } from "../ProvidersTable";

//styles
import S from "./style.module.scss";

const Providers = ({
  userDetails = {},
  getProviders = () => {},
  enqueueSnackbar = () => {},
  loading = false,
  addProvider = () => {},
}) => {
  const PERMISSIONS = userDetails.permissions || [];
  const history = useHistory();

  const initialFormStates = {
    formData: {},
    formViewType: FORM_TYPES.add,
  };

  const [showHalfCard, setShowHalfCard] = useState(false);
  const [formStates, setFormStates] = useState(initialFormStates);
  const [providerList, setProvidersList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");

  useEffect(() => {
    const id = userDetails.id || "";
    if (userDetails.role === USER_ROLES.PROVIDER && id) {
      history.replace(PROVIDER_PROFILE);
      return;
    }
    fetchProvidersList();
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchProvidersList = () => {
    getProviders({
      payload: {
        role: USER_ROLES.PROVIDER,
        limit: 10,
        offset: (currentPage - 1) * 10,
        searchString: query,
      },
      success: (data) => {
        setProvidersList([...data.data]);
        setListCount(data.count);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    fetchProvidersList();
  };

  const handleSubmit = async (values, resetForm) => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.ADD)) return;

    handleAddProvider(values, resetForm);
  };

  const handleAddProvider = (values, resetForm = () => {}) => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.ADD)) return;
    const {
      id,
      firstName,
      lastName,
      email,
      joiningDate,
      role,
      providerId,
      ...rest
    } = values;

    addProvider({
      payload: {
        hospitalDetails: { ...rest, uniqueId: providerId },
        fullName: values.firstName + " " + values.lastName,
        email: values.email,
        role: USER_ROLES.PROVIDER,
      },
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
        fetchProvidersList();
        setFormStates(initialFormStates);
        setShowHalfCard(false);
        resetForm();
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleFormClose = (callback) => {
    setShowHalfCard(false);
    setTimeout(() => {
      setFormStates({
        formData: {},
        formViewType: FORM_TYPES.add,
      });
      callback();
    }, 320);
  };

  const handleAddButton = () => {
    if (!PERMISSIONS.includes(USER_PERMISSIONS.ADD)) return;
    setShowHalfCard(true);
    setFormStates({ ...formStates, formViewType: FORM_TYPES.add });
  };

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.PROVIDERS}>
        {!!PERMISSIONS.includes(USER_PERMISSIONS.ADD) && (
          <CustomButton
            title={STRINGS.ADD}
            buttonClass={S.headButton}
            icon={"doctor"}
            iconClass={S.headButtonIcon}
            onClick={handleAddButton}
          />
        )}
      </BreadcrumbMenu>

      <SearchBox
        className={S.searchBox}
        handleSearch={handleSearch}
        value={query}
        setValue={setQuery}
      />

      <ProvidersTable data={providerList} loading={loading} />

      {!!providerList.length && (
        <CustomPagination
          count={listCount}
          selectedPage={currentPage}
          handlePagination={handlePagination}
          currentPageLength={10}
        />
      )}

      <HalfCardModal
        showCard={showHalfCard}
        title={
          formStates.formViewType === FORM_TYPES.add
            ? STRINGS.ADD_PROVIDER
            : formStates.formViewType === FORM_TYPES.edit
            ? STRINGS.UPDATE_PROVIDER
            : STRINGS.PROVIDER_DETAILS
        }
      >
        <AddProvidersForm
          formData={formStates.formData}
          formType={formStates.formViewType}
          handleCancel={handleFormClose}
          onSubmit={handleSubmit}
        />
      </HalfCardModal>
    </section>
  );
};

export default withSnackbar(Providers);
