//libs
import React, { useEffect, useState } from "react";
import { withSnackbar } from "notistack";

//constants
import { STRINGS, USER_ROLES } from "src/shared/constants";

//styles
import S from "./style.module.scss";

//components
import { Permissions } from "src/views/Private/Configure/RolesAndPermissions/Permissions";
import { BreadcrumbMenu } from "src/components";
import { Icomoon } from "src/assets/icons";

const RolesAndPermissions = ({
  getPermission,
  addPermission,
  enqueueSnackbar,
  permissions = {},
}) => {
  const [showPermissions, setShowPermissions] = useState({
    superAdmin: false,
    subAdmin: false,
  });

  const handleShowPermissions = (...args) => {
    setShowPermissions((prevState) => ({ ...prevState, ...args[0] }));
  };

  useEffect(() => {
    !!!Object.keys(permissions).length && fetchPermissions();
    // eslint-disable-next-line
  }, []);

  const fetchPermissions = () => {
    getPermission({
      payload: {},
      success: (msg) => {
        enqueueSnackbar(msg, { variant: "success" });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handleAddPermission = (values, role) => {
    addPermission({
      payload: {
        role: role,
        permissions: [...values],
      },
      success: (msg) => {
        fetchPermissions();
        enqueueSnackbar(msg, { variant: "success" });
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  return (
    <section className={S.section}>
      <BreadcrumbMenu
        title={STRINGS.CONFIGURE}
        subTitle={STRINGS.ROLES_AND_PERMISSIONS}
      />
      <div className={S.container}>
        <div
          className={S.heading}
          onClick={() =>
            handleShowPermissions({
              superAdmin: !showPermissions.superAdmin,
            })
          }
        >
          <p className={S.headingText}>{STRINGS.SUPER_ADMIN}</p>
          <Icomoon
            className={S.headingIcon}
            icon={showPermissions.superAdmin ? "minus" : "plus"}
          />
        </div>
        {showPermissions.superAdmin && (
          <Permissions
            data={permissions.SUPER_ADMIN || []}
            handleAddPermission={handleAddPermission}
            role={USER_ROLES.SUPER_ADMIN}
          />
        )}
      </div>
      <div className={S.container}>
        <div
          className={S.heading}
          onClick={() =>
            handleShowPermissions({ subAdmin: !showPermissions.subAdmin })
          }
        >
          <p className={S.headingText}>{STRINGS.SUB_ADMIN}</p>
          <Icomoon
            className={S.headingIcon}
            icon={showPermissions.subAdmin ? "minus" : "plus"}
          />
        </div>
        {showPermissions.subAdmin && (
          <Permissions
            data={permissions.SUB_ADMIN || []}
            handleAddPermission={handleAddPermission}
            role={USER_ROLES.SUB_ADMIN}
          />
        )}
      </div>
    </section>
  );
};

export default withSnackbar(RolesAndPermissions);
