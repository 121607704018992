//libs
import React from "react";

//styles
import S from "./searchBox.module.scss";
import cx from "classnames";

//assets
import { Icomoon } from "src/assets/icons";

//constants
import { STRINGS } from "src/shared/constants";

const SearchBox = ({
  handleSearch = () => {},
  className = "",
  value = "",
  setValue = () => {},
  placeholder = "",
}) => {
  return (
    <div className={cx(S.searchBox, className)}>
      <input
        className={S.searchBoxInput}
        placeholder={placeholder || STRINGS.SEARCH_TEXT}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <div className={S.searchBoxButton} onClick={() => handleSearch(value)}>
        <Icomoon icon={"search"} className={S.searchBoxIcon} />
      </div>
    </div>
  );
};

export default SearchBox;
