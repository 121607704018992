//libs
import React from "react";

//helper
import cx from "classnames";

//styles
import S from "./style.module.scss";

//constants
import { DEVICE_TYPES, STRINGS } from "src/shared/constants";

//components
import { Icomoon } from "src/assets/icons";

export const DevicesTable = ({
  data = [],
  handleDelete = (id) => { },
  handleEdit = (id) => { },
  handleSort = () => { }
}) => {
  return (
    <div className={S.tableContainer}>
      {!!!data.length ? (
        <p className={S.noData}>{STRINGS.NO_DEVICES_FOUND}</p>
      ) : (
        <table className={S.usersTable}>
          <thead>
            <tr className={cx(S.usersTableRow, S.usersTableHeading)}>
              <th>
                <span className={S.usersTableText}>Device ID/IMEI</span>
              </th>
              <th>
                <span className={S.usersTableText}>Device Name</span>
              </th>
              <th style={{ display: "flex", alignItems: "center" }}>
                <span className={S.usersTableText}>Device Type</span>
                <Icomoon className={cx(S.icon, S.sort)} icon={"sort"} color="#fff" onClick={handleSort} />
              </th>
              <th>
                <span className={S.usersTableText}>Quantity</span>
              </th>
              <th>
                <span className={S.usersTableText}>Actions</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((d) => (
              <tr key={d.id} className={S.usersTableRow}>
                <td style={{ display: "flex", alignItems: "center" }}>
                  <span className={S.usersTableText}>
                    {d.deviceId || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {d.name || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <span className={S.usersTableText}>
                    {DEVICE_TYPES[d.type - 1]?.label || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                {/* <td>
                  <span className={S.usersTableText}>
                    {d.deviceIMEI || STRINGS.EMPTY_DATA}
                  </span>
                </td> */}
                <td>
                  <span className={S.usersTableText}>
                    {d.quantity || STRINGS.EMPTY_DATA}
                  </span>
                </td>
                <td>
                  <Icomoon
                    className={cx(S.icon, S.edit)}
                    icon={"pencil"}
                    onClick={() => handleEdit(d.id)}
                  />
                  <Icomoon
                    className={cx(S.icon, S.delete)}
                    icon={"bin"}
                    onClick={() => handleDelete(d.id)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
