import { connect } from "react-redux";
import { getSpiroLinkRecords } from "src/redux/actions";
import SpiroLinkPdf from "./SpiroLinkPdf";

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSpiroLinkRecords: (payload) => dispatch(getSpiroLinkRecords(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpiroLinkPdf);
