//libs
import { withSnackbar } from "notistack";
import React, { useEffect, useState } from "react";

//decorators
import cx from "classnames";

//components
import { BreadcrumbMenu, CustomPagination, SearchBox } from "src/components";

//constants
import { STRINGS, USER_ROLES } from "src/shared/constants";
import { MembersTable } from "../MembersTable";

//styles
import S from "./style.module.scss";

const Members = ({
  getUsers = () => {},
  enqueueSnackbar = () => {},
  loading = false,
}) => {
  const [usersList, setUsersList] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(USER_ROLES.PRIMARY_USER);
  const [query, setQuery] = useState("");

  useEffect(() => {
    fetchUsersList();
    // eslint-disable-next-line
  }, [currentPage, activeTab]);

  const fetchUsersList = () => {
    getUsers({
      payload: {
        role: activeTab,
        limit: 10,
        offset: (currentPage - 1) * 10,
        searchString: query,
      },
      success: (data) => {
        setUsersList([...data.data]);
        setListCount(data.count);
      },
      fail: (msg) => {
        enqueueSnackbar(msg, { variant: "error" });
      },
    });
  };

  const handlePagination = (value) => {
    setCurrentPage(value);
  };

  const handleSearch = () => {
    if (currentPage !== 1) {
      setCurrentPage(1);
      return;
    }
    fetchUsersList();
  };

  return (
    <section className={S.section}>
      <BreadcrumbMenu title={STRINGS.MEMBERS}>
        <div className={S.tabs}>
          <p
            className={cx(S.tabsTitle, {
              [S.tabsActive]: activeTab === USER_ROLES.PRIMARY_USER,
            })}
            onClick={() => {
              setActiveTab(USER_ROLES.PRIMARY_USER);
              setCurrentPage(1);
              setQuery("");
            }}
          >
            {STRINGS.PRIMARY_USERS}
          </p>
          <p
            className={cx(S.tabsTitle, {
              [S.tabsActive]: activeTab === USER_ROLES.SECONDARY_USER,
            })}
            onClick={() => {
              setActiveTab(USER_ROLES.SECONDARY_USER);
              setCurrentPage(1);
              setQuery("");
            }}
          >
            {STRINGS.SECONDARY_USERS}
          </p>
        </div>
      </BreadcrumbMenu>

      <SearchBox
        className={S.searchBox}
        handleSearch={handleSearch}
        value={query}
        setValue={setQuery}
      />

      <MembersTable data={usersList} loading={loading} />

      {!!usersList.length && (
        <CustomPagination
          count={listCount}
          selectedPage={currentPage}
          handlePagination={handlePagination}
          currentPageLength={10}
        />
      )}
    </section>
  );
};

export default withSnackbar(Members);
