//libs
import React, { useEffect } from "react";
import moment from "moment";

//helper
import cx from "classnames";
import {
  capitalizeText,
  validContactNumber,
  validPostalCode,
} from "src/shared/services";

//styles
import S from "./style.module.scss";

//constants
import {
  alphaNumericRegex,
  COUNTRIES,
  emailRegex,
  FORM_TYPES,
  nameRegex,
  numberRegex,
  phoneRegex,
  STRINGS,
  USER_ROLES_LABELS,
  WARNINGS,
} from "src/shared/constants";

//components
import { Form, Formik } from "formik";
import { CustomButton, CustomInput, CustomSelect } from "src/components";

export const AddProvidersForm = ({
  onSubmit = () => {},
  handleCancel = () => {},
  formType = "",
  formData = {},
}) => {
  const NAME_FILEDS = [
    { name: STRINGS.FIRST_NAME, label: STRINGS.FIRST_NAME_LABEL },
    { name: STRINGS.LAST_NAME, label: STRINGS.LAST_NAME_LABEL },
  ];

  const HOSPITAL_DETAILS = [
    { name: STRINGS.NAME, label: STRINGS.HOSPITAL_NAME_LABEL },
    { name: STRINGS.BUILDING, label: STRINGS.BUILDING_LABEL },
    { name: STRINGS.STREET, label: STRINGS.STREET_LABEL },
    { name: STRINGS.CITY, label: STRINGS.CITY_LABEL },
    { name: STRINGS.TOWN, label: STRINGS.TOWN_LABEL },
  ];

  const CONTACT_FILEDS = [
    { name: STRINGS.EMAIL, label: STRINGS.EMAIL_LABEL },
    { name: STRINGS.PHONE_NUMBER, label: STRINGS.CONTACT_NUMBER_LABEL },
    { name: STRINGS.PROVIDER_ID, label: STRINGS.PROVIDER_ID_LABEL },
  ];

  const nameRefs = Array(NAME_FILEDS.length).fill(React.createRef());
  const detailsRefs = Array(HOSPITAL_DETAILS.length).fill(React.createRef());
  const contactRefs = Array(CONTACT_FILEDS.length).fill(React.createRef());

  const isAdd = formType === FORM_TYPES.add;
  const isView = formType === FORM_TYPES.view;
  const isEdit = formType === FORM_TYPES.edit;

  useEffect(() => {
    if (formData.profilePicture) {
    }
  }, [formData.profilePicture]);

  const hanldeFormSubmit = (values, { resetForm }) => {
    onSubmit(values, () => {
      resetForm();
    });
  };

  return (
    <Formik
      validate={validate}
      enableReinitialize
      initialValues={{
        id: formData?.id || "",
        firstName: formData?.fullName?.split(" ")[0] || "",
        lastName: formData?.fullName?.split(" ")[1] || "",
        email: formData?.email || "",
        name: formData?.hospitalData?.name || "",
        phoneNumber: formData?.hospitalData?.phoneNumber || "",
        buildingNumber: formData?.hospitalData?.buildingNumber || "",
        streetName: formData?.hospitalData?.streetName || "",
        city: formData?.hospitalData?.city || "",
        town: formData?.hospitalData?.town || "",
        country: formData?.hospitalData?.country || "",
        postalCode: formData?.hospitalData?.postalCode || "",
        role: USER_ROLES_LABELS[formData?.role] || "",
        // status: formData?.userCreatedOwnPassword ? "Active" : "Pending",
        joiningDate: moment(formData?.createdAt).format("MMM Do, YYYY") || "",
        isEmailVerified: formData?.isEmailVerified || false,
        providerId: formData?.hospitalData?.uniqueId || "", //member id for end user
      }}
      onSubmit={hanldeFormSubmit}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        resetForm,
        setFieldValue,
        errors,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit} className={S.form}>
          <div className={S.formBody}>
            <p className={S.subHeading}>{STRINGS.PROVIDER_DETAILS}</p>
            <div className={S.formBodyTop}>
              <div className={S.formBodyTopFields}>
                {NAME_FILEDS.map((field, idx) => (
                  <CustomInput
                    key={idx}
                    label={field.label}
                    inputRef={(r) => (nameRefs[idx] = r)}
                    name={field.name}
                    errors={errors}
                    touched={touched}
                    handleChange={(e) => {
                      setFieldValue(field.name, capitalizeText(e.target.value));
                    }}
                    containerClass={S.formBodyTopFieldsContainer}
                    labelClass={S.formBodyTopFieldsLabel}
                    inputClass={S.formBodyInput}
                    value={values[field.name]}
                    type={field.type}
                    readOnly={!!isView}
                    handleBlur={handleBlur}
                  />
                ))}
                <CustomInput
                  label={STRINGS.EMAIL_LABEL}
                  inputRef={(r) => (contactRefs[0] = r)}
                  name={STRINGS.EMAIL}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values[STRINGS.EMAIL]}
                  type={"email"}
                  readOnly={!!isView}
                  handleBlur={handleBlur}
                />
                <CustomInput
                  label={STRINGS.PROVIDER_ID_LABEL}
                  inputRef={(r) => (contactRefs[2] = r)}
                  name={STRINGS.PROVIDER_ID}
                  errors={errors}
                  touched={touched}
                  handleChange={(e) => {
                    setFieldValue(
                      STRINGS.PROVIDER_ID,
                      e?.target?.value?.toUpperCase()
                    );
                  }}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values[STRINGS.PROVIDER_ID]}
                  readOnly={!!isView || !!isEdit}
                  handleBlur={handleBlur}
                  maxLength={8}
                />
              </div>
            </div>
            <p className={S.subHeading}>{STRINGS.HOSPITAL_DETAILS}</p>
            <div className={S.formBodyMid}>
              <CustomInput
                label={STRINGS.CONTACT_NUMBER_LABEL}
                inputRef={(r) => (contactRefs[1] = r)}
                name={STRINGS.PHONE_NUMBER}
                errors={errors}
                touched={touched}
                handleChange={(e) => {
                  validContactNumber(e.target.value) &&
                    setFieldValue(STRINGS.PHONE_NUMBER, e.target.value);
                }}
                containerClass={S.formBodyMidFieldsContainer}
                labelClass={S.formBodyMidFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.PHONE_NUMBER]}
                type={"tel"}
                readOnly={!!isView}
                handleBlur={handleBlur}
              />

              {HOSPITAL_DETAILS.map((field, idx) => (
                <CustomInput
                  key={idx}
                  label={field.label}
                  inputRef={(r) => (detailsRefs[idx] = r)}
                  name={field.name}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  containerClass={S.formBodyMidFieldsContainer}
                  labelClass={S.formBodyMidFieldsLabel}
                  inputClass={S.formBodyInput}
                  value={values[field.name]}
                  type={field.type}
                  readOnly={!!isView}
                  handleBlur={handleBlur}
                />
              ))}

              <CustomInput
                label={STRINGS.POSTALCODE_LABEL}
                name={STRINGS.POSTALCODE}
                errors={errors}
                touched={touched}
                handleChange={(e) => {
                  validPostalCode(e.target.value) &&
                    setFieldValue(STRINGS.POSTALCODE, e.target.value);
                }}
                containerClass={S.formBodyMidFieldsContainer}
                labelClass={S.formBodyMidFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.POSTALCODE]}
                type={"tel"}
                readOnly={!!isView}
                handleBlur={handleBlur}
              />

              <CustomSelect
                label={STRINGS.COUNTRY_LABEL}
                name={STRINGS.COUNTRY}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                containerClass={S.formBodyMidFieldsContainer}
                labelClass={S.formBodyMidFieldsLabel}
                inputClass={S.formBodyInput}
                value={values[STRINGS.COUNTRY]}
                handleBlur={handleBlur}
                options={COUNTRIES}
              />
            </div>
          </div>
          <div className={S.formFooter}>
            <CustomButton
              title={!!!isView ? STRINGS.CANCEL : STRINGS.CLOSE}
              onClick={() => {
                handleCancel(() => {
                  resetForm();
                });
              }}
              buttonClass={cx(S.formFooterButton, S.formFooterCancel)}
            />
            {!!!isView && (
              <CustomButton
                type="submit"
                title={isAdd ? STRINGS.SUBMIT : STRINGS.UPDATE}
                onClick={handleSubmit}
                buttonClass={S.formFooterButton}
              />
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = WARNINGS.REQUIRED;
  } else if (!nameRegex.test(values.firstName)) {
    errors.firstName = WARNINGS.INVALID_NAME;
  }
  if (!values.lastName) {
    errors.lastName = WARNINGS.REQUIRED;
  } else if (!nameRegex.test(values.lastName)) {
    errors.lastName = WARNINGS.INVALID_NAME;
  }
  if (!values.email) {
    errors.email = WARNINGS.REQUIRED;
  } else if (!emailRegex.test(values.email)) {
    errors.email = WARNINGS.INVALID_EMAIL;
  }
  if (!values.name) {
    errors.name = WARNINGS.REQUIRED;
  }
  if (!values.postalCode) {
    errors.postalCode = WARNINGS.REQUIRED;
  } else if (!numberRegex.test(values.postalCode)) {
    errors.postalCode = WARNINGS.INVALID_CODE;
  }
  if (!values.country) {
    errors.country = WARNINGS.REQUIRED;
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = WARNINGS.REQUIRED;
  } else if (!phoneRegex.test(values.phoneNumber)) {
    errors.phoneNumber = WARNINGS.INVALID_CONTACT;
  }
  if (values.providerId && !alphaNumericRegex.test(values.providerId)) {
    errors.providerId = WARNINGS.INVALID_PROVIDER_ID;
  }
  return errors;
};
