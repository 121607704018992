import { connect } from "react-redux";
import {
  addPermission,
  getPermission,
  deletePermission,
} from "src/redux/actions";
import RolesAndPermissions from "./RolesAndPermissionsView";

const mapStateToProps = (state) => {
  return {
    permissions: state.permissions.permissions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPermission: (payload) => dispatch(getPermission(payload)),
    addPermission: (payload) => dispatch(addPermission(payload)),
    deletePermission: (payload) => dispatch(deletePermission(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RolesAndPermissions);
